import { useState } from "react";
import { useTranslation } from "react-i18next";
import { faTimes, faRetweet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Slide,
  Pressable,
  HStack,
  useDisclose,
  VStack,
  Checkbox,
  Heading,
  Box,
  Text,
  Radio,
  Select,
  Divider,
  CheckIcon,
} from "native-base";
import _ from "lodash";

import {
  VEHICLE_CATEGORIES,
  ONGOING_RIDE_STATUS,
  HISTORY_RIDE_STATUS,
  TRANSPORT_TYPE,
  RIDE_DATE,
  PATIENT_STATUS,
} from "global";
import { createInitialValueFromArray } from "functions";
import Button from "components/Button";

function ModalButton({ action, type }) {
  const { isOpen, onClose, onOpen } = useDisclose(false);
  return (
    <Pressable onPress={action} onHoverIn={onOpen} onHoverOut={onClose}>
      <FontAwesomeIcon
        icon={type === "close" ? faTimes : faRetweet}
        size="2x"
        style={{ color: isOpen ? "gray" : "#D3D3D3" }}
      />
    </Pressable>
  );
}

const GroupCheckbox = ({
  groupValue,
  setGroupValue,
  title,
  groupName,
  t,
  firstItemsPriority = 0,
}) => {
  return (
    <Box>
      <VStack space={2}>
        <HStack alignItems="baseline">
          <Heading fontSize="md">{title}</Heading>
        </HStack>
        <HStack space={2} flexWrap="wrap">
          {_.map(Object.keys(groupValue), (item, key) => (
            <Checkbox
              key={key}
              // value={item}
              colorScheme={key < firstItemsPriority ? "orange" : "green"}
              _text={{
                color: key < firstItemsPriority ? "orange.400" : "black",
              }}
              _checked={{
                _text: {
                  color: key < firstItemsPriority ? "orange.500" : "green.600",
                },
              }}
              isChecked={groupValue[item]}
              onChange={(value) => setGroupValue(item, value)}
              children={t(`filterModal.${groupName}.${item}`)}
            />
          ))}
        </HStack>
      </VStack>
    </Box>
  );
};

const GroupSelect = ({
  value,
  setValue,
  allItems,
  title,
  placeholder = "Choose a item",
  showElements = [],
}) => {
  function getLabelFromElements(current) {
    if (showElements.length === 1 && showElements[0] === "plate") {
      return _.toUpper(current.plate);
    }
    return showElements.reduce(
      (accumulator, currentValue) =>
        accumulator + " " + _.capitalize(current[currentValue]),
      ""
    );
  }

  return (
    <Box flex={1}>
      <VStack space={2}>
        <HStack alignItems="baseline">
          <Heading fontSize="md">{title}</Heading>
        </HStack>
        <Select
          minWidth="200"
          mt="1"
          accessibilityLabel={placeholder}
          placeholder={placeholder}
          selectedValue={value}
          onValueChange={setValue}
          _selectedItem={{
            bg: "teal.600",
            endIcon: <CheckIcon size={5} />,
          }}
        >
          <Select.Item label={"Tout"} value={"ALL"} />
          {_.map(allItems, (item, key) => (
            <Select.Item
              key={key}
              label={getLabelFromElements(item)}
              value={item._id}
            />
          ))}
        </Select>
      </VStack>
    </Box>
  );
};

const GroupRadio = ({ value, setValue, allItems, title, groupName, t }) => {
  return (
    <Box>
      <VStack space={2}>
        <HStack alignItems="baseline">
          <Heading fontSize="md">{title}</Heading>
        </HStack>
        <Radio.Group
          defaultValue="1"
          name="exampleGroup"
          colorScheme="green"
          accessibilityLabel="favorite colorscheme"
          display="flex"
          flexDirection="row"
          value={value}
          style={{ gap: "10px" }}
          onChange={(values) => {
            setValue(values || []);
          }}
        >
          {_.map(allItems, (item, key) => (
            <Radio
              key={key}
              value={item}
              _checked={{ _text: { color: "green.600" } }}
            >
              {t(`filterModal.${groupName}.${item}`)}
            </Radio>
          ))}
        </Radio.Group>
      </VStack>
    </Box>
  );
};

const FilterModal = ({
  isOpen,
  onClose,
  onSave,
  vehicles = [],
  drivers = [],
  initalState,
  page = "ongoing",
}) => {
  const { t } = useTranslation("Private");
  const [vehicleCategories, setVehicleCategories] = useState(
    initalState.vehicleCategories
  );
  const [rideType, setRideType] = useState(initalState.rideType);
  const [rideStatus, setRideStatus] = useState(initalState.rideStatus);
  const [rideDate, setRideDate] = useState(initalState.rideDate);
  const [rideVehicle, setRideVehicle] = useState(initalState.vehicle);
  const [rideDriver, setRideDriver] = useState(initalState.driver);
  const [patientStatus, setPatientStatus] = useState(initalState.patientStatus);

  function onReset() {
    setVehicleCategories(createInitialValueFromArray(VEHICLE_CATEGORIES));
    setRideType(createInitialValueFromArray(TRANSPORT_TYPE));
    setRideStatus(
      createInitialValueFromArray(
        page === "ongoing" ? ONGOING_RIDE_STATUS : HISTORY_RIDE_STATUS
      )
    );
    setRideDate("ALL");
    setRideVehicle("ALL");
    setRideDriver("ALL");
    if (page === "ongoing")
      setPatientStatus(createInitialValueFromArray(PATIENT_STATUS));
  }

  function onPressSave() {
    const filter = {
      rideDate,
      rideType,
      vehicleCategories,
      rideStatus,
      vehicle: rideVehicle,
      driver: rideDriver,
      patientStatus,
    };
    onSave(filter);
  }

  return (
    <Slide
      in={isOpen}
      placement="right"
      borderLeftWidth={1}
      bg="white"
      p={4}
      w="500px"
    >
      <HStack mb={2} justifyContent="space-between">
        <ModalButton action={onClose} type="close" />
        <ModalButton action={onReset} type="reset" />
      </HStack>

      <VStack space={4}>
        <GroupRadio
          t={t}
          groupName={"rideDate"}
          allItems={RIDE_DATE}
          value={rideDate}
          setValue={setRideDate}
          title="Date"
        />
        <GroupCheckbox
          t={t}
          groupName={"vehicleCategorie"}
          groupValue={vehicleCategories}
          setGroupValue={(key, value) =>
            setVehicleCategories((last) => ({ ...last, [key]: value }))
          }
          title="Categorie du véhicule"
        />
        <GroupCheckbox
          t={t}
          groupName={"rideType"}
          groupValue={rideType}
          setGroupValue={(key, value) =>
            setRideType((last) => ({ ...last, [key]: value }))
          }
          title="Type de transport"
        />
        <GroupCheckbox
          t={t}
          groupName={"rideStatus"}
          groupValue={rideStatus}
          setGroupValue={(key, value) =>
            setRideStatus((last) => ({ ...last, [key]: value }))
          }
          title="Statut du transport"
          firstItemsPriority={1}
        />
        <Divider />
        {patientStatus && (
          <>
            <GroupCheckbox
              t={t}
              groupName={"patientStatus"}
              groupValue={patientStatus}
              setGroupValue={(key, value) =>
                setPatientStatus((last) => ({ ...last, [key]: value }))
              }
              title="Statut du patient"
            />
            <Divider />
          </>
        )}
        <HStack space={4}>
          <GroupSelect
            allItems={vehicles}
            value={rideVehicle}
            setValue={setRideVehicle}
            title="Véhicule"
            placeholder="Choisir un véhicule"
            showElements={["plate"]}
          />
          <GroupSelect
            allItems={drivers}
            value={rideDriver}
            setValue={setRideDriver}
            title="Dea"
            placeholder="Choisir un dea"
            showElements={["firstname", "lastname"]}
          />
        </HStack>
      </VStack>
      <div>
        <HStack>
          <Button onPress={onPressSave} mt={8} py={2} px={6} rounded={4}>
            {t(`filterModal.button_validate`)}
          </Button>
        </HStack>
      </div>
    </Slide>
  );
};

export default FilterModal;
