import { capitalize } from "functions";
import { Center, HStack, VStack } from "native-base";
import { Text, Card } from "components";

const VehicleModalCard = ({
  vehicleIcon,
  vehicle,
  vehicleIsSelected = false,
}) => {
  const isDisabled = vehicle.driver ? false : true;

  return (
    <Card
      bg={
        isDisabled
          ? vehicleIsSelected
            ? "disabled"
            : "gray"
          : vehicleIsSelected
          ? "ligth"
          : "ligthDark"
      }
      borderColor={vehicleIsSelected ? "gray" : "ligthDark"}
      w="full"
      p="0"
      justifyContent="center"
      minHeight="106px"
      colorBorderHover="ligthGray"
    >
      <HStack space="2" justifyContent="space-between" alignItems="center">
        <HStack w="40" space="2" alignItems="center">
          <img width="72px" src={vehicleIcon} />
          <Text
            fontWeight="900"
            fontSize="14px"
            color={isDisabled ? "ligth" : "default"}
          >
            {capitalize(vehicle.category)}
          </Text>
        </HStack>
        <VStack w="20" justifyContent="center" space="1" alignItems="center">
          <Text
            fontWeight="900"
            fontSize="14px"
            color={isDisabled ? "ligth" : "primary"}
          >
            {capitalize(vehicle.model)}
          </Text>
          <Text
            fontWeight="400"
            fontSize="14px"
            color={isDisabled ? "ligth" : "default"}
          >
            {vehicle.plate.toUpperCase()}
          </Text>
          {vehicle.color && (
            <Text
              fontWeight="400"
              fontSize="14px"
              color={isDisabled ? "ligth" : "default"}
              fontStyle="italic"
            >
              {vehicle.color}
            </Text>
          )}
        </VStack>
        <Center w="20">
          <Text
            fontWeight="400"
            fontSize="14px"
            color={isDisabled ? "ligth" : "gray"}
            fontStyle="italic"
          >
            {vehicle.make.toUpperCase()}
          </Text>
        </Center>
      </HStack>
    </Card>
  );
};

export default VehicleModalCard;
