import { Box, Center } from "native-base";
import { Text } from "components";

export default (notif) => {
  return {
    placement: "top-right",
    render: () => {
      return (
        <Box mr="8" bg="primary" px="12px" py="3px" borderRadius="10px">
          <Center minHeight="8">
            <Text color="ligth">{notif.message}</Text>
          </Center>
        </Box>
      );
    },
  };
};
