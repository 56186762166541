import { Text as NBText } from "native-base";

export const Text = (props) => {
  return (
    <NBText fontFamily="Lato" fontWeight="400" fontSize="14px" {...props}>
      {props.children}
    </NBText>
  );
};

export const Title = (props) => {
  return (
    <Text
      fontSize="36px"
      fontFamily="Baloo"
      lineHeight="45px"
      fontWeight="400"
      color="primary"
      {...props}
    >
      {props.children}
    </Text>
  );
};
