import { Component } from "react";

class NotFoundPage extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <h1>NotFound 404</h1>
      </>
    );
  }
}

export default NotFoundPage;
