import VehicleAmbulanceIcon from "assets/img/ride/vehicle_ambulance.svg";
import VehicleAmbulanceHoverIcon from "assets/img/ride/vehicle_ambulance_hover.svg";
import VehicleVslIcon from "assets/img/ride/vehicle_vsl.svg";
import VehicleVslHoverIcon from "assets/img/ride/vehicle_vsl_hover.svg";

const VEHICLE_CATEGORIES = ["AMBULANCE", "VSL" /* ,"MEDECIN" */];
const USER_TYPE = ["AUXILIARY", "DEA"];
const TRANSPORT_TYPE = ["SIMPLE", "ROUND_TRIP", "SAMU", "SERIE"];
const RIDE_DATE = ["ALL", "YESTERDAY", "TODAY", "TOMORROW"];

const VEHICLE_OPTIONS = {
  OXYGEN: "Oxigène",
  CHILD_SAFETY_SEAT: "Siège enfant",
  PERFUSION: "Perfusion",
  POUSSE_SERINGUE: "Pousse seringue",
  ISOLEMENT: "Isolement",
  WHEELCHAIR: "Wheelchair",
};

const DRIVER_STATUS = {
  disconnected: "Déconnecté",
  offline: "Hors ligne",
  free: "Libre",
  busy: "Occupé",
  invoked: "En attente",
};

const HISTORY_RIDE_STATUS = ["completed", "canceled"];

const ONGOING_RIDE_STATUS = [
  "unassigned",
  "pending",
  "onway",
  "waiting",
  "onboard",
  "arrived",
];

const RIDE_STATUS = {
  incoming: "Attribution en cours",
  pending: "Course en attente",
  onway: "Vehicule en chemin",
  arrived: "Véhicule arrivé",
  waiting: "Véhicule arrivé",
  onboard: "Patient à bord",
  completed: "Course terminée",
  canceled: "Reservation annulée",
};

const PATIENT_STATUS = ["waiting", "admission", "ready"];
const PATIENT_RIDE_STATUS = {
  incoming: "Attribution en cours...",
  onway: "Vehicule en chemin",
  arrived: "Véhicule arrivé",
  waiting: "Votre vehicule vous attend",
  onboard: "Vous êtes à bord",
  completed: "Votre transport est terminé",
  canceled: "Reservation annulée",
};

const VEHICLE_ICONS = {
  hover: {
    AMBULANCE: VehicleAmbulanceHoverIcon,
    VSL: VehicleVslHoverIcon,
    MEDECIN: VehicleVslHoverIcon,
  },
  default: {
    AMBULANCE: VehicleAmbulanceIcon,
    VSL: VehicleVslIcon,
    MEDECIN: VehicleVslIcon,
  },
};

export {
  RIDE_STATUS,
  PATIENT_RIDE_STATUS,
  DRIVER_STATUS,
  VEHICLE_CATEGORIES,
  VEHICLE_OPTIONS,
  VEHICLE_ICONS,
  USER_TYPE,
  HISTORY_RIDE_STATUS,
  ONGOING_RIDE_STATUS,
  TRANSPORT_TYPE,
  RIDE_DATE,
  PATIENT_STATUS,
};
