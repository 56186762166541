import _ from "lodash";
import { put, takeLatest } from "redux-saga/effects";
import SettingsActions, { SettingsTypes } from "reducers/settings";

const graphQLClient = () => {
  const { Store } = require("../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* profileAsyncRequest() {
  const query = `
    query { 
      transporterUserProfile
    }
  `;
  try {
    const { transporterUserProfile } = yield graphQLClient().request(query);
    yield put(SettingsActions.profileSuccess(transporterUserProfile));
  } catch ({ response }) {
    const { errors = [{ message: "" }] } = response;
    yield put(SettingsActions.profileFailure(errors[0].message));
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [takeLatest(SettingsTypes.PROFILE_REQUEST, profileAsyncRequest)];
