import { all } from "redux-saga/effects";
import AccountSaga from "./account";
import TransporterIncomingRides from "./ride/transporter-incoming-rides";
import TransporterRidesHistory from "./transporter/transporter-rides-history";
import OnGoingRides from "./transporter/ongoing-rides";
import TransporterVehicle from "./transporter/transporter-vehicle";
import TransporterIncomingSeries from "./serie/transporter-incoming-series";
import DriverLocation from "./ride/driver-location";
import TransporterSeriesHistory from "./transporter/transporter-series-history";
import TransporterUsers from "./transporter/transporter-user";
import Minio from "./minio";
import Settings from "./settings";
import Public from "./public";

export default function* root() {
  yield all([
    ...AccountSaga,
    ...TransporterIncomingRides,
    ...TransporterRidesHistory,
    ...OnGoingRides,
    ...TransporterVehicle,
    ...TransporterIncomingSeries,
    ...DriverLocation,
    ...TransporterSeriesHistory,
    ...Minio,
    ...TransporterUsers,
    ...Settings,
    ...Public,
  ]);
}
