import { Subscription } from "@apollo/client/react/components";
import gql from "graphql-tag";
import { useDispatch } from "react-redux";
import OnGoingRideActions from "../../../reducers/transporter/transporter-ongoing-rides";
import HistorySerieActions from "../../../reducers/transporter/transporter-series-history";

const TRANSPORTER_SERIE_STATUS_SUBSCRIPTION = gql`
  subscription {
    TransporterSerieStatusSubscription {
      _id
      dates
      start
      end
    }
  }
`;

const OnGoingSerieStatusNotification = ({ showNotification }) => {
  const dispatch = useDispatch();
  function onSubscriptionData(serie) {
    if (serie !== undefined) {
      // load the last rides
      dispatch(OnGoingRideActions.onGoingRidesRequest(1, 10));
      dispatch(HistorySerieActions.transporterSerieStatusChanged(serie));

      showNotification({
        page: "/history",
        message: `la série à été rempli par le patient`,
        type: "success",
        readed: false,
      });
    }
  }

  return (
    <Subscription
      subscription={TRANSPORTER_SERIE_STATUS_SUBSCRIPTION}
      onSubscriptionData={({ subscriptionData }) => {
        onSubscriptionData(
          subscriptionData.data.TransporterSerieStatusSubscription
        );
      }}
    >
      {({ data = {}, loading, error }) => null}
    </Subscription>
  );
};

export default OnGoingSerieStatusNotification;
