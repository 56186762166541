import { Component } from "react";
import { Button as NBButton } from "native-base";

const thirdButtonStyle = {
  bg: "ligth",
  bgHover: "ligth",
  color: "primary",
  colorHover: "primaryDark",
  borderColor: "primary",
};

const secondaryButtonStyle = {
  bg: "grayLight",
  bgHover: "grayLight",
  color: "primary",
  colorHover: "primaryDark",
  borderColor: "grayLight",
};

const primaryButtonStyle = {
  bg: "primary",
  bgHover: "primaryDark",
  color: "ligth",
  colorHover: "ligth",
  borderColor: "primary",
};

const getButtonTheme = (props) => {
  if (props.secondary) return secondaryButtonStyle;
  if (props.third) return thirdButtonStyle;
  return primaryButtonStyle;
};

class Button extends Component {
  render() {
    const { bg, color, colorHover, bgHover, borderColor } = getButtonTheme(
      this.props
    );
    const colorProps = this.props.color ? this.props.color : false;

    return (
      <NBButton
        borderWidth={"2"}
        borderColor={borderColor}
        borderRadius="20px"
        py="4px"
        px="8px"
        bg={bg}
        _text={{
          fontSize: "14px",
          fontFamily: "Lato",
          lineHeight: "17px",
          fontWeight: "700",
          color: colorProps || color,
        }}
        _pressed={{
          _text: {
            color: colorProps || colorHover,
          },
          bg: bgHover,
        }}
        _focus={{
          _text: {
            color: colorProps || colorHover,
          },
          bg: bgHover,
        }}
        _hover={{
          _text: {
            color: colorProps || colorHover,
          },
          bg: bgHover,
        }}
        _disabled={{
          bg: "gray",
          borderWidth: "0px",
          _text: {
            color: "ligth",
          },
        }}
        {...this.props}
      >
        {this.props.children}
      </NBButton>
    );
  }
}

export default Button;
