import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  signinRequest: ["email", "password"],
  signinRequestSuccess: ["token"],
  signinRequestFailure: ["error"],

  signoutRequest: ["callback"],

  forgotPasswordRequest: ["email", "callback"],
  forgotPasswordSuccess: ["callback"],
  forgotPasswordFailure: ["error"],

  resetPasswordRequest: ["token", "password", "callback"],
  resetPasswordSuccess: ["callback"],
  resetPasswordFailure: ["error"],

  registerRequest: ["user", "company", "callback"],
  registerSuccess: null,
  registerFailure: ["error"],
});

export const AccountTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  // user: {},
  token: null,
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

// SIGN IN
export const signinRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const signinRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["token"], action.token);
};

export const signinRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

// SIGN OUT
export const signoutRequest = (state, action) => {
  return INITIAL_STATE;
};

// FORGOT REQUEST
export const forgotPasswordRequest = signinRequest;

export const forgotPasswordSuccess = signoutRequest;

export const forgotPasswordFailure = signinRequestFailure;

// RESET
export const resetPasswordRequest = signinRequest;

export const resetPasswordSuccess = signoutRequest;

export const resetPasswordFailure = signinRequestFailure;

// REGISTER
export const registerRequest = signinRequest;

export const registerSuccess = signoutRequest;

export const registerFailure = signinRequestFailure;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SIGNIN_REQUEST]: signinRequest,
  [Types.SIGNIN_REQUEST_SUCCESS]: signinRequestSuccess,
  [Types.SIGNIN_REQUEST_FAILURE]: signinRequestFailure,
  [Types.SIGNOUT_REQUEST]: signoutRequest,

  [Types.FORGOT_PASSWORD_REQUEST]: forgotPasswordRequest,
  [Types.FORGOT_PASSWORD_SUCCESS]: forgotPasswordSuccess,
  [Types.FORGOT_PASSWORD_FAILURE]: forgotPasswordFailure,

  [Types.RESET_PASSWORD_REQUEST]: resetPasswordRequest,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.RESET_PASSWORD_FAILURE]: resetPasswordFailure,

  [Types.REGISTER_REQUEST]: registerRequest,
  [Types.REGISTER_SUCCESS]: registerSuccess,
  [Types.REGISTER_FAILURE]: registerFailure,
});
