import { put, takeLatest } from "redux-saga/effects";
import OngoingRidesActions, {
  OngoingRideTypes,
} from "reducers/transporter/transporter-ongoing-rides";
import _ from "lodash";
import errorHandler from "sagas/error-handler";
import { getFilterQuery } from "functions";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* onGoingRidesAsyncRequest({ page, limit, filter }) {
  const query = `
    query {
      TransporterOnGoingRides(page:${page}, limit:${limit}, filter:${getFilterQuery(
    filter
  )}) {
        page 
        limit
        totalPages
        rides {
          _id
          patient {
            firstname
            lastname
            phone
            email
            nss
            status
            age
            gender
          }
          pickup{address latitude longitude}
          dropoff{address latitude longitude}        
          status
          rideNumber
          transporter {
            vehicle {
              plate
              category
              make
              color
              model
              phone
            }
            driver
          }
          datetime
          vehicleOptions
          vehicleCategory
          service {
            name
            phone
            company{ name phone }
          }
          serie {
            firstIsReturn
          }
          rideType
          samu { name }
          observation
          intervention { numeroRue rue codePostal commune complement batiment digicode etage poi }
          created { emergencyTime }
          waitingTime { duration_in_traffic }
          note
          isRoundTrip
          files { path name extension }
        }
      }
    }
  `;

  try {
    const { TransporterOnGoingRides } = yield graphQLClient().request(query);

    yield put(
      OngoingRidesActions.onGoingRidesRequestSuccess(TransporterOnGoingRides)
    );
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, OngoingRidesActions.onGoingRidesRequestFailure);
  }
}

function* transporterAssignVehicleForRideAsyncRequest({ rideId, vehicle }) {
  const query = `
    mutation {
      TransporterAssignVehicleForRide(rideId:"${rideId}", vehicleId:"${vehicle}")
      {
        _id
        status
        transporter {
          vehicle {
            plate
            category
            make
            model
            color
          }
        }
      }
    }
  `;

  try {
    const data = yield graphQLClient().request(query);

    const rides = data.TransporterAssignVehicleForRide;
    yield put(
      OngoingRidesActions.transporterAssignVehicleForRideSuccess(rides)
    );
    yield put(OngoingRidesActions.onGoingRidesRemoveRideNeedAssign(rideId));
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      OngoingRidesActions.transporterAssignVehicleForRideFailure
    );
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(OngoingRideTypes.ON_GOING_RIDES_REQUEST, onGoingRidesAsyncRequest),
  takeLatest(
    OngoingRideTypes.TRANSPORTER_ASSIGN_VEHICLE_FOR_RIDE_REQUEST,
    transporterAssignVehicleForRideAsyncRequest
  ),
];
