import {
  LoginPage,
  ForgotPassword,
  SuccessRegister,
  Register,
} from "views/public";
import {
  NotFoundPage,
  InComingRides,
  OnGoingRides,
  HistoryRides,
  TransporterVehicles,
  StaffPage,
  SettingsPage,
} from "views/private";
import { ReactComponent as incomingIcon } from "assets/img/menu/incoming.svg";
import { ReactComponent as ongoingIcon } from "assets/img/menu/ongoing.svg";
import { ReactComponent as historyIcon } from "assets/img/menu/history.svg";
import { ReactComponent as vehicleIcon } from "assets/img/menu/vehicle.svg";
import { ReactComponent as staffIcon } from "assets/img/menu/staff.svg";

const routes = [
  // PRIVATE
  {
    path: "/offer",
    layout: "/private",
    name: "Demandes",
    icon: incomingIcon,
    component: InComingRides,
  },
  {
    path: "/reservation",
    layout: "/private",
    name: "Réservation",
    icon: ongoingIcon,
    component: OnGoingRides,
  },
  {
    path: "/history",
    layout: "/private",
    name: "Historique",
    icon: historyIcon,
    component: HistoryRides,
  },
  {
    path: "/vehicle",
    layout: "/private",
    name: "Véhicule",
    icon: vehicleIcon,
    component: TransporterVehicles,
  },
  {
    path: "/staff",
    layout: "/private",
    name: "staff list",
    icon: staffIcon,
    component: StaffPage,
  },
  {
    hidden: true,
    path: "/settings",
    layout: "/private",
    name: "Paramètres",
    component: SettingsPage,
  },
  {
    hidden: true,
    path: "/",
    layout: "/private",
    name: "Demandes",
    component: InComingRides,
  },
  {
    hidden: true,
    path: "*",
    layout: "/private",
    name: "not found 404",
    component: NotFoundPage,
  },
  // PUBLIC
  {
    path: "/login",
    layout: "/public",
    name: "Page login",
    component: LoginPage,
  },
  {
    path: "/forgot",
    layout: "/public",
    name: "Forgot password",
    component: ForgotPassword,
  },
  {
    path: "/register",
    layout: "/public",
    name: "Register",
    component: Register,
  },
  {
    path: "/register-success",
    layout: "/public",
    name: "Success register",
    component: SuccessRegister,
  },
  {
    layout: "/public",
    path: `*`,
    component: LoginPage,
  },
];

export default routes;
