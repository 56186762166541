import { put, takeLatest } from "redux-saga/effects";
import TransporterRideHistoryActions, {
  TransporterRideHistoryTypes,
} from "reducers/transporter/transporter-rides-history";
import _ from "lodash";
import errorHandler from "sagas/error-handler";
import { getFilterQuery } from "functions";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* transporterRideHistoryAsyncRequest({ page, limit, filter }) {
  const query = `
    query {
      transporterRidesHistory(page:${page},limit:${limit},filter:${getFilterQuery(
    filter
  )}) {
        page
        limit
        totalPages
        rides {
          _id
          patient {
            firstname
            lastname
            phone
            email
            nss
            status
            age
            gender
          }
          pickup{address}
          dropoff{address}
          status
          rideNumber
          vehicleOptions
          transporter{
            company{name}
            vehicle {
              plate
              model
              make
              category
              color
              phone
            }
          }
          datetime
          service {
            name
            phone
            company{ name phone }
          }
          createdAt
          rideType
          samu { name }
          observation
          intervention { numeroRue rue codePostal commune complement batiment digicode etage poi }
          summary { onway onboard completed waiting arrived }
          isRoundTrip
          serie { firstIsReturn }
          note
          files { path name extension }
        }
      }
    }
  `;

  try {
    const data = yield graphQLClient().request(query);
    const rides = data.transporterRidesHistory;

    yield put(
      TransporterRideHistoryActions.transporterRideHistoryRequestSuccess(rides)
    );
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      TransporterRideHistoryActions.transporterRideHistoryRequestFailure
    );
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(
    TransporterRideHistoryTypes.TRANSPORTER_RIDE_HISTORY_REQUEST,
    transporterRideHistoryAsyncRequest
  ),
];
