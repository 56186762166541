import { Component } from "react";
import _ from "lodash";

import { Text } from "./Text";
import checkedIcon from "assets/img/patient-status-checked.svg";
import uncheckedIcon from "assets/img/patient-status-unchecked.svg";
import { HStack, VStack } from "native-base";

// class PatientStatusOld extends Component {
//   render() {
//     const { t, i18n, status } = this.props;

//     const admission = status === "ADMISSION";
//     const ready = status !== "WAITING" && !admission;

//     const textPlacement =
//       status === "WAITING" ? "start" : admission ? "center" : "end";
//     const statusText = {
//       pending: t("TransporterReservationsPage.status_patient_pending", {
//         lng: i18n.language,
//       }),
//       onway: t("TransporterReservationsPage.status_patient_onway", {
//         lng: i18n.language,
//       }),
//       ready: t("TransporterReservationsPage.status_patient_ready", {
//         lng: i18n.language,
//       }),
//     };

//     return (
//       <VStack space="1" w="140px">
//         <HStack alignItems="center">
//           <Box bg="primary" w="10px" h="5px" roundedLeft="full" />

//           <Center bg="primary" rounded="full" size="20px">
//             <Center bg="ligth" rounded="full" size="8px" />
//           </Center>

//           <Box bg="primary" w="34px" h="5px" />

//           <Center
//             bg={admission ? "primary" : ready ? "primary" : "ligthGray"}
//             rounded="full"
//             size="20px"
//           >
//             <Center bg="ligth" rounded="full" size="8px" />
//           </Center>

//           <Box
//             bg={admission ? "primary" : ready ? "primary" : "ligthGray"}
//             w="34px"
//             h="5px"
//           />

//           <Center
//             bg={ready ? "primary" : "ligthGray"}
//             rounded="full"
//             size="20px"
//           >
//             <Center bg="ligth" rounded="full" size="8px" />
//           </Center>

//           <Box
//             bg={ready ? "primary" : "ligthGray"}
//             w="10px"
//             h="5px"
//             roundedRight="full"
//           />
//         </HStack>

//         <HStack alignItems="center" w="full" justifyContent="space-between">
//           <Text
//             textAlign="center"
//             color="primary"
//             fontStyle="italic"
//             fontSize="12px"
//           >
//             {status === "WAITING" && statusText.pending}
//           </Text>
//           <Text
//             textAlign="center"
//             color="primary"
//             fontStyle="italic"
//             fontSize="12px"
//           >
//             {admission && statusText.onway}
//           </Text>
//           <Text
//             textAlign="center"
//             color="primary"
//             fontStyle="italic"
//             fontSize="12px"
//           >
//             {ready && statusText.ready}
//           </Text>
//         </HStack>
//       </VStack>
//     );
//   }
// }

class PatientStepButtonButton extends Component {
  constructor(props) {
    super(props);
    this.updateIndex = this.updateIndex.bind(this);

    this.state = {
      nextStep: 0,
    };
  }

  updateIndex() {
    this.setState({
      nextStep:
        _.indexOf(
          _.map(this.props.steps, "key"),
          this.props.value.toLowerCase()
        ) + 1,
    });
  }

  componentDidMount() {
    this.updateIndex();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.updateIndex();
    }
  }

  renderSteps() {
    return _.reduce(
      this.props.steps,
      (acc, el, index) => {
        return [
          ...acc,
          <HStack value={index} key={el.key} space={0.5}>
            <img
              src={index < this.state.nextStep ? checkedIcon : uncheckedIcon}
              alt="patient status"
            />
            <Text
              fontSize="12px"
              fontWeight={500}
              color={index < this.state.nextStep - 1 ? "gray" : "black"}
            >
              {el.value}
            </Text>
          </HStack>,
        ];
      },
      []
    );
  }
  render() {
    return <VStack space={1}>{this.renderSteps()}</VStack>;
  }
}

export default PatientStepButtonButton;
