import React, { Component, useEffect } from "react";
import { Modal, Stack } from "native-base";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import resaamVehicle from "assets/img/AmbulanceMap.svg";
import LieuDarrivee from "assets/img/Lieu-darrivee.svg";
import LieuDePriseEnCharge from "assets/img/Lieu-de-prise-en-charge.svg";
import googleMapStyle from "assets/googleMapStyle";

function MapView(props) {
  
  

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GMAPS_KEY,
  });

  const [map, setMap] = React.useState(null);
  const [direction, setDirection] = React.useState(null);

  /*const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);*/

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const fitBounds = () => {
    let markers = [];
    if (props.from) markers.push(props.from);
    if (_.isNumber(props.to?.lat) && _.isNumber(props.to?.lng)) markers.push(props.to);
    const bounds = new window.google.maps.LatLngBounds();
    markers.map((position) => {
      bounds.extend(position);
      return position;
    });
    map.fitBounds(bounds);
  };
  const onLoadMap = (map) => {
    setMap(map);
  };


  const getDirection = async () => {
    const directionService = new window.google.maps.DirectionsService();
    const direction = await directionService.route({
      origin: new window.google.maps.LatLng(props.from),
      destination: new window.google.maps.LatLng(props.to),
      travelMode: window.google.maps.TravelMode.DRIVING,
    });

    setDirection(direction);
  };

 

  useEffect(() => {
    if (map) {
      map.setZoom(13);
      fitBounds();
      if (props.from &&  (_.isNumber(props.to?.lat) && _.isNumber(props.to?.lng))) getDirection();
    }
  }, [props]);


  //console.log("MapView props", props);


  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ height: "100%", width: "100%" }}
      center={{ lat: props.latitude, lng: props.longitude }}
      onLoad={onLoadMap}
      onUnmount={onUnmount}
      options={googleMapStyle}
      zoom={13}
    >
      {/* Child components, such as markers, info windows, etc. */}
      {/*props.children*/}
      {props.from && (
        <Marker
          position={props.from}
          icon={{
            url: props.from.icon,
            //anchor: new google.maps.Point(5, 58)
          }}
        />
      )}

      { (_.isNumber(props.to?.lat) && _.isNumber(props.to?.lng)) && (
        <Marker
          position={props.to}
          icon={{
            url: props.to.icon,
            //anchor: new google.maps.Point(5, 58)
          }}
        />
      )}

      {direction && (
        <DirectionsRenderer
          directions={direction}
          options={{
            markerOptions: { icon: " ", anchorPoint: { x: 0.2, y: 0.2 } },
            polylineOptions: {
              zIndex: 10,
              strokeColor: "#9273FF",
              strokeWeight: 5,
            },
          }}
        />
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

class DriverLocationMapModal extends Component {
  render() {
    const { coordinates = { longitude: null, latitude: null }, ride } =
      this.props;
    const latitude = coordinates.latitude || 48.856614;
    const longitude = coordinates.longitude || 2.3522219;

    let to = undefined;
    if (ride && _.includes(["onway", "pending", "incoming"], ride.status)) {
      to = {
        lat: ride.pickup.latitude,
        lng: ride.pickup.longitude,
        icon: LieuDePriseEnCharge,
      };
    } else if (ride && ride.status === "onboard") {
      to = {
        lat: ride.dropoff.latitude,
        lng: ride.dropoff.longitude,
        icon: LieuDarrivee,
      };
    }

    return (
      <Modal {...this.props}>
        <Modal.Content maxWidth="588px" height="545px">
          {/* <Modal.CloseButton /> */}
          {/* <Modal.Header alignItems="center" borderBottomWidth="0px">
            <Text fontSize="24px" fontWeight="900" lineHeight="29px">
              Refuser une série
            </Text>
          </Modal.Header> */}
          <Modal.Body style={{ width: 588, height: 545 }}>
            <Stack top={0} bottom={0} right={0} left={0} position="absolute">
              <MapView
                latitude={latitude}
                longitude={longitude}
                from={{ lat: latitude, lng: longitude, icon: resaamVehicle }}
                to={to}
              />
            </Stack>
          </Modal.Body>
          {/* <Modal.Footer bg="light"></Modal.Footer> */}
        </Modal.Content>
      </Modal>
    );
  }
}

export default withTranslation(["Private", "Common"], { wait: true })(
  DriverLocationMapModal
);
