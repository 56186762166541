import { Component } from "react";
import { HStack, VStack, Box } from "native-base";
import Card from "./Card";
import { Text } from "../Text";
import { capitalize } from "functions";
// import VehicleOptions from "../VehicleOptions";
import NotSpecified from "components/NotSpecified";
// import threeDotIcon from "assets/img/three_dots.svg";

class VehicleCard extends Component {
  //   constructor(props) {
  //     super(props);
  //     this.delete = this.delete.bind(this);
  //   }

  //   delete() {
  //     const {
  //       transporterDeleteVehicleRequest,
  //       vehicle: { _id: vehicleId, plate },
  //     } = this.props;
  //     transporterDeleteVehicleRequest(vehicleId, () =>
  //       console.log(`La voiture avec la plaque ${plate} a été désactivé.`)
  //     );
  //   }
  render() {
    const { t, i18n, vehicle = {} } = this.props;
    const { color = "", model, make, plate, category, phone, inUse } = vehicle;

    return (
      <Card
        rounded="4px"
        maxWidth="986px"
        h="75px"
        py={0}
        pl="29px"
        mb="8px"
        borderColor="gray"
        borderWidth="1px"
      >
        {/* <Box alignItems="end" w="full">
          <Menu
            placement="bottom"
            trigger={(triggerProps) => (
              <Pressable
                {...triggerProps}
                accessibilityLabel="More option menu"
              >
                <img src={threeDotIcon} />
              </Pressable>
            )}
          >
            <Menu.Item className="logout" onPress={this.delete}>
              {t("vehiclesPage.button_delete_vehicle", { lng: i18n.language })}
            </Menu.Item>
          </Menu>
        </Box> */}
        <HStack h="full" space={4}>
          {/* <Box justifyContent="center" borderColor="gray" borderRightWidth={1} w="200px">
            <Text fontSize="16px" fontWeight="900">
              {"company.name"}
            </Text>
          </Box> */}

          <HStack pt="12px" flex={1} pl="20px">
            <VStack flex={1}>
              <Text fontSize="16px" fontWeight="900">
                {capitalize(model)}
              </Text>
              <Text fontSize="16px" fontWeight="900">
                {plate.toUpperCase()}
              </Text>
            </VStack>

            <VStack flex={1}>
              <Text fontSize="12px" fontWeight="500" color="gray">
                N° téléphone
              </Text>
              <Text fontSize="12px" fontWeight="500">
                {phone}
              </Text>
            </VStack>

            <VStack flex={1}>
              <Text fontSize="12px" fontWeight="500" color="gray">
                Marque du véhicule
              </Text>
              <Text fontSize="12px" fontWeight="500">
                {capitalize(make)}
              </Text>
            </VStack>

            <VStack flex={1}>
              <Text fontSize="12px" fontWeight="500" color="gray">
                Couleur
              </Text>
              <Text fontSize="12px" fontWeight="500">
                {color ? color : <NotSpecified />}
              </Text>
            </VStack>

            <VStack flex={1}>
              <Text fontSize="12px" fontWeight="500" color="gray">
                Type du véhicule
              </Text>
              <Text fontSize="12px" fontWeight="500">
                {category}
              </Text>
            </VStack>

            <HStack space="2" justifyContent="center" flex={1}>
              <Box rounded="full" size="15px" bg={inUse ? "info" : "success"} />
              <Text
                fontSize="14px"
                fontWeight="700"
                fontStyle="italic"
                color={inUse ? "info" : "success"}
              >
                {inUse ? "indisponible" : "disponible"}
              </Text>
            </HStack>
          </HStack>
        </HStack>
      </Card>
    );
  }
}

export default VehicleCard;
