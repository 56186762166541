import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import _ from "lodash";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  transporterSerieHistoryResetState: null,
  transporterSerieHistoryRequest: ["page", "limit", "filter"],
  transporterSerieHistoryRequestSuccess: ["series"],
  transporterSerieHistoryRequestFailure: ["error"],

  transporterSerieStatusChanged: ["serie"],
});

export const TransporterSerieHistoryTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  series: {
    page: 1,
    limit: 10,
    totalPages: 0,
    series: [],
  },
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

export const transporterSerieHistoryResetState = (state, action) => {
  return INITIAL_STATE;
};

export const transporterSerieHistoryRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const transporterSerieHistoryRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const transporterSerieHistoryRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  if (action.series.page == 1)
    //first page
    return Immutable.setIn(nextSate, ["series"], action.series);
  else {
    //apend next pages
    let prevList = state.series.series.asMutable({ deep: true });
    const { series, ...rest } = action.series;

    return Immutable.setIn(nextSate, ["series"], {
      ...rest,
      series: [...prevList, ...series],
    });
  }
};

export const transporterSerieStatusChanged = (state, action) => {
  const { serie: serieChanged } = action;
  let serieRequests = state.series.asMutable({ deep: true });

  let _series = _.map(serieRequests.series, (s) => {
    if (serieChanged._id === s._id) {
      return { ...s, ...serieChanged };
    }
    return s;
  });
  return Immutable.setIn(state, ["series", "series"], _series);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.TRANSPORTER_SERIE_HISTORY_RESET_STATE]:
    transporterSerieHistoryResetState,
  [Types.TRANSPORTER_SERIE_HISTORY_REQUEST]: transporterSerieHistoryRequest,
  [Types.TRANSPORTER_SERIE_HISTORY_REQUEST_SUCCESS]:
    transporterSerieHistoryRequestSuccess,
  [Types.TRANSPORTER_SERIE_HISTORY_REQUEST_FAILURE]:
    transporterSerieHistoryRequestFailure,

  [Types.TRANSPORTER_SERIE_STATUS_CHANGED]: transporterSerieStatusChanged,
});
