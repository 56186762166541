import { Subscription } from "@apollo/client/react/components";
import gql from "graphql-tag";

const TRANSPORTER_INCOMING_SERIE_LEFT_SUBSCRIPTION = gql`
  subscription {
    TransporterIncomingSerieLeftSubscription {
      _id
      status
    }
  }
`;

const IncomingSerieLeftNotification = ({
  transporterDidSerieLeft,
  transporterSerieGotByOther,
  showNotification,
}) => {
  function onSubscriptionData(serie) {
    if (serie !== undefined) {
      transporterSerieGotByOther(serie);
      setTimeout(() => {
        transporterDidSerieLeft(serie);
      }, 5_000);

      showNotification({
        page: "/offer",
        message: `la série n'est plus disponible`,
        type: "danger",
        readed: false,
      });
    }
  }

  return (
    <Subscription
      subscription={TRANSPORTER_INCOMING_SERIE_LEFT_SUBSCRIPTION}
      onSubscriptionData={({ subscriptionData }) => {
        onSubscriptionData(
          subscriptionData.data.TransporterIncomingSerieLeftSubscription
        );
      }}
    >
      {({ data = {}, loading, error }) => null}
    </Subscription>
  );
};

export default IncomingSerieLeftNotification;
