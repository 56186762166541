import { Component } from "react";
import {
  Alert,
  Box,
  Center,
  HStack,
  Pressable,
  Stack,
  View,
  VStack,
} from "native-base";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import _ from "lodash";
import Loader from "react-loader-spinner";
import Calendar from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import OngoingRidesActions from "reducers/transporter/transporter-ongoing-rides";
import DriverLocationActions from "reducers/ride/driver-location";
import datepickerLocal from "global/local-dates";
import "assets/style.css";
import noDataIcon from "assets/img/no_data.svg";
import { createInitialValueFromArray } from "functions";
import {
  Title,
  Text,
  OnGoingRidesCard,
  FloatingInput,
  NotificationMenu,
  AcceptRideModal,
  DriverLocationMapModal,
  FilterModal,
} from "components";
import OnGoingRidesCardOld from "components/Cards/OnGoingRidesCardOld";
import {
  VEHICLE_CATEGORIES,
  TRANSPORT_TYPE,
  ONGOING_RIDE_STATUS,
  PATIENT_STATUS,
  RIDE_DATE,
} from "global";

const FETCH_RIDES_TIME = 10_000; //10s

class OnGoingRides extends Component {
  constructor(props) {
    super(props);

    this.onSearchOngoingRidesFilter =
      this.onSearchOngoingRidesFilter.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onPressAcceptRideButton = this.onPressAcceptRideButton.bind(this);
    this.onCancelAcceptRidePopUp = this.onCancelAcceptRidePopUp.bind(this);
    this.onShowMap = this.onShowMap.bind(this);
    this.onToggleFilter = this.onToggleFilter.bind(this);
    this.onSaveFilter = this.onSaveFilter.bind(this);

    this.state = {
      searchInputValue: "",
      filterDates: [],
      showAcceptRidePopUp: false,
      selectedRide: null,
      rideToPassToModal: null,
      showMapOpen: false,
      selectedRideToShowDriverLocation: 0,
      showFilter: false,
      filter: {
        vehicleCategories: createInitialValueFromArray(VEHICLE_CATEGORIES),
        rideType: createInitialValueFromArray(TRANSPORT_TYPE),
        rideStatus: createInitialValueFromArray(ONGOING_RIDE_STATUS),
        patientStatus: createInitialValueFromArray(PATIENT_STATUS),
        rideDate: RIDE_DATE[0],
        vehicle: "ALL",
        driver: "ALL",
      },
    };
  }

  componentDidMount() {
    const { onGoingRidesRequest } = this.props;

    onGoingRidesRequest(1, 10, {
      text: "",
      dates: "",
      advanced: this.state.filter,
    });

    this.rideIntervalId = setInterval(() => {
      const { filterDates, searchInputValue } = this.state;

      onGoingRidesRequest(1, 100, {
        text: searchInputValue,
        dates: filterDates.length !== 0 ? `"${filterDates.join('","')}"` : "",
        advanced: this.state.filter,
      });
    }, [FETCH_RIDES_TIME]);

    this.driverLocationIntervalId = setInterval(() => {
      const { showMapOpen, selectedRideToShowDriverLocation } = this.state;

      if (!showMapOpen) return;

      this.getCurrentDriverLocationForSelectedRide(
        selectedRideToShowDriverLocation
      );
    }, 30 * 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterDates, searchInputValue, filter } = this.state;
    if (
      prevState.filterDates !== filterDates ||
      prevState.searchInputValue !== searchInputValue ||
      prevState.filter !== filter
    ) {
      this.props.onGoingRidesRequest(1, 10, {
        text: searchInputValue,
        dates: filterDates.length !== 0 ? `"${filterDates.join('","')}"` : "",
        advanced: filter,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.driverLocationIntervalId);
    clearInterval(this.rideIntervalId);
  }

  onSearchOngoingRidesFilter(e) {
    const { value } = e.target;
    this.setState({ searchInputValue: value });
  }

  fetchData() {
    const { onGoingRidesRequest, rides } = this.props;
    const { page, limit, totalPages } = rides;
    if (page < totalPages) {
      onGoingRidesRequest(page + 1, limit, {
        text: this.state.searchInputValue,
        dates: this.state.filterDates,
        advanced: this.state.filter,
      });
    }
  }

  onPressAcceptRideButton(ride) {
    this.setState(
      Object.assign({}, this.state, {
        showAcceptRidePopUp: true,
        selectedRide: ride,
      })
    );
  }

  onCancelAcceptRidePopUp() {
    this.setState(
      Object.assign({}, this.state, { showAcceptRidePopUp: false })
    );
  }

  onToggleFilter() {
    this.setState(
      Object.assign({}, this.state, { showFilter: !this.state.showFilter })
    );
  }

  onSaveFilter(obj) {
    this.setState({ filter: obj });
  }

  onShowMap(ride) {
    this.setState({
      showMapOpen: true,
      selectedRideToShowDriverLocation: ride,
      rideToPassToModal: ride,
    });
    this.getCurrentDriverLocationForSelectedRide(ride);
  }

  getCurrentDriverLocationForSelectedRide(ride) {
    const { transporterGetRideDriverLocationRequest } = this.props;
    transporterGetRideDriverLocationRequest(ride.transporter.driver);
  }

  render() {
    const { t, i18n, action, rides, driverCoords, ridesNeedAssign } =
      this.props;
    const { error, loading } = action;
    const {
      showAcceptRidePopUp,
      selectedRide,
      searchInputValue,
      selectedRideToShowDriverLocation,
      rideToPassToModal,
    } = this.state;
    let coords = {};

    if (
      selectedRideToShowDriverLocation != 0 &&
      selectedRideToShowDriverLocation.transporter.driver ===
        driverCoords.driverId
    ) {
      coords = driverCoords;
    }

    return (
      <View w="full">
        <VStack space="5" ml="24" mr="12" h="full">
          <VStack space={5} h="48" mt="5" zIndex={1}>
            {error !== "" && (
              <Alert bsStyle="danger">
                <span>{error}</span>
              </Alert>
            )}

            <Title>
              {t("TransporterReservationsPage.reservation_title_headline", {
                lng: i18n.language,
              })}
            </Title>

            {/* Notification */}
            <Stack alignItems="end">
              <NotificationMenu
                items={this.props.notifications}
                onReadedNotifications={this.props.onReadedNotifications}
              />
            </Stack>
            {/* Search */}
            <HStack justifyContent="space-between">
              <HStack alignItems="center" space={8}>
                <FloatingInput
                  inputType="search"
                  label={t("searchBar", { lng: i18n.language })}
                  onChange={this.onSearchOngoingRidesFilter}
                  value={searchInputValue}
                />
                <Pressable onPress={this.onToggleFilter}>
                  <HStack space={2}>
                    <FontAwesomeIcon
                      icon={faFilter}
                      size="lg"
                      color="#032A47"
                    />
                    <Text fontSize="16px" fontWeight={700} color="#032A47">
                      Filtre
                    </Text>
                  </HStack>
                </Pressable>
              </HStack>

              <Box justifyContent="center" position="relative">
                <Calendar
                  multiple={true}
                  value={this.state.filterDates}
                  weekStartDayIndex={1}
                  disableDayPicker={false}
                  showOtherDays={false}
                  calendarPosition="Right Top"
                  className="container-input-calendar"
                  render={<InputIcon />}
                  locale={datepickerLocal}
                  shadow={false}
                  onChange={(selectedDates) => {
                    this.setState({ filterDates: selectedDates });
                  }}
                />
              </Box>
            </HStack>
          </VStack>

          {/* No Data */}
          {rides.rides.length == 0 ? (
            <Center flex={1}>
              <img src={noDataIcon} />
              <Text fontSize="18">
                {t("TransporterReservationsPage.message_when_no_data")}
              </Text>
            </Center>
          ) : (
            <Text italic color="gray">
              Pour plus de détails appuyez sur la fiche transport
            </Text>
          )}

          <div
            id="scrollableDiv"
            style={{
              overflow: "auto",
            }}
          >
            <InfiniteScroll
              dataLength={rides.rides.length} //This is important field to render the next data
              next={this.fetchData}
              hasMore={true}
              scrollableTarget="scrollableDiv"
              loader={
                <Loader
                  type="ThreeDots"
                  color="#00BFFF"
                  height={100}
                  width={100}
                  visible={loading}
                />
              }
            >
              {rides.rides.map((ride, key) => (
                <OnGoingRidesCard
                  {...{ t, i18n }}
                  ride={ride}
                  key={key}
                  mb="10px"
                  onShowMap={this.onShowMap}
                  downloadFiles={this.props.downloadFiles}
                  onPressAcceptRideButton={this.onPressAcceptRideButton}
                />
              ))}
              {/* {rides.rides.map((ride, key) => (
                <OnGoingRidesCardOld
                  {...{ t, i18n }}
                  ride={ride}
                  key={key}
                  mb='10px'
                  downloadFiles={this.downloadFiles}
                  ridesNeedAssign={ridesNeedAssign}
                  onShowMap={this.onShowMap}
                />
              ))} */}
            </InfiniteScroll>
          </div>
        </VStack>

        <FilterModal
          onSave={this.onSaveFilter}
          isOpen={this.state.showFilter}
          onClose={this.onToggleFilter}
          vehicles={this.props.vehicle.vehicles}
          drivers={this.props.transporterUsers.users.users.filter(
            (u) => u.userType
          )}
          initalState={this.state.filter}
        />

        <AcceptRideModal
          {...{ t, i18n }}
          selectedRide={selectedRide}
          isOpen={showAcceptRidePopUp}
          isTransporterAssignVehicleForRide={true}
          transporterAssignVehicleForRideRequest={
            this.props.transporterAssignVehicleForRideRequest
          }
          onClose={this.onCancelAcceptRidePopUp}
          companyVehicles={this.props.vehicle.vehicles}
        />

        <DriverLocationMapModal
          isOpen={this.state.showMapOpen}
          coordinates={coords}
          ride={rideToPassToModal}
          onClose={() => {
            this.setState(
              Object.assign({}, this.state, { showMapOpen: false })
            );
          }}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rides: state.onGoingRides.rides.asMutable({ deep: true }),
    action: state.onGoingRides.action.asMutable({ deep: true }),
    driverCoords: state.driverLocation.coords.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGoingRidesRequest: (...args) =>
      dispatch(OngoingRidesActions.onGoingRidesRequest(...args)),
    transporterAssignVehicleForRideRequest: (...args) =>
      dispatch(
        OngoingRidesActions.transporterAssignVehicleForRideRequest(...args)
      ),
    transporterGetRideDriverLocationRequest: (...args) =>
      dispatch(
        DriverLocationActions.transporterGetRideDriverLocationRequest(...args)
      ),
  };
};

const reservationsRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(OnGoingRides);
export default withTranslation(["Private", "Common"], { wait: true })(
  reservationsRedux
);
