import React from "react";
import { useTranslation } from "react-i18next";

import { Text } from "./Text";

function NotSpecified(props) {
  const { t, i18n } = useTranslation("Private");
  return (
    <Text fontSize="12px" italic>
      {t("TransporterReservationsPage.not_specified", {
        lng: i18n.language,
      })}
    </Text>
  );
}

export default NotSpecified;
