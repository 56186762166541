import { combineReducers } from "redux";

export default combineReducers({
  account: require("./account").reducer,
  onGoingRides: require("./transporter/transporter-ongoing-rides").reducer,
  inComingRides: require("./ride/transporter-incoming-rides").reducer,
  historyRides: require("./transporter/transporter-rides-history").reducer,
  transporterVehicles: require("./transporter/transporter-vehicle").reducer,
  incomingSeries: require("./serie/transporter-incoming-series").reducer,
  driverLocation: require("./ride/driver-location").reducer,
  historySeries: require("./transporter/transporter-series-history").reducer,
  minio: require("./minio").reducer,
  transporterUsers: require("./transporter/transporter-user").reducer,
  settings: require("./settings").reducer,
  public: require("./public").reducer,
  // transporterVehiclesDrivers: require('./transporter/transporter-vehicles-drivers').reducer,
});
