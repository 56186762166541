import { Box, Center, Stack } from "native-base";
import { useTranslation } from "react-i18next";
import { Text } from "./Text";
import childSafetySeatIcon from "assets/img/vehicle-options/child_safety_seat.svg";
import fractureIcon from "assets/img/vehicle-options/fracture.svg";
import infusionIcon from "assets/img/vehicle-options/infusion.svg";
import isolationIcon from "assets/img/vehicle-options/isolation.svg";
import syringe_driverIcon from "assets/img/vehicle-options/syringe_driver.svg";
import wheelChairIcon from "assets/img/vehicle-options/wheelchair.svg";
import oxygenIcon from "assets/img/vehicle-options/oxygen.svg";
import _ from "lodash";
import "assets/style.css";

const VEHICLE_OPTIONS = [
  {
    name: "CHILD_SAFETY_SEAT",
    icon: childSafetySeatIcon,
  },
  {
    name: "FRACTURE",
    icon: fractureIcon,
  },
  {
    name: "PERFUSION",
    icon: infusionIcon,
  },
  {
    name: "ISOLEMENT",
    icon: isolationIcon,
  },
  {
    name: "POUSSE_SERINGUE",
    icon: syringe_driverIcon,
  },
  {
    name: "OXYGEN",
    icon: oxygenIcon,
  },
  {
    name: "WHEELCHAIR",
    icon: wheelChairIcon,
  },
];

const Option = ({ value, t, i18n }) => {
  for (const option of VEHICLE_OPTIONS) {
    if (option.name == value) {
      const label = t(`vehicleOptions.status.${option.name}`, {
        lng: i18n.language,
      });
      return (
        <Center minHeight="50px" justifyContent="space-between">
          <img src={option.icon} alt={label} width="30" />
          <Text fontStyle="italic" fontSize="14px" color="gray">
            {label}
          </Text>
        </Center>
      );
    }
  }
  return null;
};

const VehicleOptions = ({ options }) => {
  const { t, i18n } = useTranslation();
  if (!_.isArray(options)) return null;
  return (
    <div className="vehicle-options-grid">
      {options.map((option, key) => {
        return <Option key={key} value={option} {...{ t, i18n }} />;
      })}
    </div>
  );
};

export default VehicleOptions;
