import { Subscription } from "@apollo/client/react/components";
import gql from "graphql-tag";

const TRANSPORTER_RIDE_STATUS_SUBSCRIPTION = gql`
  subscription {
    TransporterRideStatusSubscription {
      _id
      status
      rideNumber
      dropoff {
        address
      }
    }
  }
`;

const OnGoingRideStatusNotification = ({
  onGoingRideStatusChanged,
  onGoingRidesRemoveRide,
  showNotification,
}) => {
  function onSubscriptionData(ride) {
    if (ride !== undefined) {
      onGoingRideStatusChanged(ride);
      if (ride.status === "canceled" || ride.status === "completed") {
        onGoingRidesRemoveRide(ride);
        // showNotification({
        //   page: "/history",
        //   message: `Transport ${ride.rideNumber} ajouté dans l'historique`,
        //   type: "info",
        //   readed: false,
        // });
      } else {
        showNotification({
          page: "/reservation",
          message: `Status du transport ${ride.rideNumber} a changé`,
          type: "info",
          readed: false,
        });
      }
    }
  }

  return (
    <Subscription
      subscription={TRANSPORTER_RIDE_STATUS_SUBSCRIPTION}
      onSubscriptionData={({ subscriptionData }) => {
        onSubscriptionData(
          subscriptionData.data.TransporterRideStatusSubscription
        );
      }}
    >
      {({ data = {}, loading, error }) => null}
    </Subscription>
  );
};

export default OnGoingRideStatusNotification;
