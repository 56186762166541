import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import _ from "lodash";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  transporterIncomingRidesResetState: null,
  transporterIncomingRidesRequest: ["page", "limit", "filter"],
  transporterIncomingRidesRequestSuccess: ["rides"],
  transporterIncomingRidesRequestFailure: ["error"],

  transporterAcceptProposedRideRequest: [
    "ride",
    "vehicle",
    "arriveIn",
    /*"driver",*/ "callback",
  ],
  transporterAcceptProposedRideRequestSuccess: ["ride"],
  transporterAcceptProposedRideRequestFailure: ["error"],
  transporterProposedRideRequestNotAvailable: ["ride"],
  // request incomingrideNotification
  // push en premier dans le tableau
  transporterDidReceiveRide: ["ride"],
  transporterDidRideLeft: ["ride"],
  transporterRideGotByOther: ["ride"],

  // request incoming ride left quand transport pris par autrui
  transporterRequestDatetimeValidationForProposedRideRequest: [
    "ride",
    "datetimeOffer",
  ],
  transporterRequestDatetimeValidationForProposedRideSuccess: ["ride"],
  transporterRequestDatetimeValidationForProposedRideFailure: ["error"],

  transporterRefuseIncomingRideRequest: ["ride"],
  transporterRefuseIncomingRideSuccess: ["ride"],
  transporterRefuseIncomingRideFailure: ["error"],

  transporterClearError: null,
});

export const TransporterIncomingRideTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  rides: {
    page: 1,
    limit: 10,
    totalPages: 0,
    rides: [],
  },
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

/// GENERAL
export const transporterIncomingRidesResetState = (state, action) => {
  return INITIAL_STATE;
};

export const transporterIncomingRidesRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const transporterIncomingRidesRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const transporterIncomingRidesRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  if (action.rides.page == 1)
    //first page
    return Immutable.setIn(nextSate, ["rides"], action.rides);
  else {
    //apend next pages
    let prevList = state.rides.rides.asMutable({ deep: true });
    const { rides, ...rest } = action.rides;

    return Immutable.setIn(nextSate, ["rides"], {
      ...rest,
      rides: [...prevList, ...rides],
    });
  }
};

export const transporterDidReceiveRide = (state, action) => {
  let rideRequests = state.rides.asMutable({ deep: true });
  rideRequests = [action.ride, ...rideRequests.rides];
  return Immutable.setIn(state, ["rides", "rides"], rideRequests);
};

export const transporterAcceptProposedRideRequest = (state, action) => {
  let nextState = Immutable.setIn(state, ["action", "error"], "");
  return Immutable.setIn(nextState, ["action", "loading"], true);
};

export const transporterAcceptProposedRideRequestFailure = (state, action) => {
  let nextState = Immutable.setIn(state, ["action", "error"], action.error);
  return Immutable.setIn(nextState, ["action", "loading"], false);
};
export const transporterAcceptProposedRideRequestSuccess = (state, action) => {
  let rideRequests = state.rides.asMutable({ deep: true });
  let { rides } = rideRequests;

  rides = _.reduce(
    rides,
    (acc, r) => {
      if (r._id !== action.ride._id) return [...acc, r];
      return acc;
    },
    []
  );

  let nextState = Immutable.setIn(state, ["rides", "rides"], rides);
  return Immutable.setIn(nextState, ["action", "loading"], false);
};

export const transporterProposedRideRequestNotAvailable =
  transporterAcceptProposedRideRequestSuccess;

export const transporterDidRideLeft =
  transporterAcceptProposedRideRequestSuccess;
// export const transporterDidRideLeft = (state, action) => {
//     let rideRequests = state.rides.asMutable({ deep: true });
//     let { rides } = rideRequests;

//     rides = _.reduce(rides, (acc, r) => {
//         if (r._id !== action.ride._id) return [...acc, r];
//         return acc;
//     }, []);

//     let nextState = Immutable.setIn(state, ['rides', 'rides'], rides);
//     return Immutable.setIn(nextState, ['action', 'loading'], false);
// }

export const transporterRideGotByOther = (state, action) => {
  let rideRequests = state.rides.asMutable({ deep: true });
  let { rides } = rideRequests;
  rides = _.reduce(
    rides,
    (acc, r) => {
      if (r._id === action.ride._id) {
        r.status = "pending";
      }
      return [...acc, r];
    },
    []
  );

  let nextState = Immutable.setIn(state, ["rides", "rides"], rides);
  return Immutable.setIn(nextState, ["action", "loading"], false);
};

export const transporterRequestDatetimeValidationForProposedRideRequest = (
  state,
  action
) => {
  let nextState = Immutable.setIn(state, ["action", "error"], "");
  return Immutable.setIn(nextState, ["action", "loading"], true);
};

export const transporterRequestDatetimeValidationForProposedRideFailure = (
  state,
  action
) => {
  let nextState = Immutable.setIn(state, ["action", "error"], action.error);
  return Immutable.setIn(nextState, ["action", "loading"], false);
};
export const transporterRequestDatetimeValidationForProposedRideSuccess = (
  state,
  action
) => {
  let rideRequests = state.rides.asMutable({ deep: true });
  let { rides } = rideRequests;

  rides = _.reduce(
    rides,
    (acc, r) => {
      if (r._id === action.ride) {
        r.status = "anoffer";
      }
      return [...acc, r];
    },
    []
  );

  let nextState = Immutable.setIn(state, ["rides", "rides"], rides);
  return Immutable.setIn(nextState, ["action", "loading"], false);
};

export const transporterRefuseIncomingRideRequest = (state, action) => {
  let nextState = Immutable.setIn(state, ["action", "error"], "");
  return Immutable.setIn(nextState, ["action", "loading"], true);
};

export const transporterRefuseIncomingRideSuccess = (state, action) => {
  let rideRequests = state.rides.asMutable({ deep: true });
  let { rides } = rideRequests;
  const _rides = rides.filter((r) => r._id !== action.ride._id);
  let nextState = Immutable.setIn(state, ["rides", "rides"], _rides);
  return Immutable.setIn(nextState, ["action", "loading"], false);
};

export const transporterRefuseIncomingRideFailure = (state, action) => {
  let nextState = Immutable.setIn(state, ["action", "error"], action.error);
  return Immutable.setIn(nextState, ["action", "loading"], false);
};

export const transporterClearError = (state, action) => {
  return Immutable.setIn(state, ["action", "error"], "");
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.TRANSPORTER_INCOMING_RIDES_RESET_STATE]:
    transporterIncomingRidesResetState,
  [Types.TRANSPORTER_INCOMING_RIDES_REQUEST]: transporterIncomingRidesRequest,
  [Types.TRANSPORTER_INCOMING_RIDES_REQUEST_SUCCESS]:
    transporterIncomingRidesRequestSuccess,
  [Types.TRANSPORTER_INCOMING_RIDES_REQUEST_FAILURE]:
    transporterIncomingRidesRequestFailure,

  [Types.TRANSPORTER_ACCEPT_PROPOSED_RIDE_REQUEST]:
    transporterAcceptProposedRideRequest,
  [Types.TRANSPORTER_ACCEPT_PROPOSED_RIDE_REQUEST_SUCCESS]:
    transporterAcceptProposedRideRequestSuccess,
  [Types.TRANSPORTER_PROPOSED_RIDE_REQUEST_NOT_AVAILABLE]:
    transporterProposedRideRequestNotAvailable,
  [Types.TRANSPORTER_ACCEPT_PROPOSED_RIDE_REQUEST_FAILURE]:
    transporterAcceptProposedRideRequestFailure,

  [Types.TRANSPORTER_DID_RECEIVE_RIDE]: transporterDidReceiveRide,
  [Types.TRANSPORTER_DID_RIDE_LEFT]: transporterDidRideLeft,
  [Types.TRANSPORTER_RIDE_GOT_BY_OTHER]: transporterRideGotByOther,

  [Types.TRANSPORTER_REQUEST_DATETIME_VALIDATION_FOR_PROPOSED_RIDE_REQUEST]:
    transporterRequestDatetimeValidationForProposedRideRequest,
  [Types.TRANSPORTER_REQUEST_DATETIME_VALIDATION_FOR_PROPOSED_RIDE_REQUEST]:
    transporterRequestDatetimeValidationForProposedRideFailure,
  [Types.TRANSPORTER_REQUEST_DATETIME_VALIDATION_FOR_PROPOSED_RIDE_REQUEST]:
    transporterRequestDatetimeValidationForProposedRideSuccess,

  [Types.TRANSPORTER_REFUSE_INCOMING_RIDE_REQUEST]:
    transporterRefuseIncomingRideRequest,
  [Types.TRANSPORTER_REFUSE_INCOMING_RIDE_SUCCESS]:
    transporterRefuseIncomingRideSuccess,
  [Types.TRANSPORTER_REFUSE_INCOMING_RIDE_FAILURE]:
    transporterRefuseIncomingRideFailure,

  [Types.TRANSPORTER_CLEAR_ERROR]: transporterClearError,
});
