import { Subscription } from "@apollo/client/react/components";
import gql from "graphql-tag";
import { Text } from "components";

const TRANSPORTER_INCOMING_RIDE_SUBSCRIPTION = gql`
  subscription {
    TransporterIncomingRideSubscription {
      _id
      status
      vehicleCategory
      rideNumber
      vehicleOptions
      createdAt
      pickup {
        address
      }
      dropoff {
        address
      }
      datetime
      patient {
        firstname
        lastname
        phone
        email
        nss
        status
      }
      service {
        company {
          name
        }
      }
      note
      isRoundTrip
      files {
        path
        name
        extension
      }
    }
  }
`;

const IncomingRideNotification = ({
  transporterDidReceiveRide,
  showNotification,
}) => {
  function onSubscriptionData(ride) {
    if (ride !== undefined) {
      transporterDidReceiveRide(ride);
      showNotification({
        page: "/offer",
        message: "Vous avez une nouvelle demande de transport",
        type: "info",
        readed: false,
      });
    }
  }

  return (
    <Subscription
      subscription={TRANSPORTER_INCOMING_RIDE_SUBSCRIPTION}
      onSubscriptionData={({ subscriptionData }) => {
        onSubscriptionData(
          subscriptionData.data.TransporterIncomingRideSubscription
        );
      }}
    >
      {({ data = {}, loading, error }) => {
        // if (!error && !loading) {
        //   this.onSubscriptionData(data.TransporterIncomingRideSubscription);
        // }
        return null;
      }}
    </Subscription>
  );
};

export default IncomingRideNotification;
