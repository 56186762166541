import { put, takeLatest } from "redux-saga/effects";
import TransporterSerieHistoryActions, {
  TransporterSerieHistoryTypes,
} from "reducers/transporter/transporter-series-history";
import _ from "lodash";
import errorHandler from "sagas/error-handler";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* transporterSerieHistoryAsyncRequest({ page, limit, filter = false }) {
  let filterQuery = null;
  if (filter) {
    filterQuery = `
      {
        dates: [${filter.dates}],
        text: "${filter.text}"
      }
    `;
  }
  const query = `
    query {
      TransporterSeriesHistory(page:${page},limit:${limit}) {
        page
        limit
        totalPages
        series {
          _id
          status
          vehicleCategory
          vehicleOptions
          dates
          nbDates
          start
          end
          returnDatetime
          createdAt
          pickup { address }
          dropoff { address }
          patient {
            firstname
            lastname
            phone
            email
            nss
            status
          }
          service { company { name } }
          files { path name extension }
          note
        }
      }
    }
  `;

  try {
    const data = yield graphQLClient().request(query);
    const series = data.TransporterSeriesHistory;
    yield put(
      TransporterSerieHistoryActions.transporterSerieHistoryRequestSuccess(
        series
      )
    );
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      TransporterSerieHistoryActions.transporterSerieHistoryRequestFailure
    );
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(
    TransporterSerieHistoryTypes.TRANSPORTER_SERIE_HISTORY_REQUEST,
    transporterSerieHistoryAsyncRequest
  ),
];
