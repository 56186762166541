import React, { useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Box,
  Center,
  HStack,
  Select,
  Spinner,
  Stack,
  VStack,
} from "native-base";
import GooglePlacesAutocomplete, {
  getLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { Text, Title, FloatingInput, Button, Card } from "components";
import AccountAcions from "reducers/account";
import PublicAcions from "reducers/public";
import resaamIcon from "assets/img/resaam-logo.svg";

function InputComponent({
  label,
  value,
  onChange,
  parentKey,
  childKey,
  onKeyPress,
}) {
  const handleChange = (newValue) => {
    // Créez un nouvel objet pour mettre à jour le state parent
    const updatedState = {
      ...value, // Copiez toutes les valeurs existantes du state parent
      [parentKey]: {
        ...value[parentKey], // Copiez toutes les valeurs existantes de la clé parent
        [childKey]: newValue, // Mettez à jour la valeur de la clé actuelle (childKey)
      },
    };

    // Appelez la fonction de rappel avec le nouvel état mis à jour
    onChange(updatedState);
  };

  return (
    <VStack space={1} flex={1}>
      <Text fontSize="14px" fontWeight="500">
        {label}
      </Text>
      <FloatingInput
        inputType={childKey}
        label={label}
        onChange={(e) => handleChange(e.target.value)}
        onKeyPress={onKeyPress}
        value={value[parentKey][childKey]}
        _focus={{ borderColor: "#2684FF" }}
      />
    </VStack>
  );
}

const INITIAL_STATE = {
  profile: {
    lastname: "",
    firstname: "",
    email: "",
    password: "",
  },
  company: {
    name: "",
    siret: "",
    address: {
      address: "",
      latitude: null,
      longitude: null,
    },
    email: "",
    phones: "",
    partnerId: "none",
    // web: "",
    // billing_address: "",
    // contact: "",
    // billing: "",
    // rib: "",
  },
};

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("Public");
  const partners = useSelector((state) => state.public.partners);
  const action = useSelector((state) => state.account.action);
  const [form, setForm] = React.useState(INITIAL_STATE);
  const [addressText, setAddressText] = React.useState("");
  const [isInvalid, setIsInvalid] = React.useState(true);

  useEffect(() => {
    dispatch(AccountAcions.signoutRequest());
    // get list of partners
    dispatch(PublicAcions.getListOfPartnersRequest());
  }, []);

  useEffect(() => {
    const isInvalid =
      _.isEmpty(form.profile.lastname) ||
      _.isEmpty(form.profile.firstname) ||
      _.isEmpty(form.profile.email) ||
      _.isEmpty(form.profile.password) ||
      _.isEmpty(form.company.address.address) ||
      !_.isNumber(form.company.address.latitude) ||
      !_.isNumber(form.company.address.longitude) ||
      _.isEmpty(form.company.name) ||
      _.isEmpty(form.company.siret) ||
      _.isEmpty(form.company.email) ||
      _.isEmpty(form.company.phones);
    setIsInvalid(isInvalid);
    console.log(form.company.partnerId);
    return () => {
      // setForm(INITIAL_STATE);
      // setIsInvalid(true);
    };
  }, [form]);

  const onPropertyChanged = (properiety) => {
    setForm(Object.assign({}, form, { ...properiety }));
  };

  const onCompanyChanged = (properiety) => {
    setForm(
      Object.assign({}, form, { company: { ...form.company, ...properiety } })
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onPressRegisterButton();
    }
  };

  const onPressRegisterButton = () => {
    dispatch(
      AccountAcions.registerRequest(form.profile, form.company, () =>
        navigate("/register-success")
      )
    );
  };

  return (
    <Card
      zIndex="10"
      borderRadius="50px"
      borderWidth="0px"
      shadow="login"
      alignItems="center"
      px="4em"
      py="3em"
      justifyContent="space-between"
    >
      <img className="logo resaam" width="180px" src={resaamIcon} />

      <Title fontSize="24px">
        {t("RegisterPage.head_title", { lng: i18n.language })}
      </Title>

      <VStack h={8}>
        {action.loading && (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="primary"
            size="sm"
          />
        )}
        {action.error && (
          <Text color="danger">
            {t("RegisterPage.fail_register", { lng: i18n.language })}
          </Text>
        )}
      </VStack>

      <Center>
        <VStack w="full" space={2}>
          <Text fontSize="18px" fontWeight="700">
            {t(`RegisterPage.profile_title`, { lng: i18n.language })}
          </Text>

          <Stack space={2} direction={["column", "column", "row"]} w="full">
            <InputComponent
              parentKey={"profile"}
              childKey={"lastname"}
              value={form}
              label={t(`RegisterPage.lastname_label`, { lng: i18n.language })}
              onKeyPress={handleKeyPress}
              onChange={onPropertyChanged}
            />
            <InputComponent
              parentKey={"profile"}
              childKey={"firstname"}
              value={form}
              label={t(`RegisterPage.firstname_label`, { lng: i18n.language })}
              onKeyPress={handleKeyPress}
              onChange={onPropertyChanged}
            />
          </Stack>

          <Stack space={2} direction={["column", "column", "row"]} w="full">
            <InputComponent
              parentKey={"profile"}
              childKey={"email"}
              value={form}
              label={t(`RegisterPage.email_label`, { lng: i18n.language })}
              onKeyPress={handleKeyPress}
              onChange={onPropertyChanged}
            />
            <InputComponent
              parentKey={"profile"}
              childKey={"password"}
              value={form}
              label={t(`RegisterPage.password_label`, { lng: i18n.language })}
              onKeyPress={handleKeyPress}
              onChange={onPropertyChanged}
            />
          </Stack>
        </VStack>

        <VStack w="full" space={2} pt={8}>
          <Text fontSize="18px" fontWeight="700">
            {t(`RegisterPage.company_title`, { lng: i18n.language })}
          </Text>

          <Stack space={2} direction={["column", "column", "row"]} w="full">
            <InputComponent
              parentKey={"company"}
              childKey={"name"}
              value={form}
              label={t(`RegisterPage.name_label`, { lng: i18n.language })}
              onKeyPress={handleKeyPress}
              onChange={onPropertyChanged}
            />
            <InputComponent
              parentKey={"company"}
              childKey={"siret"}
              value={form}
              label={t(`RegisterPage.siret_label`, { lng: i18n.language })}
              onKeyPress={handleKeyPress}
              onChange={onPropertyChanged}
            />
          </Stack>

          <VStack space={1} flex={1}>
            <Text fontSize="14px" fontWeight="500">
              {t(`RegisterPage.address_label`, { lng: i18n.language })}
            </Text>
            <Box position="relative">
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GMAPS_KEY}
                selectProps={{
                  inputValue: addressText,
                  onInputChange: (text) => setAddressText(text),
                  onChange: ({ label, value }) => {
                    const { place_id, description, ...rest } = value;
                    geocodeByPlaceId(place_id)
                      .then((results) => getLatLng(results[0]))
                      .then(({ lat, lng }) => {
                        onCompanyChanged({
                          address: {
                            address: label,
                            latitude: lat,
                            longitude: lng,
                          },
                        });
                      });
                  },
                  placeholder: "Adresse",
                  blurInputOnSelect: true,
                  noOptionsMessage: () => null,
                  components: {
                    // DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  },
                  styles: {
                    menu: (provided, state) => ({
                      ...provided,
                      // zIndex: 9999999,
                      position: "block",
                      backgroundColor: "white",
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      borderWidth: "2px",
                      borderColor: "#999C9F",
                      fontFamily: "Lato",
                      fontSize: "14px",
                    }),
                  },
                }}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ["fr"],
                  },
                }}
              />
            </Box>
          </VStack>

          <Stack space={2} direction={["column", "column", "row"]} w="full">
            <InputComponent
              parentKey={"company"}
              childKey={"email"}
              value={form}
              label={t(`RegisterPage.email_label`, { lng: i18n.language })}
              onKeyPress={handleKeyPress}
              onChange={onPropertyChanged}
            />
            <InputComponent
              parentKey={"company"}
              childKey={"phones"}
              value={form}
              label={t(`RegisterPage.phones_label`, { lng: i18n.language })}
              onKeyPress={handleKeyPress}
              onChange={onPropertyChanged}
            />

            {/* <InputComponent
              parentKey={"company"}
              childKey={"web"}
              value={form}
              label={t(`RegisterPage.web_label`, { lng: i18n.language })}
              onKeyPress={handleKeyPress}
              onChange={onPropertyChanged}
            /> */}
          </Stack>

          <Stack space={2} direction={["column", "column", "row"]} w="full">
            <VStack space={1} flex={1}>
              <Text fontSize="14px" fontWeight="500">
                {t(`RegisterPage.partner_label`, { lng: i18n.language })}
              </Text>
              <Select
                selectedValue={form.company.partnerId}
                minWidth="200"
                accessibilityLabel={t(`RegisterPage.partner_label`, {
                  lng: i18n.language,
                })}
                placeholder={t(`RegisterPage.partner_select_placeholder`, {
                  lng: i18n.language,
                })}
                onValueChange={(itemValue) => {
                  onCompanyChanged({ partnerId: itemValue });
                }}
                borderColor="gray"
                borderWidth="2px"
                _focus={{ borderColor: "#2684FF" }}
              >
                {/* First item is none */}
                <Select.Item key="none" label="Aucun" value="none" />

                {/* List of partners */}
                {partners.map((item) => (
                  <Select.Item
                    key={item._id}
                    label={item.name}
                    value={item._id}
                  />
                ))}
              </Select>
            </VStack>
          </Stack>
        </VStack>

        {/* BUTTONS */}
        <HStack justifyContent="space-between" w="full" mt={8} mb={12}>
          <NavLink to="/login">
            {t("RegisterPage.login_link", { lng: i18n.language })}
          </NavLink>

          <Button
            onPress={onPressRegisterButton}
            third
            borderColor="primary"
            isDisabled={isInvalid}
            _text={{
              color: "primary",
              fontFamily: "Lato",
              fontSize: "14px",
              fontWeight: "700",
            }}
            px="11px"
            h="32px"
            alignItems="center"
          >
            {t("RegisterPage.register_button", { lng: i18n.language })}
          </Button>
        </HStack>
      </Center>
    </Card>
  );
}

export default Register;
