import { put, takeLatest } from "redux-saga/effects";
import DriverLocationActions, {
  DriverLocationTypes,
} from "reducers/ride/driver-location";
import _ from "lodash";
import errorHandler from "sagas/error-handler";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* transporterGetRideDriverLocationAsyncRequest({ driverId }) {
  const query = `
    query{
      TransporterGetDriversCurrentLocation(
        driversId: ["${driverId}"]
      ) {
        driverId
        longitude 
        latitude
      }
    }
  `;
  try {
    const { TransporterGetDriversCurrentLocation } =
      yield graphQLClient().request(query);

    yield put(
      DriverLocationActions.transporterGetRideDriverLocationRequestSuccess(
        TransporterGetDriversCurrentLocation[0]
      )
    );
  } catch (error) {
    /*const { message = "" } = error.response.errors[0]
    yield errorHandler(message,DriverLocationActions.transporterGetRideDriverLocationRequestFailure)
    if (_.isFunction(callback)) callback()
    */
  }
}

function* transporterGetRidesWaitingTimeAsyncRequest({ ridesId }) {
  let _ridesId = _.reduce(ridesId, (acc, e) => [...acc, `"${e}"`], []);

  const query = `
    query {
      TransporterGetRideWaitingTime(ridesId:[${_ridesId.join(",")}]) {
        rideId,
        duration,
        distance
      }
    }
  `;

  try {
    const { TransporterGetRideWaitingTime } = yield graphQLClient().request(
      query
    );

    yield put(
      DriverLocationActions.transporterGetRidesWaitingTimeRequestSuccess(
        TransporterGetRideWaitingTime
      )
    );
  } catch (error) {
    /*const { message = "" } = error.response.errors[0]
    yield errorHandler(message,DriverLocationActions.transporterGetRidesWaitingTimeRequestFailure)
    if (_.isFunction(callback)) callback()
    */
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(
    DriverLocationTypes.TRANSPORTER_GET_RIDE_DRIVER_LOCATION_REQUEST,
    transporterGetRideDriverLocationAsyncRequest
  ),
  takeLatest(
    DriverLocationTypes.TRANSPORTER_GET_RIDES_WAITING_TIME_REQUEST,
    transporterGetRidesWaitingTimeAsyncRequest
  ),
];
