import { Component } from "react";
import { Box, Center, HStack, Pressable, Stack, VStack } from "native-base";
import DatePicker, { Calendar, DateObject } from "react-multi-date-picker";
import colorsCalendar from "react-multi-date-picker/plugins/colors";
import Icon from "react-multi-date-picker/components/icon";
import moment from "moment";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";

import datepickerLocal from "global/local-dates";
import "assets/style.css";
import {
  CountdownCircle,
  VehicleOptions,
  RideDestination,
  Button,
  Text,
  Card,
  Tooltip,
  BadgeCard,
  NotSpecified,
  Tag,
} from "components";
import { formatPartenaryTime } from "./InComingRidesCard";
import serieARIcon from "assets/img/serie-ar.svg";
import serieReturnIcon from "assets/img/return-ride.svg";
import clockIcon from "assets/img/clock.svg";

const getStartOrEndFormated = (date) => {
  const day = moment(new Date());
  day.set({
    hour: date / 60,
    minute: date % 60,
  });
  return day.format("HH[h]mm");
};

class InComingSerieCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      partenaryTimeLabel: "start",
      showMore: false,
      // partenaryTime: null,
    };

    this.onAccept = this.onAccept.bind(this);
    this.onRefuse = this.onRefuse.bind(this);
    this.setStartTime = this.setStartTime.bind(this);
    this.handleShowMore = this.handleShowMore.bind(this);
    // this.calendarInitialProps = this.calendarInitialProps.bind(this);
  }

  componentDidMount() {
    // this.timer = null;
    // const { dispatchingCycle } = this.props.serie;
    // if(dispatchingCycle !== "first") {
    //   const partenaryTime = moment(this.props.partenaryTime * 1000).diff(moment(new Date()), "seconds");
    //   this.setState({ partenaryTime });
    //   this.timer = setInterval(() => {
    //     console.log(partenaryTime)
    //     this.setState({ partenaryTime: this.state.partenaryTime - 1 });
    //     if(this.state.partenaryTime <= 0) clearInterval(this.timer);
    //   }, 1000);
    // }
  }

  componentWillUnmount() {
    // clearInterval(this.timer);
  }

  onAccept() {
    const { onPressAcceptSerieButton, serie } = this.props;
    onPressAcceptSerieButton({
      ...serie,
      startTime: this.state.startTime,
    });
  }

  onRefuse() {
    const { onPressRefuseSerieButton, serie } = this.props;
    onPressRefuseSerieButton(serie);
  }

  setStartTime(time) {
    this.setState({
      startTime: time,
    });
  }

  handleShowMore() {
    this.setState({
      showMore: !this.state.showMore,
    });
  }

  calendarInitialProps() {
    const { returnDatetime, dates } = this.props.serie;
    const toDateObject = (day) => new DateObject(day);
    const colors = {
      red: !returnDatetime ? [] : [toDateObject(returnDatetime)],
      blue: dates.map(toDateObject),
    };

    Object.keys(colors).forEach((color) => {
      colors[color].forEach((date, index) => {
        colors[color][index].color = color;
      });
    });

    const initial = {
      multiple: true,
      value: [...colors.blue, ...colors.red],
      weekStartDayIndex: 1,
      // disableDayPicker: false,
      readOnly: true,
      showOtherDays: false,
      locale: datepickerLocal,
      shadow: false,
      minDate: returnDatetime ? returnDatetime : new DateObject(),
      plugins: [colorsCalendar({ colors: ["blue", "red"], disabled: true })],
    };
    return initial;
  }

  render() {
    const { t, i18n, serie, downloadFiles } = this.props;
    const { partenaryTime = null } = this.state;
    const {
      start,
      end,
      dates,
      nbDates,
      returnDatetime,
      pickup,
      dropoff,
      vehicleCategory,
      vehicleOptions,
      status,
      patient,
      service,
      createdAt,
      note,
      files = [],
    } = serie;
    const hasReturnDatetime = returnDatetime ? true : false;
    const { showMore } = this.state;
    // const numberOfRides = (dates.length <= 1 ? nbDates * 2 : dates.length) + (hasReturnDatetime ? 1 : 0);
    // const startFormated = dates.length <= 1 && !hasReturnDatetime ? dates[0] : getStartOrEndFormated(start);
    // const endFormated =  dates.length <= 1 && !hasReturnDatetime ? dates[0] : getStartOrEndFormated(end);
    const isDemand = status === "incoming";

    function ListOfTags() {
      const direction = isDemand ? "row" : "column";
      return (
        <VStack space={2}>
          <Stack space={isDemand ? 3 : 2} direction={direction}>
            <Tag
              text={`${nbDates * 2} transports`}
              color={"white"}
              bg={"secondary"}
              py="2px"
            />
            <Tag
              text={_.capitalize(vehicleCategory)}
              color={"white"}
              bg={`vehicleCategory.${vehicleCategory}`}
              py="2px"
            />
            {hasReturnDatetime && (
              <Tag
                text={isDemand ? "Retour immédiat" : "+1 retour"}
                color={"white"}
                bg={isDemand ? "info" : "success"}
                py="2px"
              />
            )}
          </Stack>
          {isDemand && (
            <Box alignItems="start">
              <Tag text={"Série"} color={"white"} bg={"primary"} py="2px" />
            </Box>
          )}
        </VStack>
      );
    }

    return (
      <Pressable
        // onPress={this.handleShowMore}
        cursor="auto"
        mb={this.props.mb || 0}
        position="relative"
        borderWidth={1}
        boxSize="border-box"
        rounded="4px"
        borderColor={"gray"}
        _hover={{ borderColor: "rgba(2, 150, 174, 0.6)" }}
        _pressed={{ borderColor: "#0296AE" }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        maxWidth="986px"
        py="2"
        px="27px"
        bg="ligth"
        space={4}
      >
        <HStack w="full">
          <VStack space={4} w="295px" pr={2}>
            <HStack space="40px">
              <VStack justifyContent="space-between" /*  space={5} */ h="110px">
                <VStack>
                  <Text fontSize="12px" fontWeight="500" color="gray">
                    {isDemand ? "Demande fait le" : "Accepté le"}
                  </Text>
                  <Text fontSize="12px" fontWeight={500}>
                    {moment(createdAt).format("DD MMM HH:mm")}
                  </Text>
                </VStack>
                <VStack>
                  <Text fontSize="12px" fontWeight="500" color="gray">
                    Premier transport
                  </Text>
                  <Text fontSize="12px" fontWeight={500}>
                    {moment(
                      hasReturnDatetime ? returnDatetime : dates[0]
                    ).format("DD MMM HH:mm")}
                  </Text>
                </VStack>
                {isDemand && (
                  <HStack justifyContent="space-between" w="full">
                    <img src={clockIcon} style={{ width: 25 }} alt="" />
                    <Text fontSize="16px" fontWeight={900}>
                      {formatPartenaryTime(partenaryTime)}
                    </Text>
                  </HStack>
                )}
              </VStack>

              <VStack space={2} flex={1}>
                <VStack>
                  <Text fontSize="12px" fontWeight="500" color="gray">
                    Prise en charge
                  </Text>
                  <Text fontSize="12px" fontWeight="500" numberOfLines={2}>
                    {pickup.address}
                  </Text>
                </VStack>
                <VStack>
                  <Text fontSize="12px" fontWeight="500" color="gray">
                    Dépose
                  </Text>
                  <Text fontSize="12px" fontWeight="500" numberOfLines={2}>
                    {dropoff.address}
                  </Text>
                </VStack>
              </VStack>
            </HStack>
          </VStack>

          <VStack borderColor="gray"></VStack>
          <HStack flex={1} space={isDemand ? "44px" : 0}>
            <HStack>
              {!isDemand && (
                <VStack
                  space={1}
                  px={4}
                  borderLeftWidth={1}
                  borderColor="gray"
                  w="140.5px"
                >
                  <Text fontSize="16px" fontWeight={900} isTruncated>
                    {`${_.capitalize(patient?.firstname) || ""} ${_.capitalize(
                      patient?.lastname
                    )}`}
                  </Text>
                  <VStack>
                    <Text fontSize="12px" fontWeight={500} color="gray">
                      Age
                    </Text>
                    {patient?.age ? (
                      <Text
                        fontSize="12px"
                        fontWeight={900}
                      >{`${patient.age} ans`}</Text>
                    ) : (
                      <NotSpecified />
                    )}
                  </VStack>
                  <VStack>
                    <Text fontSize="12px" fontWeight={500} color="gray">
                      N° téléphone
                    </Text>
                    {patient?.phone ? (
                      <Text fontSize="12px" fontWeight={500}>
                        {patient.phone}
                      </Text>
                    ) : (
                      <NotSpecified />
                    )}
                  </VStack>

                  {showMore && (
                    <VStack space={1}>
                      <VStack>
                        <Text fontSize="12px" fontWeight={500} color="gray">
                          N° sécurité sociale
                        </Text>
                        <NotSpecified />
                      </VStack>
                    </VStack>
                  )}
                </VStack>
              )}

              <VStack
                space={2}
                w={isDemand ? "149px" : "132px"}
                borderLeftWidth={isDemand ? 1 : 0}
                borderLeftColor="gray"
                pl={isDemand ? 4 : 0}
              >
                <VStack space={1}>
                  <Text fontSize="12px" fontWeight="500" color="gray">
                    Notes
                  </Text>
                  {_.isEmpty(note) && <NotSpecified />}
                  <Text
                    fontSize="12px"
                    fontWeight="500"
                    numberOfLines={showMore ? 100 : isDemand ? 4 : 2}
                  >
                    {note}
                  </Text>
                </VStack>
                {files.length > 0 && (
                  <VStack space={1} alignItems="start">
                    <Text
                      fontSize="12px"
                      fontWeight="500"
                      mb="2px"
                      color="gray"
                    >
                      {files.length}{" "}
                      {files.length == 1
                        ? "fichier disponible"
                        : `fichiers disponibles`}
                    </Text>
                    <Button
                      secondary
                      rounded="5px"
                      onPress={() => downloadFiles(files, patient.lastname)}
                    >
                      Télécharger
                    </Button>
                  </VStack>
                )}
              </VStack>
            </HStack>

            <VStack space={2} h="full">
              <VStack space={2} alignItems="flex-start">
                <Text fontSize="12px" fontWeight="500" color="gray">
                  Tags
                </Text>
                <ListOfTags />
              </VStack>
            </VStack>

            <Box flex={1}>
              {isDemand ? (
                <VStack justifyContent="center" alignItems="flex-end" flex={1}>
                  <VStack space="14px">
                    <Button onPress={this.onAccept} rounded="5px">
                      {t(`button.accept`, { lng: i18n.language }).toUpperCase()}
                    </Button>
                    <Button secondary onPress={this.onRefuse} rounded="5px">
                      {t(`button.decline`, {
                        lng: i18n.language,
                      }).toUpperCase()}
                    </Button>
                  </VStack>
                </VStack>
              ) : (
                <Pressable
                  onPress={this.handleShowMore}
                  p={8}
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text fontSize="14px" fontWeight={500} mb={2}>
                    Voir {showMore ? "moins" : "plus"}
                  </Text>
                  <FontAwesomeIcon
                    icon={showMore ? faArrowUp : faArrowDown}
                    size="lg"
                  />
                </Pressable>
                // <>
                //   {!isDemand && (
                //     <DatePicker
                //       // {...this.calendarInitialProps()}
                //       render={<Icon />}
                //       zIndex={9000}
                //       // multiple
                //       // value
                //       // weekStartDayIndex
                //       // readOnly
                //       // showOtherDays
                //       // locale
                //       // shadow
                //       // minDate
                //       // plugins
                //       className="container-datepicker red"
                //     />
                //   )}
                // </>
              )}
            </Box>
          </HStack>
        </HStack>
        {showMore && (
          <HStack space={4} flex={1} w="full" justifyContent="end">
            {/* <VStack space={4}>
              <Stack alignItems="center">
                {start ? (
                  <VStack space={8}>
                    <VStack>
                      <Text fontWeight={700} fontSize="16px">
                        {"Heure de début de rendez-vous"}
                      </Text>
                      <Text fontWeight={900} fontSize="16px">
                        {startFormated}
                      </Text>
                    </VStack>

                    <VStack>
                      <Text fontWeight={700} fontSize="16px">
                        {"Heure de fin de rendez-vous"}
                      </Text>
                      <Text fontWeight={900} fontSize="16px">
                        {endFormated}
                      </Text>
                    </VStack>
                  </VStack>
                ) : (
                 <Box alignItems={"start"}>
                   <Text textAlign="right" fontWeight={700} fontSize="14px" numberOfLines={2}>
                      La série n'a pas été renseigné, rapprochez-vous du patient
                    </Text>
                 </Box>
                )}
              </Stack>
            </VStack> */}
            {!isDemand && (
              <VStack>
                <Calendar
                  {...this.calendarInitialProps()}
                  className="container-datepicker red"
                />
              </VStack>
            )}
          </HStack>
        )}
      </Pressable>
    );
    // return (
    //   <Pressable
    //     onPress={this.handleShowMore}
    //     mb={this.props.mb || 0}
    //     position="relative"
    //   >
    //     <BadgeCard
    //       tooltip={t(
    //         "ReservationsInDemandesPage.number_of_ride_in_the_serie_tooltip",
    //         { lng: i18n.language, number: numberOfRides }
    //       )}
    //     >
    //       <Text color="ligth">
    //         {t("ReservationsInDemandesPage.number_of_ride_in_the_serie_label", {
    //           lng: i18n.language,
    //           number: numberOfRides,
    //         })}
    //       </Text>
    //     </BadgeCard>

    //     <Stack pt="3" pr={status == "incoming" ? 3 : 0}>
    //       <Card
    //         display="flex"
    //         flexDirection="row"
    //         borderColor={status == "incoming" ? `status.${status}`: "ligthGray"}
    //         minWidth="767px"
    //         minHeight="167px"
    //         alignItems="center"
    //         pt={8}
    //         pb={showMore ? 2 : 8}
    //       >
    //         <VStack space="2" w="full">
    //           <HStack
    //             space="2"
    //             justifyContent="space-between"
    //             w="full"
    //             alignItems="center"
    //             pb={showMore ? 4 : 0}
    //           >
    //             {partenaryTime && (
    //               <Box alignItems="center" flex={1}>
    //                 <Tooltip
    //                   placement="right"
    //                   label={t(
    //                     `ReservationsInDemandesPage.countdown_circle_tooltip_${this.state.partenaryTimeLabel}`,
    //                     { lng: i18n.language }
    //                   )}
    //                 >
    //                   <Box w="100">
    //                     <CountdownCircle
    //                       duration={partenaryTime}
    //                       onComplete={() =>
    //                         this.setState({ partenaryTimeLabel: "end" })
    //                       }
    //                     />
    //                   </Box>
    //                 </Tooltip>
    //               </Box>
    //             )}

    //             <HStack flex={3} alignItems="center" justifyContent="center">
    //               <Box flex={1} alignItems="end">
    //                 <img
    //                   src={hasReturnDatetime ? serieReturnIcon : serieARIcon}
    //                   width={48}
    //                 />
    //               </Box>
    //               <Box flex={3}>
    //                 <RideDestination
    //                   pickup={
    //                     hasReturnDatetime ? dropoff.address : pickup.address
    //                   }
    //                   dropoff={
    //                     hasReturnDatetime ? pickup.address : dropoff.address
    //                   }
    //                 />
    //               </Box>
    //             </HStack>

    //             {hasReturnDatetime ? (
    //               <Box justifyContent="center" alignItems="center" flex={1}>
    //                 <Text
    //                   fontWeight="900"
    //                   fontSize="18px"
    //                   color={showMore ? "danger" : "primary"}
    //                 >
    //                   {moment(returnDatetime).format("DD MMM YYYY")}
    //                 </Text>
    //                 <Text
    //                   fontWeight="900"
    //                   fontSize="18px"
    //                   color={showMore ? "danger" : "primary"}
    //                 >
    //                   {moment(returnDatetime).format("HH[h]mm")}
    //                 </Text>
    //               </Box>
    //             ) : (
    //               <Box justifyContent="center" alignItems="center" flex={1}>
    //                 <Text fontWeight="900" fontSize="18px">
    //                   {moment(startFormated).isValid() ? moment(startFormated).format("DD MMM YYYY") : moment(dates[0]).format("DD MMM YYYY")}{}
    //                 </Text>
    //                 <Text fontWeight="900" fontSize="18px">
    //                   {moment(startFormated).isValid() ? moment(startFormated).format("HH[h]mm") : moment(dates[0]).format("HH[h]mm")}{}
    //                 </Text>
    //               </Box>
    //             )}

    //             <Box justifyContent="center" alignItems="center" flex={1}>
    //               <Text
    //                 color="primary"
    //                 fontFamily="Lato"
    //                 fontSize="14px"
    //                 fontWeight="400"
    //               >
    //                 {patient.firstname} {patient.lastname}
    //               </Text>
    //               <Text
    //                 fontSize="14px"
    //                 fontStyle="italic"
    //                 color="gray"
    //                 lineHeight="17px"
    //               >
    //                 {patient.nss}
    //               </Text>
    //             </Box>

    //             <Box alignItems="center" flex={1}>
    //               <Text fontWeight="900" fontSize="18px" isTruncated="true">
    //                 {_.capitalize(vehicleCategory)}
    //               </Text>
    //             </Box>

    //             <VStack space="4" flex={1}>
    //               <VehicleOptions options={vehicleOptions} />
    //             </VStack>

    //             {status == "incoming" && (
    //               <VStack justifyContent="center" h="full" space="14px" flex={1}>
    //                 <Button onPress={this.onAccept}>
    //                   {t(`button.accept`, { lng: i18n.language }).toUpperCase()}
    //                 </Button>
    //                 <Button secondary onPress={this.onRefuse}>
    //                   {t(`button.decline`, { lng: i18n.language }).toUpperCase()}
    //                 </Button>
    //               </VStack>
    //             )}
    //           </HStack>

    //           {showMore && (
    //             <HStack
    //               space="2"
    //               justifyContent="space-between"
    //               alignItems="center"
    //               w="full"
    //               minHeight="132px"
    //               pt={2}
    //               borderTopWidth={2}
    //               boxSize="border-box"
    //               borderColor={"ligthGray"}
    //             >
    //               <HStack flex={1} alignItems="center">
    //                 <Box flex={1} alignItems="end">
    //                   <img src={serieARIcon} width={48} />
    //                 </Box>
    //                 <Box flex={3}>
    //                   <RideDestination
    //                     pickup={pickup.address}
    //                     dropoff={dropoff.address}
    //                   />
    //                 </Box>
    //               </HStack>

    //               <Box flex={1} alignItems="center">
    //                 <Calendar
    //                   {...this.calendarInitialProps()}
    //                   className="container-datepicker red"
    //                 />
    //               </Box>

    //               <Stack flex={1} alignItems="center">
    //                 {start ? (
    //                   <VStack space={8}>
    //                     <VStack>
    //                       <Text fontWeight={700} fontSize="16px">
    //                         {"Heure de début de rendez-vous"}
    //                       </Text>
    //                       <Text fontWeight={900} fontSize="16px">
    //                         {startFormated}
    //                       </Text>
    //                     </VStack>

    //                     <VStack>
    //                       <Text fontWeight={700} fontSize="16px">
    //                         {"Heure de fin de rendez-vous"}
    //                       </Text>
    //                       <Text fontWeight={900} fontSize="16px">
    //                         {endFormated}
    //                       </Text>
    //                     </VStack>
    //                   </VStack>
    //                 ) : (
    //                   <Center>
    //                     <Text fontWeight={700} fontSize="16px">
    //                       La série n'a pas été renseigné, rapprochez-vous du patient
    //                     </Text>
    //                   </Center>
    //                 )}
    //               </Stack>
    //             </HStack>
    //           )}
    //         </VStack>
    //       </Card>
    //     </Stack>
    //   </Pressable>
    // );
  }
}

export default InComingSerieCard;
