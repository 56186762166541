import { put, takeLatest } from "redux-saga/effects";
import TransporterIncomingSeriesActions, {
  TransporterIncomingSerieTypes,
} from "reducers/serie/transporter-incoming-series";
// import OnGoingSerieActions from "reducers/transporter/transporter-ongoing-series";
import OnGoingRideActions from "reducers/transporter/transporter-ongoing-rides";
import _ from "lodash";
import errorHandler from "sagas/error-handler";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* incomingSeriesAsyncRequest({ page, limit }) {
  const query = `
    query {
      TransporterInComingSeries(page:${page},limit:${limit}){ 
        page
        limit
        totalPages 
        totalDocs
        series {
          _id
          status
          vehicleCategory
          vehicleOptions
          dates
          nbDates
          start
          end
          returnDatetime
          createdAt
          dispatchingCycle
          pickup { address }
          dropoff { address }
          patient {
            firstname
            lastname
            phone
            email
            nss
            status
          }
          service { company { name } }
          note
        }
      }
    }
  `;
  try {
    const data = yield graphQLClient().request(query);
    const series = data.TransporterInComingSeries;
    yield put(
      TransporterIncomingSeriesActions.transporterIncomingSeriesRequestSuccess(
        series
      )
    );
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      TransporterIncomingSeriesActions.transporterIncomingSeriesRequestFailure
    );
  }
}

function* transporterAcceptProposedSerieAsyncRequest({
  serie,
  vehicle,
  callback,
}) {
  let vehicleQuery = null;

  if (vehicle !== false) {
    vehicleQuery = `
    {
      id: "${vehicle._id}",
      plate: "${vehicle.plate}",
      make: "${vehicle.make}",
      model: "${vehicle.model}",
      category: ${vehicle.category}
    }
  `;
  }

  const query = `
    mutation {
      TransporterAcceptProposedSerie(
        serieId: "${serie}",
        vehicle: ${vehicleQuery},
      )
      {
        _id
      }
    }
  `;

  try {
    const data = yield graphQLClient().request(query);
    const serie = data.TransporterAcceptProposedSerie;

    yield put(
      TransporterIncomingSeriesActions.transporterAcceptProposedSerieRequestSuccess(
        serie
      )
    );

    // for history serie
    // yield put(OnGoingSerieActions.onGoingSeriesNewSerieRequest(serie));

    if (_.isFunction(callback)) callback({});
  } catch (error) {
    const { message = "" } = error.response.errors[0];

    if (message === "SerieNotAvailable") {
      yield put(
        TransporterIncomingSeriesActions.transporterProposedSerieRequestNotAvailable(
          serie
        )
      );
      if (_.isFunction(callback)) callback({ error: message });
    } else {
      yield put(
        TransporterIncomingSeriesActions.transporterAcceptProposedSerieRequestFailure(
          message
        )
      );
    }
  }
}

function* transporterRefuseIncomingSerieAsyncRequest({ serie, callback }) {
  const query = `
    mutation {
      TransporterRefuseIncomingSerie(serieId: "${serie._id}") {
        _id
      }
    }
  `;
  try {
    const data = yield graphQLClient().request(query);
    const serie = data.TransporterRefuseIncomingSerie;

    yield put(
      TransporterIncomingSeriesActions.transporterRefuseIncomingSerieSuccess(
        serie
      )
    );

    if (_.isFunction(callback)) callback({});
  } catch (error) {
    const { message = "" } = error.response.errors[0];

    yield put(
      TransporterIncomingSeriesActions.transporterRefuseIncomingSerieFailure(
        message
      )
    );
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(
    TransporterIncomingSerieTypes.TRANSPORTER_INCOMING_SERIES_REQUEST,
    incomingSeriesAsyncRequest
  ),
  takeLatest(
    TransporterIncomingSerieTypes.TRANSPORTER_ACCEPT_PROPOSED_SERIE_REQUEST,
    transporterAcceptProposedSerieAsyncRequest
  ),
  takeLatest(
    TransporterIncomingSerieTypes.TRANSPORTER_REFUSE_INCOMING_SERIE_REQUEST,
    transporterRefuseIncomingSerieAsyncRequest
  ),
];
