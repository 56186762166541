import "assets/style.css";
import { Component } from "react";
import {
  Alert,
  Center,
  HStack,
  Pressable,
  Stack,
  View,
  VStack,
} from "native-base";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import Calendar from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import datepickerLocal from "global/local-dates";
import historyRidesActions from "reducers/transporter/transporter-rides-history";
import historySeriesActions from "reducers/transporter/transporter-series-history";
import { createInitialValueFromArray } from "functions";
import {
  HISTORY_RIDE_STATUS,
  TRANSPORT_TYPE,
  VEHICLE_CATEGORIES,
  RIDE_DATE,
} from "global";
import {
  Title,
  Text,
  FloatingInput,
  NotificationMenu,
  HistoryRidesCard,
  FilterModal,
  InComingSerieCard,
} from "components";
import Rides from "./Rides";
import { Button } from "components";
import Series from "./Series";

class HistoryRides extends Component {
  constructor(props) {
    super(props);

    this.onSearchHistoryRidesFilter =
      this.onSearchHistoryRidesFilter.bind(this);
    this.fetchRideData = this.fetchRideData.bind(this);
    this.fetchSerieData = this.fetchSerieData.bind(this);
    this.onToggleFilter = this.onToggleFilter.bind(this);
    this.onSaveFilter = this.onSaveFilter.bind(this);
    this.onSwitchScreen = this.onSwitchScreen.bind(this);

    this.state = {
      switchScreen: "rides",
      searchInputValue: "",
      filterDates: [],
      showFilter: false,
      filter: {
        vehicleCategories: createInitialValueFromArray(VEHICLE_CATEGORIES),
        rideType: createInitialValueFromArray(TRANSPORT_TYPE),
        rideStatus: createInitialValueFromArray(HISTORY_RIDE_STATUS),
        // patientStatus: createInitialValueFromArray(PATIENT_STATUS),
        rideDate: RIDE_DATE[0],
        vehicle: "ALL",
        driver: "ALL",
      },
    };
  }

  componentDidMount() {
    const { transporterRideHistoryRequest, transporterSerieHistoryRequest } =
      this.props;
    const { searchInputValue, filterDates, filter } = this.state;

    transporterRideHistoryRequest(1, 10, {
      text: searchInputValue,
      dates: filterDates.length !== 0 ? `"${filterDates.join('","')}"` : "",
      advanced: filter,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterDates, searchInputValue, filter, switchScreen } = this.state;
    if (
      prevState.filterDates !== filterDates ||
      prevState.searchInputValue !== searchInputValue ||
      prevState.filter !== filter ||
      prevState.switchScreen !== switchScreen
    ) {
      if (switchScreen === "rides") {
        this.props.transporterRideHistoryRequest(1, 10, {
          text: searchInputValue,
          dates: filterDates.length !== 0 ? `"${filterDates.join('","')}"` : "",
          advanced: filter,
        });
      }
      if (switchScreen === "series") {
        this.props.transporterSerieHistoryRequest(1, 10);
      }
    }
  }

  onSwitchScreen(screen = "rides") {
    this.setState(Object.assign({}, this.state, { switchScreen: screen }));
  }

  onToggleFilter() {
    this.setState(
      Object.assign({}, this.state, { showFilter: !this.state.showFilter })
    );
  }

  onSaveFilter(obj) {
    this.setState({ filter: obj });
  }

  onSearchHistoryRidesFilter(e) {
    const { value } = e.target;
    this.setState({ searchInputValue: value });
  }

  fetchRideData() {
    const { transporterRideHistoryRequest, ride } = this.props;
    const { page, limit, totalPages } = ride.rides;
    if (page < totalPages)
      transporterRideHistoryRequest(page + 1, limit, {
        text: this.state.searchInputValue,
        dates: this.state.filterDates,
        advanced: this.state.filter,
      });
  }

  fetchSerieData() {
    const { transporterSerieHistoryRequest, serie } = this.props;
    const { page, limit, totalPages } = serie.series;
    if (page < totalPages) transporterSerieHistoryRequest(page + 1, limit);
    // transporterSerieHistoryRequest(page + 1, limit, {
    //     text: this.state.searchInputValue,
    //     dates: this.state.filterDates,
    //     advanced: this.state.filter,
    //   });
  }

  render() {
    const { t, i18n, action, downloadFiles } = this.props;
    const { error } = action;

    return (
      <View w="full">
        <VStack space="5" ml="24" mr="12" h="full">
          <VStack space={5} h="48" mt="5" zIndex={1}>
            {error !== "" && (
              <Alert bsStyle="danger">
                <span>{error}</span>
              </Alert>
            )}

            <Title>
              {t("RidesHistoryPage.ride_history_title_headline", {
                lng: i18n.language,
              })}
            </Title>

            {/* Notification */}
            <Stack alignItems="end">
              <NotificationMenu
                items={this.props.notifications}
                onReadedNotifications={this.props.onReadedNotifications}
              />
            </Stack>
            {/* Search */}

            <HStack justifyContent="space-between">
              <HStack alignItems="center" space={8}>
                <FloatingInput
                  inputType="search"
                  label={t("searchBar", { lng: i18n.language })}
                  onChange={this.onSearchHistoryRidesFilter}
                  value={this.state.searchInputValue}
                />

                <Pressable onPress={this.onToggleFilter}>
                  <HStack space={2}>
                    <FontAwesomeIcon
                      icon={faFilter}
                      size="lg"
                      color="#032A47"
                    />
                    <Text fontSize="16px" fontWeight={700} color="#032A47">
                      Filtre
                    </Text>
                  </HStack>
                </Pressable>
                <HStack space={2}>
                  <Button
                    onPress={() => this.onSwitchScreen("rides")}
                    third={this.state.switchScreen === "series"}
                  >
                    Transport
                  </Button>
                  <Button
                    px={6}
                    onPress={() => this.onSwitchScreen("series")}
                    third={this.state.switchScreen === "rides"}
                  >
                    Série
                  </Button>
                </HStack>
              </HStack>

              <Center position="relative">
                <Calendar
                  multiple={true}
                  value={this.state.filterDates}
                  weekStartDayIndex={1}
                  disableDayPicker={false}
                  showOtherDays={false}
                  calendarPosition="Right Top"
                  className="container-input-calendar"
                  render={<InputIcon />}
                  locale={datepickerLocal}
                  shadow={false}
                  onChange={(selectedDates) => {
                    this.setState({ filterDates: selectedDates });
                  }}
                />
              </Center>
            </HStack>
          </VStack>
          {this.state.switchScreen === "rides" ? (
            <Rides
              fetchData={this.fetchRideData}
              ride={this.props.ride}
              t={this.props.t}
              i18n={this.props.i18n}
              downloadFiles={downloadFiles}
            />
          ) : (
            <Series
              serie={this.props.serie}
              t={this.props.t}
              i18n={this.props.i18n}
              fetchData={this.fetchSerieData}
              downloadFiles={downloadFiles}
            />
          )}
        </VStack>

        {/* MODAL FOR REPORT ACCIDENT OR ANYTHING */}
        <FilterModal
          onSave={this.onSaveFilter}
          isOpen={this.state.showFilter}
          onClose={this.onToggleFilter}
          vehicles={this.props.vehicle.vehicles}
          drivers={this.props.transporterUsers.users.users.filter(
            (u) => u.userType
          )}
          initalState={this.state.filter}
          page="history"
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ride: state.historyRides.asMutable({ deep: true }),
    serie: state.historySeries.asMutable({ deep: true }),
    action: state.historyRides.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    transporterRideHistoryRequest: (...args) =>
      dispatch(historyRidesActions.transporterRideHistoryRequest(...args)),
    transporterSerieHistoryRequest: (...args) =>
      dispatch(historySeriesActions.transporterSerieHistoryRequest(...args)),
  };
};

const historyRedux = connect(mapStateToProps, mapDispatchToProps)(HistoryRides);
export default withTranslation(["Private", "Common"], { wait: true })(
  historyRedux
);
