import { Subscription } from "@apollo/client/react/components";
import { Text } from "components";
import gql from "graphql-tag";

const TRANSPORTER_PATIENT_STATUS_SUBSCRIPTION = gql`
  subscription {
    TransporterPatientStatusSubscription {
      _id
      rideNumber
      patient {
        status
      }
    }
  }
`;

const OnGoingRidePatientStatusNotification = ({
  onGoingRidePatientStatusChanged,
  showNotification,
}) => {
  function onSubscriptionData(ride) {
    if (ride !== undefined) {
      onGoingRidePatientStatusChanged(ride);

      showNotification({
        page: "/reservation",
        message: `Status du patient du transport ${ride.rideNumber} a changé.`,
        type: "info",
        readed: false,
      });
    }
  }

  return (
    <Subscription
      subscription={TRANSPORTER_PATIENT_STATUS_SUBSCRIPTION}
      onSubscriptionData={({ subscriptionData }) => {
        onSubscriptionData(
          subscriptionData.data.TransporterPatientStatusSubscription
        );
      }}
    >
      {({ data = {}, loading, error }) => null}
    </Subscription>
  );
};

export default OnGoingRidePatientStatusNotification;
