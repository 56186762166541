import { HStack } from "native-base";
import { Text } from "components";
import RideIcon from "assets/img/ride/ride_destination.svg";

const RideDestination = ({ w, dropoff, pickup }) => {
  return (
    <HStack space={2} w={w}>
      <img src={RideIcon} height="100%" />

      <Text
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Text noOfLines={2} fontWeight="900" fontSize="16px">
          {pickup}
        </Text>
        <Text noOfLines={2} fontWeight="900" fontSize="16px">
          {dropoff}
        </Text>
      </Text>
    </HStack>
  );
};

export default RideDestination;
