import { Component } from "react";
import transporterIncomingRidesAction from "reducers/ride/transporter-incoming-rides";
import transporterIncomingSeriesAction from "reducers/serie/transporter-incoming-series";
import { withTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import { Alert, Center, Stack, View, VStack } from "native-base";
import { connect } from "react-redux";
import {
  Title,
  Text,
  InComingRidesCard,
  AcceptRideModal,
  FloatingInput,
  NotificationMenu,
  RefuseRideModal,
  AcceptRideSuccessModal,
  InComingSerieCard,
  AcceptSerieModal,
  RefuseSerieModal,
} from "components";
import InfiniteScroll from "react-infinite-scroll-component";
// import moment from "moment";
import noDataIcon from "assets/img/no_data.svg";

class InComingRides extends Component {
  constructor(props) {
    super(props);

    this.onSearchVehicleFilter = this.onSearchVehicleFilter.bind(this);
    this.onPressAcceptRideButton = this.onPressAcceptRideButton.bind(this);
    this.onShowAcceptRideSuccess = this.onShowAcceptRideSuccess.bind(this);
    this.onCloseShowAcceptRideSuccess =
      this.onCloseShowAcceptRideSuccess.bind(this);
    this.onCancelAcceptRidePopUp = this.onCancelAcceptRidePopUp.bind(this);
    this.onPressRefuseRideButton = this.onPressRefuseRideButton.bind(this);
    this.onCancelRefuseRidePopUp = this.onCancelRefuseRidePopUp.bind(this);
    this.successAlert = this.successAlert.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.errorAlert = this.errorAlert.bind(this);

    this.fetchData = this.fetchData.bind(this);

    this.onPressAcceptSerieButton = this.onPressAcceptSerieButton.bind(this);
    this.onCancelAcceptSeriePopUp = this.onCancelAcceptSeriePopUp.bind(this);
    this.onPressRefuseSerieButton = this.onPressRefuseSerieButton.bind(this);
    this.onCancelRefuseSeriePopUp = this.onCancelRefuseSeriePopUp.bind(this);

    this.state = {
      searchInputValue: "",
      showAcceptRidePopUp: false,
      showAcceptRideSuccessPopUp: false,
      showRefuseRidePopUp: false,
      selectedRide: null,

      showAcceptSeriePopUp: false,
      showRefuseSeriePopUp: false,
      selectedSerie: null,

      alert: null,
    };
  }

  componentDidMount() {
    const {
      transporterIncomingRidesRequest,
      transporterIncomingSeriesRequest,
    } = this.props;
    transporterIncomingRidesRequest(1, 10);
    transporterIncomingSeriesRequest(1, 10);
  }

  onSearchVehicleFilter(e) {
    const { value } = e.target;
    this.setState({ searchInputValue: value });
    this.props.transporterIncomingRidesRequest(1, 10, value);
  }

  onPressAcceptSerieButton(serie) {
    const { transporterVehiclesRequest } = this.props;
    // load vehicles
    transporterVehiclesRequest(1, 100);

    this.setState(
      Object.assign({}, this.state, {
        showAcceptSeriePopUp: true,
        selectedSerie: serie,
      })
    );
  }

  onPressRefuseSerieButton(serie) {
    this.setState(
      Object.assign({}, this.state, {
        showRefuseSeriePopUp: true,
        selectedSerie: serie,
        // showAcceptRidePopUp: false,
        // selectedRide: null,
      })
    );
  }

  onCancelAcceptSeriePopUp() {
    this.setState(
      Object.assign({}, this.state, { showAcceptSeriePopUp: false })
    );
  }

  onCancelRefuseSeriePopUp() {
    this.setState(
      Object.assign({}, this.state, { showRefuseSeriePopUp: false })
    );
  }

  onPressAcceptRideButton(ride) {
    const { transporterVehiclesRequest } = this.props;
    // load vehicles
    transporterVehiclesRequest(1, 100);

    this.setState(
      Object.assign({}, this.state, {
        showAcceptRidePopUp: true,
        selectedRide: ride,
      })
    );
  }

  onCancelAcceptRidePopUp() {
    this.setState(
      Object.assign({}, this.state, { showAcceptRidePopUp: false })
    );
  }

  onPressRefuseRideButton(ride) {
    this.setState(
      Object.assign({}, this.state, {
        showRefuseRidePopUp: true,
        selectedRide: ride,
      })
    );
  }

  onCancelRefuseRidePopUp() {
    this.setState(
      Object.assign({}, this.state, { showRefuseRidePopUp: false })
    );
  }

  onShowAcceptRideSuccess() {
    setTimeout(() => {
      this.setState({ showAcceptRideSuccessPopUp: true });
    }, 800);

    setTimeout(() => {
      this.onCloseShowAcceptRideSuccess();
    }, 6_000);
  }

  onCloseShowAcceptRideSuccess() {
    this.setState({ showAcceptRideSuccessPopUp: false });
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  successAlert() {
    const { t, i18n } = this.props;
    this.setState({
      alert: (
        <Alert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title=""
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {t("ReservationsInDemandesPage.ride_accepted_with_success", {
            lng: i18n.language,
          })}
        </Alert>
      ),
    });
  }

  errorAlert() {
    const { t, i18n } = this.props;
    this.setState({
      alert: (
        <Alert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title=""
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {t("ReservationsInDemandesPage.ride_accepted_with_failure", {
            lng: i18n.language,
          })}
        </Alert>
      ),
    });
  }

  fetchData() {
    const {
      transporterIncomingRidesRequest,
      transporterIncomingSeriesRequest,
      inComingRides: rides,
      inComingSeries: series,
    } = this.props;
    const { page, limit, totalPages } = rides;
    if (page < totalPages) transporterIncomingRidesRequest(page + 1, limit);

    if (series.page < series.totalPages)
      transporterIncomingSeriesRequest(series.page + 1, series.limit);
  }

  render() {
    const {
      t,
      i18n,
      actionError,
      inComingRides: rides,
      inComingSeries: series,
    } = this.props;
    // const { totalDocs = 0, totalPages = 0, limit } = rides;
    const { error, loading } = actionError;
    const {
      showAcceptRidePopUp,
      showRefuseRidePopUp,
      selectedRide,
      searchInputValue,
      showAcceptRideSuccessPopUp,
      selectedSerie,
      showAcceptSeriePopUp,
      showRefuseSeriePopUp,
    } = this.state;

    return (
      <View w="full">
        <VStack space="5" ml="24" mr="12" h="full">
          <VStack space="5" h="48" mt="5">
            {error !== "" && (
              <Alert bsStyle="danger">
                <span>{error}</span>
              </Alert>
            )}

            <Title>
              {t("ReservationsInDemandesPage.demandes_title_headline", {
                lng: i18n.language,
              })}
            </Title>

            {/* Notification menu */}
            <Stack alignItems="end">
              <NotificationMenu
                items={this.props.notifications}
                onReadedNotifications={this.props.onReadedNotifications}
              />
            </Stack>
            {/* Search */}
            <FloatingInput
              inputType="search"
              label={t("searchBar", { lng: i18n.language })}
              onChange={this.onSearchVehicleFilter}
              value={searchInputValue}
            />
          </VStack>

          {/* No Data */}
          {rides.rides.length == 0 && series.series.length == 0 && (
            <Center flex={1}>
              <img src={noDataIcon} />
              <Text fontSize="18">
                {t("ReservationsInDemandesPage.message_when_no_data")}
              </Text>
            </Center>
          )}
          <div
            id="scrollableDivIncomingRides"
            style={{
              overflow: "auto",
            }}
          >
            <InfiniteScroll
              dataLength={rides.rides.length} //This is important field to render the next data
              next={this.fetchData}
              hasMore={true}
              scrollableTarget="scrollableDivIncomingRides"
              loader={
                <Loader
                  type="ThreeDots"
                  color="#00BFFF"
                  height={100}
                  width={100}
                  visible={loading}
                />
              }
            >
              {/* SERIES */}
              {series.series.map((serie, key) => {
                return (
                  <InComingSerieCard
                    serie={serie}
                    {...{ t, i18n }}
                    key={key}
                    mb="10px"
                    onPressAcceptSerieButton={this.onPressAcceptSerieButton}
                    onPressRefuseSerieButton={this.onPressRefuseSerieButton}
                  />
                );
              })}
              {/* RIDES */}
              {rides.rides.map((ride, key) => (
                <InComingRidesCard
                  // diff between createdAt and now then add 10 mins (600s)
                  ride={ride}
                  {...{ t, i18n }}
                  key={key}
                  mb="10px"
                  onPressAcceptRideButton={this.onPressAcceptRideButton}
                  onPressRefuseRideButton={this.onPressRefuseRideButton}
                />
              ))}
            </InfiniteScroll>
          </div>
        </VStack>

        <AcceptRideModal
          selectedRide={selectedRide}
          isOpen={showAcceptRidePopUp}
          transporterAcceptProposedRideRequest={
            this.props.transporterAcceptProposedRideRequest
          }
          transporterRequestDatetimeValidationForProposedRideRequest={
            this.props
              .transporterRequestDatetimeValidationForProposedRideRequest
          }
          transporterAcceptProposedSerieRequest={
            this.props.transporterAcceptProposedSerieRequest
          }
          onClose={this.onCancelAcceptRidePopUp}
          companyVehicles={this.props.vehicle.vehicles}
          onShowAcceptRideSuccess={this.onShowAcceptRideSuccess}
        />
        <AcceptSerieModal
          selectedSerie={selectedSerie}
          isOpen={showAcceptSeriePopUp}
          transporterAcceptProposedSerieRequest={
            this.props.transporterAcceptProposedSerieRequest
          }
          onClose={this.onCancelAcceptSeriePopUp}
          companyVehicles={this.props.vehicle.vehicles}
        />
        <AcceptRideSuccessModal
          selectedRide={selectedRide}
          isOpen={showAcceptRideSuccessPopUp}
          onClose={this.onCloseShowAcceptRideSuccess}
        />
        {/* modal for confirm to refuse ride */}
        <RefuseRideModal
          selectedRide={selectedRide}
          isOpen={showRefuseRidePopUp}
          transporterRefuseIncomingRideRequest={
            this.props.transporterRefuseIncomingRideRequest
          }
          onClose={this.onCancelRefuseRidePopUp}
        />
        <RefuseSerieModal
          selectedSerie={selectedSerie}
          isOpen={showRefuseSeriePopUp}
          transporterRefuseIncomingSerieRequest={
            this.props.transporterRefuseIncomingSerieRequest
          }
          onClose={this.onCancelRefuseSeriePopUp}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // rides: state.inComingRides.rides.asMutable({ deep: true }),
    actionError: state.inComingRides.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // transporterIncomingRidesRequest: (...args) =>
    //   dispatch(
    //     transporterIncomingRidesAction.transporterIncomingRidesRequest(...args)
    //   ),
    transporterAcceptProposedRideRequest: (...args) =>
      dispatch(
        transporterIncomingRidesAction.transporterAcceptProposedRideRequest(
          ...args
        )
      ),
    transporterRequestDatetimeValidationForProposedRideRequest: (...args) =>
      dispatch(
        transporterIncomingRidesAction.transporterRequestDatetimeValidationForProposedRideRequest(
          ...args
        )
      ),
    transporterRefuseIncomingRideRequest: (...args) =>
      dispatch(
        transporterIncomingRidesAction.transporterRefuseIncomingRideRequest(
          ...args
        )
      ),

    transporterAcceptProposedSerieRequest: (...args) =>
      dispatch(
        transporterIncomingSeriesAction.transporterAcceptProposedSerieRequest(
          ...args
        )
      ),
    transporterRefuseIncomingSerieRequest: (...args) =>
      dispatch(
        transporterIncomingSeriesAction.transporterRefuseIncomingSerieRequest(
          ...args
        )
      ),
  };
};

const demandesRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(InComingRides);
export default withTranslation(["Private", "Common"], { wait: true })(
  demandesRedux
);
