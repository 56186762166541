import { Component } from "react";
import { Routes, Route } from "react-router-dom";
import { Stack, Box } from "native-base";
import routes from "../routes";
import waveBg from "assets/img/waves-bg.svg";
import { withTranslation } from "react-i18next";

class Public extends Component {
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/public") {
        const View = prop.component;
        return (
          <Route
            path={prop.path}
            element={<View {...this.props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <Stack
        w="100vw"
        h="100vh"
        position="relative"
        justifyContent="center"
        alignItems="center"
      >
        <Routes>{this.getRoutes(routes)}</Routes>

        <Box w="full" position="absolute" bottom="0" left="0" zIndex={-1}>
          <img src={waveBg} />
        </Box>
      </Stack>
    );
  }
}

export default withTranslation(["Public", "Common"], { wait: true })(Public);
