import { Component } from "react";
import { Center, Modal } from "native-base";
import { withTranslation } from "react-i18next";
import { Text, Title } from "components";
import resaamIcon from "assets/img/resaam-logo.svg";

class AcceptRideSuccessModal extends Component {
  render() {
    const { t, i18n } = this.props;
    return (
      <Modal {...this.props}>
        <Modal.Content maxWidth="588px" maxHeight="545px" py="20px">
          <Modal.Body alignItems="center">
            <Center>
              <img width="150px" src={resaamIcon} />
              <Title fontSize="36px" fontWeight="400">
                Transport non assigné !
              </Title>
              <Text>
                Vous avez accepté le transport. N'oubliez pas de l'assigner dans
                les réservations en cours.
              </Text>
            </Center>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withTranslation(["Private", "Common"], { wait: true })(
  AcceptRideSuccessModal
);
