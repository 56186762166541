import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import _ from "lodash";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  transporterRideHistoryResetState: null,
  transporterRideHistoryRequest: ["page", "limit", "filter"],
  transporterRideHistoryRequestSuccess: ["rides"],
  transporterRideHistoryRequestFailure: ["error"],
  historyRideFilesUpdated: ["ride"],
});

export const TransporterRideHistoryTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  rides: {
    page: 1,
    limit: 10,
    totalPages: 0,
    rides: [],
  },
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

export const transporterRideHistoryResetState = (state, action) => {
  return INITIAL_STATE;
};

export const transporterRideHistoryRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const transporterRideHistoryRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const transporterRideHistoryRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  if (action.rides.page == 1)
    //first page
    return Immutable.setIn(nextSate, ["rides"], action.rides);
  else {
    //apend next pages
    let prevList = state.rides.rides.asMutable({ deep: true });
    const { rides, ...rest } = action.rides;

    return Immutable.setIn(nextSate, ["rides"], {
      ...rest,
      rides: [...prevList, ...rides],
    });
  }
};

export const historyRideFilesUpdated = (state, action) => {
  const { ride } = action;
  let rides = state.rides.rides.asMutable({ deep: true });
  let _rides = _.map(rides, (r) => {
    if (ride._id === r._id) {
      return { ...r, ...ride };
    }
    return r;
  });
  return Immutable.setIn(state, ["rides", "rides"], _rides);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.TRANSPORTER_RIDE_HISTORY_RESET_STATE]:
    transporterRideHistoryResetState,
  [Types.TRANSPORTER_RIDE_HISTORY_REQUEST]: transporterRideHistoryRequest,
  [Types.TRANSPORTER_RIDE_HISTORY_REQUEST_SUCCESS]:
    transporterRideHistoryRequestSuccess,
  [Types.TRANSPORTER_RIDE_HISTORY_REQUEST_FAILURE]:
    transporterRideHistoryRequestFailure,
  [Types.HISTORY_RIDE_FILES_UPDATED]: historyRideFilesUpdated,
});
