import React, { Component } from "react";
import { connect } from "react-redux";
import AccountActions from "reducers/account";
import Public from "./public";
import Private from "./private/index.js";

import { WebSocketLink } from "@apollo/client/link/ws";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ApolloProvider } from "@apollo/client";

let WS_URL = "";

if (process.env.REACT_APP_STAGE === "dev")
  WS_URL =
    process.env.REACT_APP_BACK_END_WS_DEV +
    process.env.REACT_APP_WS_SUBSCRIPRTION;
else if (process.env.REACT_APP_STAGE === "prod")
  WS_URL =
    process.env.REACT_APP_BACK_END_WS_PROD +
    process.env.REACT_APP_WS_SUBSCRIPRTION;
else if (process.env.REACT_APP_STAGE === "demo")
  WS_URL =
    process.env.REACT_APP_BACK_END_WS_DEMO +
    process.env.REACT_APP_WS_SUBSCRIPRTION;
else
  WS_URL =
    process.env.REACT_APP_BACK_END_WS + process.env.REACT_APP_WS_SUBSCRIPRTION;

const PrivateContainer = (props) => {
  const { token = null } = props.account;

  const link = new WebSocketLink({
    uri: WS_URL,
    lazy: true,
    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  });

  const GraphQLClient = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={GraphQLClient}>
      <Private {...props} />
    </ApolloProvider>
  );
};

class AppLayout extends Component {
  componentDidMount() {
    document.title = "Reaam - Régulateur";
  }

  render() {
    const { token = null } = this.props.account;

    return (
      <div style={{ height: "100vh", width: "100vw" }}>
        {token === null ? (
          <Public {...this.props} />
        ) : (
          <PrivateContainer {...this.props} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signinRequest: (...args) => dispatch(AccountActions.signinRequest(...args)),
    signoutRequest: (...args) =>
      dispatch(AccountActions.signoutRequest(...args)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
