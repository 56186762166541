import React from "react";
import { HStack, VStack } from "native-base";
import { Text } from "../Text";

export default function InterventionInfo(props) {
  const { intervention = null, t, i18n } = props;
  const notSpecifiedText = t("interventionInfo.not_specified", {
    lng: i18n.language,
  });

  if (!intervention) return null;

  return (
    <HStack space={10}>
      <VStack space={1} px={2} flex={1}>
        <HStack space={1}>
          <Text fontWeight="900">
            {t("interventionInfo.complement", {
              lng: i18n.language,
            })}
          </Text>
          <Text>{intervention.complement || notSpecifiedText}</Text>
        </HStack>
        <HStack space={4}>
          <HStack space={1}>
            <Text fontWeight="900">
              {t("interventionInfo.tower", {
                lng: i18n.language,
              })}
            </Text>
            <Text>{intervention.batiment || notSpecifiedText}</Text>
          </HStack>

          <HStack space={1}>
            <Text fontWeight="900">
              {t("interventionInfo.stage", {
                lng: i18n.language,
              })}
            </Text>
            <Text>{intervention.etage || notSpecifiedText}</Text>
          </HStack>

          <HStack space={1}>
            <Text fontWeight="900">
              {t("interventionInfo.digicode", {
                lng: i18n.language,
              })}
            </Text>
            <Text>{intervention.digicode || notSpecifiedText}</Text>
          </HStack>
        </HStack>
        <HStack space={1}>
          <Text fontWeight="900">
            {t("interventionInfo.poi", {
              lng: i18n.language,
            })}
          </Text>
          <Text>{intervention.poi || notSpecifiedText}</Text>
        </HStack>
      </VStack>
      {/* <VStack flex={1} space={2}>

        <VStack>
          <Text>
            {t("interventionInfo.subject", {
              lng: i18n.language,
            })}
          </Text>
          <Text>{subject||notSpecifiedText}</Text>
        </VStack>

        <VStack>
          <Text>
            {t("interventionInfo.observation", {
              lng: i18n.language,
            })}
          </Text>
          <Text>{observation||notSpecifiedText}</Text>
        </VStack>
      </VStack> */}
    </HStack>
  );
}
