import { Component } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserNurse,
  faUser,
  faUserGraduate,
} from "@fortawesome/free-solid-svg-icons";
import { HStack, VStack, Box, Menu, Pressable, Toast } from "native-base";
import Card from "./Card";
import { Text } from "../Text";
import NotSpecified from "components/NotSpecified";
import threeDotIcon from "assets/img/three_dots.svg";
import NotificationCustom from "components/NotificationCustom";

const ICONS_USER = {
  transporterUser: faUserGraduate,
  transporterDriver: faUserNurse,
  null: faUser,
};

function isDea(role) {
  return ["transporterDriver", "transporterUser"].includes(role);
}

function isOffLine(status) {
  return ["disconnected", "offline"].includes(status);
}

function getStatusTextAndColor(user) {
  const { active, userType, status, inUse } = user;
  let statusColor = "gray";
  let statusText = "Désactivé";

  if (!active) return { statusColor, statusText };
  if (isDea(userType)) {
    statusColor = isOffLine(status)
      ? "danger"
      : status === "busy"
      ? "info"
      : "success";
    statusText = isOffLine(status)
      ? "Déconnecté"
      : status === "busy"
      ? "Occupé"
      : "Disponible";
  } else {
    statusColor = inUse ? "success" : "danger";
    statusText = inUse ? "Disponible" : "Déconnecté";
  }
  return {
    statusColor,
    statusText,
  };
}

class UserCard extends Component {
  constructor(props) {
    super(props);
    // this.delete = this.delete.bind(this);
    this.toggleActivate = this.toggleActivate.bind(this);
  }

  toggleActivate() {
    const {
      toggleActivateUser,
      user: { _id: userId, userType },
    } = this.props;

    toggleActivateUser(
      userId,
      userType === null ? "AUX" : userType,
      (isSuccess = true) =>
        Toast.show(
          NotificationCustom({
            message: isSuccess
              ? "L'utilisateur a été activé."
              : "L'utilisateur a été désactivé.",
          })
        )
    );
  }

  // delete() {
  //   const {
  //     transporterDeleteUserRequest,
  //     user: { _id: userId, plate },
  //   } = this.props;
  //   transporterDeleteUserRequest(userId, () =>
  //     console.log(`L'utilisateur a été désactivé.`)
  //   );
  // }

  render() {
    const { t, i18n, user = {}, currentCompany } = this.props;
    const {
      firstname = "",
      lastname = "",
      // avatar,
      phone = "",
      email = "",
      userType,
      vehicle = null,
      teamMate,
      active,
    } = user;
    const { statusColor, statusText } = getStatusTextAndColor(
      user,
      currentCompany
    );

    return (
      <Card
        rounded="4px"
        maxWidth="986px"
        h="75px"
        py={0}
        pl="29px"
        mb="8px"
        borderColor="gray"
        borderWidth="1px"
        position="relative"
      >
        <Box position="absolute" top={1} right={1}>
          {userType !== "transporterUser" && (
            <Menu
              placement="bottom"
              trigger={(triggerProps) => (
                <Pressable
                  {...triggerProps}
                  accessibilityLabel="More option menu"
                  px={3}
                  py={3}
                >
                  <img src={threeDotIcon} />
                </Pressable>
              )}
            >
              <Menu.Item onPress={this.toggleActivate}>
                {t(
                  `StaffPage.button_${
                    active ? "desactivate" : "activate"
                  }_user`,
                  { lng: i18n.language }
                )}
              </Menu.Item>
            </Menu>
          )}
        </Box>
        <HStack h="full" space={4}>
          <HStack
            borderColor="gray"
            borderRightWidth={1}
            w="150px"
            alignItems="center"
            space={4}
          >
            <FontAwesomeIcon
              icon={ICONS_USER[userType]}
              size="lg"
              style={{ color: "#999C9F" }}
            />
            <Text fontSize="16px" fontWeight="700">
              {_.capitalize(
                t(
                  `addUserPopUp.add_user_popup_user_type.${
                    isDea(userType) ? "DEA" : "AUXILIARY"
                  }`,
                  { lng: i18n.language }
                )
              )}
            </Text>
          </HStack>

          <HStack pt="12px" flex={1} pl="20px">
            <VStack flex={1}>
              <Text fontSize="16px" fontWeight="900">
                {_.upperCase(lastname)}
              </Text>
              <Text fontSize="16px" fontWeight="900">
                {_.capitalize(firstname)}
              </Text>
            </VStack>

            <VStack flex={1}>
              <Text fontSize="12px" fontWeight="500" color="gray">
                {t("StaffPage.card_label_contact", { lng: i18n.language })}
              </Text>
              {!phone && !email && <NotSpecified />}
              {phone && (
                <Text fontSize="12px" fontWeight="500">
                  {phone}
                </Text>
              )}
              {email && (
                <Text fontSize="12px" fontWeight="500">
                  {email}
                </Text>
              )}
            </VStack>

            <VStack flex={1}>
              <Text fontSize="12px" fontWeight="500" color="gray">
                {t("StaffPage.card_label_vehicle", { lng: i18n.language })}
              </Text>
              <Text fontSize="12px" fontWeight="500">
                {_.capitalize(vehicle?.model)}
              </Text>
              <Text fontSize="12px" fontWeight="500">
                {_.upperCase(vehicle?.plate)}
              </Text>
            </VStack>

            <VStack flex={1}>
              <Text fontSize="12px" fontWeight="500" color="gray">
                {t("StaffPage.card_label_partner", { lng: i18n.language })}
              </Text>
              <Text fontSize="12px" fontWeight="500">
                {_.upperCase(teamMate?.lastname)}
              </Text>
              <Text fontSize="12px" fontWeight="500">
                {_.capitalize(teamMate?.firstname)}
              </Text>
            </VStack>

            <HStack space="2" justifyContent="center" flex={1}>
              <Box rounded="full" size="15px" bg={statusColor} />
              <Text
                fontSize="14px"
                fontWeight="700"
                fontStyle="italic"
                color={statusColor}
              >
                {statusText}
              </Text>
            </HStack>
          </HStack>
        </HStack>
      </Card>
    );
  }
}

export default UserCard;
