import { Component } from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import moment from "moment";
import { saveAs } from "file-saver";
import { Box, HStack, Stack, Toast, Center } from "native-base";
import _ from "lodash";

import routes from "routes";
import TransporterVehicleActions from "reducers/transporter/transporter-vehicle";
import rideChangeStatusAction from "reducers/transporter/transporter-ongoing-rides";
import incomingRideAction from "reducers/ride/transporter-incoming-rides";
import incomingSerieAction from "reducers/serie/transporter-incoming-series";
import transporterUsersAction from "reducers/transporter/transporter-user";
import TransporterRideHistoryAction from "reducers/transporter/transporter-rides-history";
import MinioActions from "reducers/minio";

import { SideBar, Text, NotificationCustom } from "components";

import {
  IncomingRideLeftNotification,
  IncomingRideNotification,
  OnGoingRidePatientStatusNotification,
  OnGoingRideStatusNotification,
  IncomingRideSamuNotification,
  RideFilesUpdateNotification,
} from "layouts/private/ride";

import {
  IncomingSerieNotification,
  IncomingSerieLeftNotification,
  OnGoingSerieStatusNotification,
} from "layouts/private/serie";

import bip from "assets/raw/juntos.mp3";

class Private extends Component {
  constructor(props) {
    super(props);

    this.playSoundNotification = this.playSoundNotification.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.onReadedNotifications = this.onReadedNotifications.bind(this);
    this.readedNotificationInPage = this.readedNotificationInPage.bind(this);
    this.inComingRidesSoundReminder =
      this.inComingRidesSoundReminder.bind(this);
    this.searchRideStartSoon = this.searchRideStartSoon.bind(this);
    this.downloadFiles = this.downloadFiles.bind(this);

    this.state = {
      soundIsPlayed: false,
      notifications: [],
      soundReminder: null,
    };
  }

  componentDidMount() {
    // load profile firstname, lastname, society name...
    this.props.profileRequest();
    // load all vehicles and dea
    this.props.transporterVehiclesRequest(1, 100);
    this.props.transporterUsersRequest(1, 100);
    this.inComingRidesSoundReminder();

    this.searchRideStartSoon();
    this.intervalRideNeedAssign = setInterval(() => {
      this.searchRideStartSoon();
    }, 1000 * 60 * 5); // 5min
  }

  componentWillUnmount() {
    clearInterval(this.state.soundReminder);
    clearInterval(this.intervalRideNeedAssign);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.inComingRides.rides.length !==
      prevProps.inComingRides.rides.length
    ) {
      this.inComingRidesSoundReminder();
    }
  }

  downloadFiles(paths, prefixFileName = "") {
    this.props.getPresignedUrl(
      paths.map((e) => e.path),
      async (urls) => {
        for (const urls3 of urls) {
          const file = paths.find((i) => decodeURI(urls3).includes(i.name));
          fetch(urls3, { method: "GET" })
            .then((res) => {
              return res.blob();
            })
            .then((blob) => {
              let fileName = `${prefixFileName}_${file.name}`;
              // if include extension at the end of the file name like "file.pdf"
              if (!fileName.includes("." + file.extension)) {
                fileName = `${fileName}.${file.extension}`;
              }
              saveAs(blob, fileName);
            })
            .catch((err) => {
              // console.error('err: ', err);
            });
        }
      }
    );
  }

  inComingRidesSoundReminder() {
    let isReminder = false;
    for (const ride of this.props.inComingRides.rides) {
      if (ride.status !== "anoffer") {
        isReminder = true;
      }
    }

    clearInterval(this.state.soundReminder);

    if (isReminder) {
      const interval = setInterval(() => {
        this.playSoundNotification();
      }, 20_000);
      this.setState({ soundReminder: interval });
    }
  }

  playSoundNotification(type = "default") {
    this.setState({ soundIsPlayed: true });
    let sound = bip;
    // type = ["info", "success", "danger"];
    switch (type) {
      case "default":
        sound = bip;
        break;
      default:
        break;
    }

    new Audio(bip).play();

    setTimeout(() => {
      this.setState({ soundIsPlayed: false });
    }, 2_000);
  }

  showNotification(notif) {
    if (!this.state.soundIsPlayed) {
      this.playSoundNotification(notif.type);
    }

    Toast.show({
      placement: "top-right",
      render: () => {
        return (
          <Box mr="8" bg="primary" px="12px" py="3px" borderRadius="10px">
            <Center minHeight="8">
              <Text color="ligth">{notif.message}</Text>
            </Center>
          </Box>
        );
      },
    });

    this.setState({ notifications: [notif, ...this.state.notifications] });
  }

  onReadedNotifications() {
    const notifsReaded = this.state.notifications.map((notif) => ({
      ...notif,
      readed: true,
    }));
    this.setState({ notifications: notifsReaded });
  }

  readedNotificationInPage(page) {
    const { notifications } = this.state;
    const notificationsUpdated = notifications.map((notif) => {
      if (notif.page === page) return { ...notif, readed: true };
      return notif;
    });
    this.setState({ notifications: notificationsUpdated });
  }

  searchRideStartSoon() {
    const { onGoingRides, ridesNeedAssign, onGoingRidesAddRideNeedAssign } =
      this.props;
    for (const ride of onGoingRides.rides) {
      if (
        ride.transporter.vehicle === null &&
        !ridesNeedAssign.includes(ride._id)
      ) {
        var date = moment(ride.datetime).subtract(30, "minutes");
        var now = moment();

        if (now > date) {
          onGoingRidesAddRideNeedAssign(ride._id);
          Toast.show(
            NotificationCustom({
              message: `Le transport ${ride.rideNumber} a besoin d'être assigné`,
            })
          );
          this.playSoundNotification();
        }
      }
    }
  }

  getRoutes(routes) {
    return routes.map((prop, key) => {
      if (prop.layout === "/private") {
        const View = prop.component;
        return (
          <Route
            key={key}
            path={prop.path}
            element={
              <View
                {...this.props}
                notifications={this.state.notifications}
                onReadedNotifications={this.onReadedNotifications}
                downloadFiles={this.downloadFiles}
              />
            }
          />
        );
      } else return null;
    });
  }

  render() {
    const { profile } = this.props;
    return (
      <>
        <Stack h="full">
          {/* <UserStatusSubscription   {...this.props} /> */}
          <HStack w="full" h="full" bg="ligth">
            <IncomingRideNotification
              transporterDidReceiveRide={this.props.transporterDidReceiveRide}
              showNotification={this.showNotification}
            />

            <IncomingRideLeftNotification
              transporterDidRideLeft={this.props.transporterDidRideLeft}
              transporterRideGotByOther={this.props.transporterRideGotByOther}
              showNotification={this.showNotification}
            />

            <OnGoingRideStatusNotification
              onGoingRideStatusChanged={this.props.onGoingRideStatusChanged}
              onGoingRidesRemoveRide={this.props.onGoingRidesRemoveRide}
              showNotification={this.showNotification}
            />

            <OnGoingRidePatientStatusNotification
              onGoingRidePatientStatusChanged={
                this.props.onGoingRidePatientStatusChanged
              }
              showNotification={this.showNotification}
            />

            <IncomingRideSamuNotification
              onGoingRidesNewRideRequest={this.props.onGoingRidesNewRideRequest}
              showNotification={this.showNotification}
            />

            <IncomingSerieNotification
              transporterDidReceiveSerie={this.props.transporterDidReceiveSerie}
              showNotification={this.showNotification}
            />

            <IncomingSerieLeftNotification
              transporterDidSerieLeft={this.props.transporterDidSerieLeft}
              transporterSerieGotByOther={this.props.transporterSerieGotByOther}
              showNotification={this.showNotification}
            />

            <OnGoingSerieStatusNotification
              onGoingRidesNewRideRequest={this.props.onGoingRidesNewRideRequest}
              showNotification={this.showNotification}
            />

            <RideFilesUpdateNotification
              ongoingRideFilesUpdated={this.props.ongoingRideFilesUpdated}
              historyRideFilesUpdated={this.props.historyRideFilesUpdated}
              showNotification={this.showNotification}
            />

            {/* Subscription when service validate the proposed offer datetime */}

            <SideBar
              minWidth="125px"
              notifications={this.state.notifications}
              readedNotificationInPage={this.readedNotificationInPage}
              {...this.props}
            />

            <div style={{ display: "flex", flexGrow: 1, minWidth: "" }}>
              <Routes>{this.getRoutes(routes)}</Routes>
            </div>

            <Box
              position="fixed"
              top={0}
              right={0}
              px="20px"
              py="5px"
              borderBottomLeftRadius={12}
              display="flex"
              alignItems="center"
              gap="20px"
              color="black"
              bg="#E8E8E8"
              shadow="rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
            >
              <Text fontSize="14px">
                {_.capitalize(profile?.lastname)}{" "}
                {_.capitalize(profile?.firstname)} -{" "}
                {_.capitalize(profile?.company?.name)}
              </Text>
              <div className="setings d-none d-lg-block">
                {/* <Menu menuButton={<img src={gear} className="menu-deroulant" />}>
                  <MenuItem>
                    <NavLink to={"/private/settings"}>Paramètres</NavLink>
                  </MenuItem> */}
                {/* <MenuItem className="logout" onClick={this.logout}>
                    {t("navLinks.log_out_menu_item_label", { lng: i18n.language })}
                  </MenuItem> */}
                {/* </Menu> */}
              </div>
            </Box>
          </HStack>
        </Stack>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    vehicle: state.transporterVehicles.transporter.vehicles.asMutable({
      deep: true,
    }),
    inComingRides: state.inComingRides.rides.asMutable({ deep: true }),
    inComingSeries: state.incomingSeries.series.asMutable({ deep: true }),
    onGoingRides: state.onGoingRides.rides.asMutable({ deep: true }),
    ridesNeedAssign: state.onGoingRides.ridesNeedAssign.asMutable({
      deep: true,
    }),
    transporterUsers: state.transporterUsers.asMutable({
      deep: true,
    }),
    profile: state.settings.profile.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    transporterIncomingRidesRequest: (...args) =>
      dispatch(incomingRideAction.transporterIncomingRidesRequest(...args)),
    transporterVehiclesRequest: (...args) =>
      dispatch(TransporterVehicleActions.transporterVehiclesRequest(...args)),

    // transporterDriverStatusDidChanged: (...args) => dispatch(driverChangeStatusAction.transporterDriverStatusDidChanged(...args)),
    onGoingRideStatusChanged: (...args) =>
      dispatch(rideChangeStatusAction.onGoingRideStatusChanged(...args)),
    onGoingRidesRemoveRide: (...args) =>
      dispatch(rideChangeStatusAction.onGoingRidesRemoveRide(...args)),
    onGoingRidePatientStatusChanged: (...args) =>
      dispatch(rideChangeStatusAction.onGoingRidePatientStatusChanged(...args)),
    transporterDidReceiveRide: (...args) =>
      dispatch(incomingRideAction.transporterDidReceiveRide(...args)),
    transporterDidRideLeft: (...args) =>
      dispatch(incomingRideAction.transporterDidRideLeft(...args)),
    transporterRideGotByOther: (...args) =>
      dispatch(incomingRideAction.transporterRideGotByOther(...args)),

    transporterIncomingSeriesRequest: (...args) =>
      dispatch(incomingSerieAction.transporterIncomingSeriesRequest(...args)),
    transporterDidReceiveSerie: (...args) =>
      dispatch(incomingSerieAction.transporterDidReceiveSerie(...args)),
    transporterDidSerieLeft: (...args) =>
      dispatch(incomingSerieAction.transporterDidSerieLeft(...args)),
    transporterSerieGotByOther: (...args) =>
      dispatch(incomingSerieAction.transporterSerieGotByOther(...args)),

    onGoingRidesAddRideNeedAssign: (...args) =>
      dispatch(rideChangeStatusAction.onGoingRidesAddRideNeedAssign(...args)),
    onGoingRidesNewRideRequest: (...args) =>
      dispatch(rideChangeStatusAction.onGoingRidesNewRideRequest(...args)),

    transporterUsersRequest: (...args) =>
      dispatch(transporterUsersAction.transporterUsersRequest(...args)),
    getPresignedUrl: (...args) =>
      dispatch(MinioActions.getPresignedUrl(...args)),
    ongoingRideFilesUpdated: (...args) =>
      dispatch(rideChangeStatusAction.ongoingRideFilesUpdated(...args)),
    historyRideFilesUpdated: (...args) =>
      dispatch(TransporterRideHistoryAction.historyRideFilesUpdated(...args)),
    profileRequest: () => dispatch({ type: "PROFILE_REQUEST" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Private);
