import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import _ from "lodash";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  transporterVehiclesResetState: null,

  transporterVehiclesRequest: ["page", "limit", "filter"],
  transporterVehiclesRequestSuccess: ["vehicles"],
  transporterVehiclesRequestFailure: ["error"],

  transporterCreateVehicleRequest: ["vehicle", "callback"],
  transporterCreateVehicleRequestSuccess: ["vehicle"],
  transporterCreateVehicleRequestFailure: ["error"],

  // transporterModifyVehicleRequest: ["vehicle", "id", "callback"],
  // transporterModifyVehicleRequestSuccess: null,
  // transporterModifyVehicleRequestFailure: ["error"],

  transporterDeleteVehicleRequest: ["vehiculeId"],
  transporterDeleteVehicleRequestSuccess: null,
  transporterDeleteVehicleRequestFailure: ["error"],
});

export const TransporterVehicleTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  transporter: {
    details: null,
    users: {
      drivers: [],
      admins: [],
    },
    vehicles: {
      page: 1,
      limit: 10,
      vehicles: [],
    },
  },
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

export const transporterVehiclesResetState = (state, action) => {
  return INITIAL_STATE;
};

export const transporterVehiclesRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const transporterVehiclesRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(
    nextSate,
    ["transporter", "vehicles"],
    action.vehicles
  );
};

export const transporterVehiclesRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

/* ------add vehicle -----------------------*/

export const transporterCreateVehicleRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const transporterCreateVehicleRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const transporterCreateVehicleRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

// export const transporterModifyVehicleRequest = (state, action) => {
//     let nextSate = Immutable.setIn(state, ["action", 'loading'], true);
//     return Immutable.setIn(nextSate, ["action", 'error'], "");
// }

// export const transporterModifyVehicleRequestSuccess = (state, action) => {
//     return Immutable.setIn(state, ["action", 'loading'], false);

// }

// export const transporterModifyVehicleRequestFailure = (state, action) => {
//     let nextSate = Immutable.setIn(state, ["action", 'loading'], false);
//     return Immutable.setIn(nextSate, ["action", 'error'], action.error);
// }

export const transporterDeleteVehicleRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const transporterDeleteVehicleRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const transporterDeleteVehicleRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.TRANSPORTER_VEHICLES_RESET_STATE]: transporterVehiclesResetState,
  [Types.TRANSPORTER_VEHICLES_REQUEST]: transporterVehiclesRequest,
  [Types.TRANSPORTER_VEHICLES_REQUEST_SUCCESS]:
    transporterVehiclesRequestSuccess,
  [Types.TRANSPORTER_VEHICLES_REQUEST_FAILURE]:
    transporterVehiclesRequestFailure,

  [Types.TRANSPORTER_CREATE_VEHICLE_REQUEST]: transporterCreateVehicleRequest,
  [Types.TRANSPORTER_CREATE_VEHICLE_REQUEST_SUCCESS]:
    transporterCreateVehicleRequestSuccess,
  [Types.TRANSPORTER_CREATE_VEHICLE_REQUEST_FAILURE]:
    transporterCreateVehicleRequestFailure,

  // [Types.TRANSPORTER_MODIFY_VEHICLE_REQUEST]: transporterModifyVehicleRequest,
  // [Types.TRANSPORTER_MODIFY_VEHICLE_REQUEST_SUCCESS]: transporterModifyVehicleRequestSuccess,
  // [Types.TRANSPORTER_MODIFY_VEHICLE_REQUEST_FAILURE]: transporterModifyVehicleRequestFailure,

  [Types.TRANSPORTER_DELETE_VEHICLE_REQUEST]: transporterDeleteVehicleRequest,
  [Types.TRANSPORTER_DELETE_VEHICLE_REQUEST_SUCCESS]:
    transporterDeleteVehicleRequestSuccess,
  [Types.TRANSPORTER_DELETE_VEHICLE_REQUEST_FAILURE]:
    transporterDeleteVehicleRequestFailure,
});
