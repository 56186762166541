import { Component } from "react";
import {
  Center,
  HStack,
  Modal,
  Pressable,
  ScrollView,
  Toast,
  VStack,
} from "native-base";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import { NotificationCustom, Card, Button, Text } from "components";
import { VEHICLE_ICONS } from "global";
import CancelIcon from "assets/img/ride/cancel.svg";
import CancelHoverIcon from "assets/img/ride/cancel_hover.svg";
import VehicleCard from "components/VehicleModalCard";

class AcceptRideModal extends Component {
  constructor(props) {
    super(props);

    this.onConfirmAction = this.onConfirmAction.bind(this);
    this.onCancelAction = this.onCancelAction.bind(this);

    this.setSelectedVehicle = this.setSelectedVehicle.bind(this);
    this.vehicleCategoryCompatible = this.vehicleCategoryCompatible.bind(this);

    this.state = {
      selectedVehicle: null,
      transporterProposedAnOffer: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedRide !== prevProps.selectedRide) {
      const { selectedRide } = this.props;
      let transporterProposedAnOffer = null;
      if ("startTime" in selectedRide) {
        transporterProposedAnOffer =
          Math.abs(
            moment
              .duration(
                moment(selectedRide.datetime).diff(selectedRide.startTime)
              )
              .minutes()
          ) >= 10;
      }
      this.setState(
        Object.assign({}, this.state, {
          transporterProposedAnOffer,
          selectedVehicle: null,
        })
      );
    }
  }

  componentWillUnmount() {
    this.setSelectedVehicle(false);
  }

  onCancelAction() {
    this.setSelectedVehicle(null);
    this.props.onClose();
  }

  onConfirmAction() {
    const {
      t,
      i18n,
      selectedRide,
      transporterAcceptProposedRideRequest,
      transporterRequestDatetimeValidationForProposedRideRequest,
      transporterAssignVehicleForRideRequest,
      isTransporterAssignVehicleForRide,
      onShowAcceptRideSuccess,
    } = this.props;
    const { selectedVehicle, transporterProposedAnOffer } = this.state;

    // propose an offer
    if (transporterProposedAnOffer) {
      transporterRequestDatetimeValidationForProposedRideRequest(
        selectedRide._id,
        moment(selectedRide.startTime).toISOString()
      );
      Toast.show(
        NotificationCustom({
          message: "Demande envoyée concernant la proposition d'une heure",
        })
      );
    } else if (isTransporterAssignVehicleForRide) {
      transporterAssignVehicleForRideRequest(
        selectedRide._id,
        selectedVehicle._id
      );
      Toast.show(
        NotificationCustom({
          message: `Vous avez accepté le transport ${selectedRide.rideNumber}, retrouvez le  dans les réservations en cours`,
        })
      );
    } else {
      const vehicleId =
        selectedVehicle === false ? null : `"${selectedVehicle._id}"`;
      transporterAcceptProposedRideRequest(
        selectedRide._id,
        vehicleId,
        selectedRide?.arriveIn
      );
      if (!selectedVehicle) {
        if (_.isFunction(onShowAcceptRideSuccess)) onShowAcceptRideSuccess();
      } else {
        Toast.show(
          NotificationCustom({
            message: "Transport ajouté dans réservation en cours",
          })
        );
      }
    }
    this.onCancelAction();
  }

  setSelectedVehicle(vehicle) {
    this.setState(Object.assign({}, this.state, { selectedVehicle: vehicle }));
  }

  vehicleCategoryCompatible(vehicle) {
    const { selectedRide = false } = this.props;

    if (selectedRide && vehicle.category === selectedRide.vehicleCategory)
      return true;
    return false;
  }

  getVehicles() {
    const { selectedVehicle } = this.state;
    const vehicles = this.props.companyVehicles.sort((a, b) =>
      a.driver > b.driver ? 1 : -1
    );
    return vehicles.map((vehicle, key) => {
      const vehicleIsSelected = _.isEqual(selectedVehicle, vehicle);
      const vehicleIcon = !vehicleIsSelected
        ? VEHICLE_ICONS.hover[vehicle.category]
        : VEHICLE_ICONS.default[vehicle.category];

      if (!this.vehicleCategoryCompatible(vehicle)) {
        return null;
      }

      // if (!vehicle.driver) {
      //   return (
      //     <VehicleCard vehicle={vehicle} vehicleIcon={vehicleIcon} key={key} />
      //   );
      // }

      return (
        <Pressable
          onPress={() => this.setSelectedVehicle(vehicle)}
          p="0"
          key={key}
        >
          <VehicleCard
            vehicleIsSelected={vehicleIsSelected}
            vehicle={vehicle}
            vehicleIcon={vehicleIcon}
          />
        </Pressable>
      );
    });
  }

  render() {
    const { t, i18n, isTransporterAssignVehicleForRide, selectedRide } =
      this.props;
    const { selectedVehicle, transporterProposedAnOffer } = this.state;
    const vehicleUnselected = selectedVehicle === false;
    const rideStartNow = selectedRide
      ? moment(selectedRide.datetime).diff(moment(new Date()), "minutes") < 30
      : true;

    return (
      <Modal {...this.props}>
        <Modal.Content maxWidth="588px" maxHeight="545px">
          {/* <Modal.CloseButton /> */}
          <Modal.Header alignItems="center" borderBottomWidth="0px">
            <Text fontSize="24px" fontWeight="900" lineHeight="29px">
              {t(`assignVehicleModal.title`, { lng: i18n.language })}
            </Text>
            {rideStartNow &&
              selectedRide?.arriveIn &&
              !isTransporterAssignVehicleForRide && (
                <Text fontSize="14px" fontWeight="400" lineHeight="17px" mt="4">
                  Prise en charge du patient dans les {selectedRide?.arriveIn}{" "}
                  minutes.
                </Text>
              )}
          </Modal.Header>
          <Modal.Body alignItems="center">
            <ScrollView w="full" px="36px" mt="4">
              <VStack height="full" space="10px">
                {/* NON ASSIGNER */}
                {!rideStartNow && !isTransporterAssignVehicleForRide && (
                  <Pressable onPress={() => this.setSelectedVehicle(false)}>
                    <Card
                      bg={vehicleUnselected ? "ligth" : "ligthDark"}
                      borderColor={vehicleUnselected ? "gray" : "ligthDark"}
                      w="full"
                      p="0"
                      py="1px"
                      colorBorderHover="ligthGray"
                    >
                      <HStack
                        space="2"
                        minHeight="69px"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Center w="12">
                          <img
                            src={
                              vehicleUnselected ? CancelHoverIcon : CancelIcon
                            }
                          />
                        </Center>
                        <Text w="full">
                          <Text fontSize="14px" fontWeight="900">
                            {t(`assignVehicleModal.acceptWithoutVehicle`, {
                              lng: i18n.language,
                            })}
                          </Text>
                          <Text fontSize="14px" fontWeight="400" noOfLines="2">
                            {t(
                              `assignVehicleModal.acceptWithoutVehicleDisclaimer`,
                              { lng: i18n.language }
                            )}
                          </Text>
                        </Text>
                      </HStack>
                    </Card>
                  </Pressable>
                )}
                {/* VEHICLES */}
                {!transporterProposedAnOffer && this.getVehicles()}
              </VStack>
            </ScrollView>
          </Modal.Body>
          <Modal.Footer bg="light">
            <HStack space="2" mr="36px" my="4">
              <Button
                minWidth="140px"
                third
                borderWidth="1px"
                px="36px"
                py="8px"
                onPress={this.onCancelAction}
              >
                {t(`button.cancel`, { lng: i18n.language }).toUpperCase()}
              </Button>
              <Button
                minWidth="140px"
                isDisabled={selectedVehicle === null}
                borderWidth="0px"
                px="36px"
                py="8px"
                onPress={this.onConfirmAction}
              >
                {t(`button.accept`, { lng: i18n.language }).toUpperCase()}
              </Button>
            </HStack>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withTranslation(["Private", "Common"], { wait: true })(
  AcceptRideModal
);
