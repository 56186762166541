import { Tooltip as TooltipNB } from "native-base";

const Tooltip = (props) => {
  return (
    <TooltipNB
      placement="left top"
      bg="ligth"
      maxWidth="250px"
      _text={{
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: "400",
      }}
      py="21px"
      px="15px"
      shadow={0}
      borderWidth="1px"
      borderColor="ligthGray"
      rounded="10px"
      {...props}
    >
      {props.children}
    </TooltipNB>
  );
};

export default Tooltip;
