import { Subscription } from "@apollo/client/react/components";
import gql from "graphql-tag";

const TRANSPORTER_INCOMING_RIDE_SAMU_SUBSCRIPTION = gql`
  subscription {
    TransporterIncomingRideSamuSubscription {
      _id
      patient {
        firstname
        lastname
        phone
        email
        nss
        status
        age
        gender
      }
      pickup {
        address
      }
      dropoff {
        address
      }
      status
      rideNumber
      transporter {
        vehicle {
          plate
          category
          make
          color
          model
        }
        driver
      }
      datetime
      vehicleOptions
      vehicleCategory
      service {
        company {
          name
        }
      }
      serie {
        firstIsReturn
      }
      rideType
      samu {
        name
      }
      observation
      intervention {
        numeroRue
        rue
        codePostal
        commune
        complement
        batiment
        digicode
        etage
        poi
      }
    }
  }
`;

const IncomingRideSamuNotification = ({
  onGoingRidesNewRideRequest,
  showNotification,
}) => {
  function onSubscriptionData(ride) {
    if (ride !== undefined) {
      onGoingRidesNewRideRequest(ride);
      showNotification({
        page: "/reservation",
        message: "Vous avez une nouvelle mission samu",
        type: "info",
        readed: false,
      });
    }
  }

  return (
    <Subscription
      subscription={TRANSPORTER_INCOMING_RIDE_SAMU_SUBSCRIPTION}
      onSubscriptionData={({ subscriptionData }) => {
        onSubscriptionData(
          subscriptionData.data.TransporterIncomingRideSamuSubscription
        );
      }}
    >
      {({ data = {}, loading, error }) => {
        return null;
      }}
    </Subscription>
  );
};

export default IncomingRideSamuNotification;
