import React from "react";
import { NavLink } from "react-router-dom";
import { Box, Center, VStack } from "native-base";
import approveChatIcon from "assets/img/approve_chat.svg";
import { Text } from "components";

function Register() {
  return (
    <VStack maxW="700px" gap="10px">
      <Center>
        <img src={approveChatIcon} />
      </Center>
      <VStack>
        <Text fontSize="18px" fontWeight="700">
          Votre demande a bien été prise en compte !
        </Text>
        <Text fontSize="14px">
          Vous pourrez vous connecter à votre compte une fois que votre demande
          aura été approuvée par notre équipe.
        </Text>
        <Box justifyContent="end" mt={2} w="full">
          <NavLink to={"/login"}>Se connecter</NavLink>
        </Box>
      </VStack>
    </VStack>
  );
}

export default Register;
