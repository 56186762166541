import _ from "lodash";
import { put, takeLatest } from "redux-saga/effects";
import PublicActions, { PublicTypes } from "reducers/public";

const graphQLClient = () => {
  const { graphQLClient } = require("../graphql");
  return graphQLClient;
};

function* getListOfPartnersAsyncRequest() {
  const query = `
    query { 
      transporterGetPublicPartners
    }
  `;

  try {
    const { transporterGetPublicPartners } = yield graphQLClient().request(
      query
    );
    yield put(
      PublicActions.getListOfPartnersSuccess(transporterGetPublicPartners)
    );
  } catch (error) {
    console.log(error);
    yield put(PublicActions.getListOfPartnersFailure("error"));
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(
    PublicTypes.GET_LIST_OF_PARTNERS_REQUEST,
    getListOfPartnersAsyncRequest
  ),
];
