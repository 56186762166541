import { createStore, applyMiddleware, compose } from "redux";
import { createBlacklistFilter } from "redux-persist-transform-filter";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import ImmutablePersistenceTransform from "./ImmutablePersistenceTransform";

import SagasRoot from "sagas";
import AppReducers from "reducers";

// middleware that logs actions
const loggerMiddleware = createLogger({
  predicate: (getState, action) => process.env.NODE_ENV,
});
const sagaMiddleware = createSagaMiddleware();

const saveSubsetBlacklistFilter = createBlacklistFilter("account", [
  "action.loading",
]);

const rootPersistConfig = {
  key: "root.0.1",
  storage,
  blacklist: [],
  whitelist: ["account"],
  transforms: [ImmutablePersistenceTransform, saveSubsetBlacklistFilter],
};

const persistedAppReducers = persistReducer(rootPersistConfig, AppReducers);

function configureStore(initialState) {
  let store = null;

  if (process.env.REACT_APP_STAGE === "dev")
    store = compose(applyMiddleware(sagaMiddleware, loggerMiddleware))(
      createStore
    )(persistedAppReducers);
  else
    store = compose(applyMiddleware(sagaMiddleware))(createStore)(
      persistedAppReducers
    );

  sagaMiddleware.run(SagasRoot);

  return store;
}

export const Store = configureStore({});
export const Persistor = persistStore(Store);
