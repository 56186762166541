import ImportIncomingRideNotification from "./IncomingRideNotification";
import ImportIncomingRideLeftNotification from "./IncomingRideLeftNotification";
import ImportOnGoingRideStatusNotification from "./OnGoingRideStatusNotification";
import ImportOnGoingRidePatientStatusNotification from "./OnGoingRidePatientStatusNotification";
import ImportIncomingRideSamuNotification from "./IncomingRideSamuNotification";
import ImportRideFilesUpdateNotification from "./RideFilesUpdateNotification";

export const IncomingRideNotification = ImportIncomingRideNotification;
export const IncomingRideLeftNotification = ImportIncomingRideLeftNotification;
export const OnGoingRideStatusNotification =
  ImportOnGoingRideStatusNotification;
export const OnGoingRidePatientStatusNotification =
  ImportOnGoingRidePatientStatusNotification;
export const IncomingRideSamuNotification = ImportIncomingRideSamuNotification;
export const RideFilesUpdateNotification = ImportRideFilesUpdateNotification;
