import { Subscription } from "@apollo/client/react/components";
import gql from "graphql-tag";

const TRANSPORTER_RIDE_FILES_UPDATED_SUBSCRIPTION = gql`
  subscription {
    TransporterRideFilesUpdatedSubscription {
      _id
      status
      rideNumber
      files {
        name
        size
        path
        extension
      }
    }
  }
`;

const RideFilesUpdateNotification = ({
  ongoingRideFilesUpdated,
  historyRideFilesUpdated,
  showNotification,
}) => {
  function onSubscriptionData(ride) {
    if (ride !== undefined) {
      const isCompletedOrCanceled = ["completed", "canceled"].includes(
        ride?.status
      );
      if (isCompletedOrCanceled) historyRideFilesUpdated(ride);
      else ongoingRideFilesUpdated(ride);

      showNotification({
        page: "/none",
        message: `Les fichiers relatifs au transport${
          isCompletedOrCanceled ? "" : " en cours"
        } ${ride.rideNumber} ont été mis à jour.`,
        type: "info",
        readed: false,
      });
    }
  }

  return (
    <Subscription
      subscription={TRANSPORTER_RIDE_FILES_UPDATED_SUBSCRIPTION}
      onSubscriptionData={({ subscriptionData }) => {
        onSubscriptionData(
          subscriptionData.data.TransporterRideFilesUpdatedSubscription
        );
      }}
    >
      {({ data = {}, loading, error }) => null}
    </Subscription>
  );
};

export default RideFilesUpdateNotification;
