import { Component } from "react";
import { Box, Center, HStack, Modal, Toast, VStack } from "native-base";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { FloatingInput, Button, Title } from "components";
import { Text } from "components/Text";

const INITIAL_STATE = {
  firstname: "",
  lastname: "",
  avatar: "",
  phone: "",
  password: "",
  errors: [],
};

class UserFormModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };

    this.onPropertyChanged = this.onPropertyChanged.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.resetState = this.resetState.bind(this);
    this.cancelAction = this.cancelAction.bind(this);
    this.validationForm = this.validationForm.bind(this);
    this.callbackSubmitForm = this.callbackSubmitForm.bind(this);
  }

  onPropertyChanged = (properiety) => {
    this.setState(Object.assign({}, this.state, { ...properiety }));
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.onSubmitForm();
    }
  };

  resetState() {
    this.setState({
      ...INITIAL_STATE,
    });
  }

  cancelAction() {
    this.resetState();
    this.props.onClose();
  }

  validationForm() {
    const { firstname, lastname, avatar, phone, password } = this.state;
    const { userType } = this.props;
    let errors = [];
    if (!lastname) errors.push("lastname_required");
    if (!firstname) errors.push("firstname_required");
    if (userType == "DEA") {
      // test phone regex 10 digits only and start with 0
      if (!phone) errors.push("phone_required");
      else if (!/^(0)[0-9]{9}$/.test(phone)) errors.push("phone_invalid");
      if (!password) errors.push("password_required");
    }
    this.setState({ errors });
    return errors.length === 0;
  }

  callbackSubmitForm(err = false) {
    if (!err) this.resetState();
    Toast.show({
      placement: "top-right",
      variant: "left-accent",
      render: () => {
        return (
          <Box
            mr="8"
            bg={!err ? "success" : "danger"}
            px="12px"
            py="3px"
            borderRadius="10px"
          >
            <Center minHeight="8">
              <Text color="ligth">
                {`Le compte ${
                  this.props.userType === "DEA" ? "DEA" : "auxiliaire"
                } ` +
                  (!err ? "a été créé avec succès" : "n'a pas pu être créé")}
              </Text>
            </Center>
          </Box>
        );
      },
    });
  }

  onSubmitForm() {
    const { userType } = this.props;
    if (!this.validationForm()) return;
    this.props.onConfirm({ ...this.state, userType }, this.callbackSubmitForm);
  }

  render() {
    const { t, i18n, userType } = this.props;
    const { firstname, lastname, avatar, phone, password, errors } = this.state;
    const style = { fontFamily: "Lato", fontSize: "14px" };

    return (
      <Modal isOpen={this.props.isOpen} onClose={this.onClose}>
        <Modal.Content maxWidth="517px" px="" py="41px">
          <Modal.Body alignItems="center">
            <VStack space="30px">
              <VStack space="2">
                <Title fontSize="36px" fontWeight="400" textAlign="center">
                  {t(`addUserPopUp.add_user_popup_title_headline.${userType}`, {
                    lng: i18n.language,
                  }).toUpperCase()}
                </Title>

                <VStack w="300px">
                  {errors.map((error) => (
                    <Text
                      key={error}
                      color="danger"
                      fontSize="14px"
                      fontWeight="400"
                      fontFamily="Lato"
                    >
                      -{" "}
                      {t(`addUserPopUp.errors.${error}`, {
                        lng: i18n.language,
                      })}
                    </Text>
                  ))}
                </VStack>
              </VStack>

              {userType == "DEA" ? (
                <VStack space="25px">
                  <FloatingInput
                    inputType="text"
                    label={t(`addUserPopUp.add_user_popup_lastname_label`, {
                      lng: i18n.language,
                    }).toUpperCase()}
                    isRequired={true}
                    {...style}
                    onChange={(e) =>
                      this.onPropertyChanged({ lastname: e.target.value })
                    }
                    onKeyPress={this.handleKeyPress}
                    error={errors.includes("lastname_required")}
                    value={lastname}
                  />
                  <FloatingInput
                    inputType="text"
                    label={t(`addUserPopUp.add_user_popup_firstname_label`, {
                      lng: i18n.language,
                    }).toUpperCase()}
                    isRequired={true}
                    {...style}
                    onChange={(e) =>
                      this.onPropertyChanged({ firstname: e.target.value })
                    }
                    onKeyPress={this.handleKeyPress}
                    error={errors.includes("firstname_required")}
                    value={firstname}
                  />
                  <FloatingInput
                    inputType="text"
                    label={t(`addUserPopUp.add_user_popup_phone_label`, {
                      lng: i18n.language,
                    }).toUpperCase()}
                    isRequired={true}
                    autoComplete={"off"}
                    {...style}
                    onChange={(e) =>
                      this.onPropertyChanged({ phone: e.target.value })
                    }
                    onKeyPress={this.handleKeyPress}
                    error={
                      errors.includes("phone_required") ||
                      errors.includes("phone_invalid")
                    }
                    value={phone}
                  />
                  <FloatingInput
                    inputType="password"
                    label={t(`addUserPopUp.add_user_popup_password_label`, {
                      lng: i18n.language,
                    }).toUpperCase()}
                    isRequired={true}
                    autoComplete={"new-password"}
                    {...style}
                    onChange={(e) =>
                      this.onPropertyChanged({ password: e.target.value })
                    }
                    onKeyPress={this.handleKeyPress}
                    error={errors.includes("password_required")}
                    value={password}
                  />
                </VStack>
              ) : (
                <VStack space="25px">
                  <FloatingInput
                    inputType="text"
                    label={t(`addUserPopUp.add_user_popup_lastname_label`, {
                      lng: i18n.language,
                    }).toUpperCase()}
                    isRequired={true}
                    {...style}
                    onChange={(e) =>
                      this.onPropertyChanged({ lastname: e.target.value })
                    }
                    onKeyPress={this.handleKeyPress}
                    value={lastname}
                  />
                  <FloatingInput
                    inputType="text"
                    label={t(`addUserPopUp.add_user_popup_firstname_label`, {
                      lng: i18n.language,
                    }).toUpperCase()}
                    isRequired={true}
                    {...style}
                    onChange={(e) =>
                      this.onPropertyChanged({ firstname: e.target.value })
                    }
                    onKeyPress={this.handleKeyPress}
                    value={firstname}
                  />
                </VStack>
              )}

              <HStack justifyContent="end" space="11px" alignItems="center">
                <Button
                  minWidth="140px"
                  third
                  borderWidth="1px"
                  px="36px"
                  py="8px"
                  onPress={this.cancelAction}
                >
                  {t(`button.cancel`, { lng: i18n.language }).toUpperCase()}
                </Button>

                <Button
                  minWidth="140px"
                  borderWidth="0px"
                  px="36px"
                  py="8px"
                  onPress={this.onSubmitForm}
                >
                  {t(`button.valid`, { lng: i18n.language }).toUpperCase()}
                </Button>
              </HStack>
            </VStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withTranslation(["Private", "Common"], { wait: true })(
  UserFormModal
);
