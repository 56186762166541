import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getListOfPartnersRequest: null,
  getListOfPartnersSuccess: ["partners"],
  getListOfPartnersFailure: ["error"],
});

export const PublicTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  partners: [],
  action: {
    loading: false,
    error: null,
  },
});

/* ------------- Reducers ------------- */
export const getListOfPartnersRequest = (state, action) => {
  return Immutable.setIn(state, ["action"], { loading: true, error: null });
};

export const getListOfPartnersSuccess = (state, action) => {
  let nextState = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextState, ["partners"], action.partners);
};

export const getListOfPartnersFailure = (state, action) => {
  return Immutable.setIn(state, ["action"], {
    error: action.error,
    loading: false,
  });
};
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_LIST_OF_PARTNERS_REQUEST]: getListOfPartnersRequest,
  [Types.GET_LIST_OF_PARTNERS_SUCCESS]: getListOfPartnersSuccess,
  [Types.GET_LIST_OF_PARTNERS_FAILURE]: getListOfPartnersFailure,
});
