import { Component } from "react";
import { HStack, Modal } from "native-base";
import { withTranslation } from "react-i18next";
import { Button, Text } from "components";

class RefuseSerieModal extends Component {
  constructor(props) {
    super(props);

    this.onConfirmAction = this.onConfirmAction.bind(this);
    this.onCancelAction = this.onCancelAction.bind(this);
  }

  onCancelAction() {
    this.props.onClose();
  }

  onConfirmAction() {
    const { selectedSerie, transporterRefuseIncomingSerieRequest } = this.props;
    transporterRefuseIncomingSerieRequest(selectedSerie);
    this.onCancelAction();
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Modal {...this.props}>
        <Modal.Content maxWidth="588px" maxHeight="545px">
          {/* <Modal.CloseButton /> */}
          <Modal.Header alignItems="center" borderBottomWidth="0px">
            <Text fontSize="24px" fontWeight="900" lineHeight="29px">
              {/* {t(`assignVehicleModal.title`, { lng: i18n.language })} */}
              Refuser une série
            </Text>
          </Modal.Header>
          <Modal.Body alignItems="center">
            <Text>Etes vous sur de vouloir refuser la série ?</Text>
          </Modal.Body>
          <Modal.Footer bg="light">
            <HStack
              space="2"
              my="4"
              alignItems="center"
              justifyContent="center"
              w="full"
            >
              <Button
                minWidth="140px"
                third
                borderWidth="1px"
                px="36px"
                py="8px"
                onPress={this.onCancelAction}
              >
                {t(`button.cancel`, { lng: i18n.language }).toUpperCase()}
              </Button>
              <Button
                minWidth="140px"
                borderWidth="0px"
                px="36px"
                py="8px"
                onPress={this.onConfirmAction}
              >
                {t(`button.valid`, { lng: i18n.language }).toUpperCase()}
              </Button>
            </HStack>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withTranslation(["Private", "Common"], { wait: true })(
  RefuseSerieModal
);
