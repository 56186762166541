import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import _ from "lodash";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  transporterUsersResetState: null,

  transporterUsersRequest: ["page", "limit", "filter"],
  transporterUsersRequestSuccess: ["users"],
  transporterUsersRequestFailure: ["error"],

  transporterCreateUserRequest: ["user", "callback"],
  transporterCreateUserRequestSuccess: ["user"],
  transporterCreateUserRequestFailure: ["error"],

  // transporterModifyUserRequest: ["user", "id", "callback"],
  // transporterModifyUserRequestSuccess: null,
  // transporterModifyUserRequestFailure: ["error"],

  transporterDeleteUserRequest: ["vehiculeId"],
  transporterDeleteUserRequestSuccess: null,
  transporterDeleteUserRequestFailure: ["error"],

  transporterToggleActivateUserRequest: ["userId", "userType", "callback"],
  transporterToggleActivateUserSuccess: ["user"],
  transporterToggleActivateUserFailure: ["error"],
});

export const TransporterUserTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
const INITIAL_STATE_ACTION = {
  loading: false,
  error: "",
};

export const INITIAL_STATE = Immutable({
  users: {
    page: 1,
    limit: 10,
    users: [],
  },
  action: INITIAL_STATE_ACTION,
});

/* ------------- Reducers ------------- */

export const transporterUsersResetState = (state, action) => {
  return INITIAL_STATE;
};

export const transporterUsersRequest = (state, action) => {
  return Immutable.setIn(state, ["action"], INITIAL_STATE_ACTION);
};

export const transporterUsersRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["users"], action.users);
};

export const transporterUsersRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

/* ------add user -----------------------*/

export const transporterCreateUserRequest = transporterUsersRequest;

export const transporterCreateUserRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const transporterCreateUserRequestFailure =
  transporterUsersRequestFailure;

// export const transporterModifyUserRequest = (state, action) => {
//     let nextSate = Immutable.setIn(state, ["action", 'loading'], true);
//     return Immutable.setIn(nextSate, ["action", 'error'], "");
// }

// export const transporterModifyUserRequestSuccess = (state, action) => {
//     return Immutable.setIn(state, ["action", 'loading'], false);

// }

// export const transporterModifyUserRequestFailure = (state, action) => {
//     let nextSate = Immutable.setIn(state, ["action", 'loading'], false);
//     return Immutable.setIn(nextSate, ["action", 'error'], action.error);
// }

export const transporterDeleteUserRequest = transporterUsersRequest;

export const transporterDeleteUserRequestSuccess =
  transporterCreateUserRequestSuccess;

export const transporterDeleteUserRequestFailure =
  transporterUsersRequestFailure;

// Toggle Activate User
export const transporterToggleActivateUserRequest = transporterUsersRequest;

export const transporterToggleActivateUserSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action"], INITIAL_STATE_ACTION);
  const { users } = state.users.asMutable({ deep: true });
  const { user } = action;
  const index = _.findIndex(users, { _id: user._id });
  users[index] = user;
  return Immutable.setIn(nextSate, ["users", "users"], users);
};

export const transporterToggleActivateUserFailure =
  transporterUsersRequestFailure;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.TRANSPORTER_USERS_RESET_STATE]: transporterUsersResetState,
  [Types.TRANSPORTER_USERS_REQUEST]: transporterUsersRequest,
  [Types.TRANSPORTER_USERS_REQUEST_SUCCESS]: transporterUsersRequestSuccess,
  [Types.TRANSPORTER_USERS_REQUEST_FAILURE]: transporterUsersRequestFailure,

  [Types.TRANSPORTER_CREATE_USER_REQUEST]: transporterCreateUserRequest,
  [Types.TRANSPORTER_CREATE_USER_REQUEST_SUCCESS]:
    transporterCreateUserRequestSuccess,
  [Types.TRANSPORTER_CREATE_USER_REQUEST_FAILURE]:
    transporterCreateUserRequestFailure,

  // [Types.TRANSPORTER_MODIFY_USER_REQUEST]: transporterModifyUserRequest,
  // [Types.TRANSPORTER_MODIFY_USER_REQUEST_SUCCESS]: transporterModifyUserRequestSuccess,
  // [Types.TRANSPORTER_MODIFY_USER_REQUEST_FAILURE]: transporterModifyUserRequestFailure,

  [Types.TRANSPORTER_DELETE_USER_REQUEST]: transporterDeleteUserRequest,
  [Types.TRANSPORTER_DELETE_USER_REQUEST_SUCCESS]:
    transporterDeleteUserRequestSuccess,
  [Types.TRANSPORTER_DELETE_USER_REQUEST_FAILURE]:
    transporterDeleteUserRequestFailure,

  [Types.TRANSPORTER_TOGGLE_ACTIVATE_USER_REQUEST]:
    transporterToggleActivateUserRequest,
  [Types.TRANSPORTER_TOGGLE_ACTIVATE_USER_SUCCESS]:
    transporterToggleActivateUserSuccess,
  [Types.TRANSPORTER_TOGGLE_ACTIVATE_USER_FAILURE]:
    transporterToggleActivateUserFailure,
});
