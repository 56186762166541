import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import _ from "lodash";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  transporterIncomingSeriesResetState: null,
  transporterIncomingSeriesRequest: ["page", "limit"],
  transporterIncomingSeriesRequestSuccess: ["series"],
  transporterIncomingSeriesRequestFailure: ["error"],

  transporterAcceptProposedSerieRequest: ["serie", "vehicle", "callback"],
  transporterAcceptProposedSerieRequestSuccess: ["serie"],
  transporterAcceptProposedSerieRequestFailure: ["error"],
  transporterProposedSerieRequestNotAvailable: ["serie"],

  transporterDidReceiveSerie: ["serie"],
  transporterDidSerieLeft: ["serie"],
  transporterSerieGotByOther: ["serie"],

  transporterRefuseIncomingSerieRequest: ["serie"],
  transporterRefuseIncomingSerieSuccess: ["serie"],
  transporterRefuseIncomingSerieFailure: ["error"],

  transporterClearError: null,
});

export const TransporterIncomingSerieTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  series: {
    page: 1,
    limit: 10,
    totalPages: 0,
    series: [],
  },
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

/// GENERAL
export const transporterIncomingSeriesResetState = (state, action) => {
  return INITIAL_STATE;
};

export const transporterIncomingSeriesRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const transporterIncomingSeriesRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const transporterIncomingSeriesRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  if (action.series.page == 1)
    //first page
    return Immutable.setIn(nextSate, ["series"], action.series);
  else {
    //apend next pages
    let prevList = state.series.series.asMutable({ deep: true });
    const { series, ...rest } = action.series;

    return Immutable.setIn(nextSate, ["series"], {
      ...rest,
      series: [...prevList, ...series],
    });
  }
};

export const transporterDidReceiveSerie = (state, action) => {
  let serieRequests = state.series.asMutable({ deep: true });
  serieRequests = [action.serie, ...serieRequests.series];
  return Immutable.setIn(state, ["series", "series"], serieRequests);
};

export const transporterAcceptProposedSerieRequest = (state, action) => {
  let nextState = Immutable.setIn(state, ["action", "error"], "");
  return Immutable.setIn(nextState, ["action", "loading"], true);
};

export const transporterAcceptProposedSerieRequestFailure = (state, action) => {
  let nextState = Immutable.setIn(state, ["action", "error"], action.error);
  return Immutable.setIn(nextState, ["action", "loading"], false);
};
export const transporterAcceptProposedSerieRequestSuccess = (state, action) => {
  let serieRequests = state.series.asMutable({ deep: true });
  let { series } = serieRequests;

  series = _.reduce(
    series,
    (acc, s) => {
      if (s._id !== action.serie._id) return [...acc, s];
      return acc;
    },
    []
  );

  let nextState = Immutable.setIn(state, ["series", "series"], series);
  return Immutable.setIn(nextState, ["action", "loading"], false);
};

export const transporterProposedSerieRequestNotAvailable =
  transporterAcceptProposedSerieRequestSuccess;

export const transporterDidSerieLeft =
  transporterAcceptProposedSerieRequestSuccess;

export const transporterSerieGotByOther = (state, action) => {
  let serieRequests = state.series.asMutable({ deep: true });
  let { series } = serieRequests;
  series = _.reduce(
    series,
    (acc, s) => {
      if (s._id === action.serie._id) {
        s.status = "pending";
      }
      return [...acc, s];
    },
    []
  );

  let nextState = Immutable.setIn(state, ["series", "series"], series);
  return Immutable.setIn(nextState, ["action", "loading"], false);
};

export const transporterRefuseIncomingSerieRequest = (state, action) => {
  let nextState = Immutable.setIn(state, ["action", "error"], "");
  return Immutable.setIn(nextState, ["action", "loading"], true);
};

export const transporterRefuseIncomingSerieSuccess = (state, action) => {
  let serieRequests = state.series.asMutable({ deep: true });
  let { series } = serieRequests;
  const _series = series.filter((s) => s._id !== action.serie._id);
  let nextState = Immutable.setIn(state, ["series", "series"], _series);
  return Immutable.setIn(nextState, ["action", "loading"], false);
};

export const transporterRefuseIncomingSerieFailure = (state, action) => {
  let nextState = Immutable.setIn(state, ["action", "error"], action.error);
  return Immutable.setIn(nextState, ["action", "loading"], false);
};

export const transporterClearError = (state, action) => {
  return Immutable.setIn(state, ["action", "error"], "");
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.TRANSPORTER_INCOMING_SERIES_RESET_STATE]:
    transporterIncomingSeriesResetState,
  [Types.TRANSPORTER_INCOMING_SERIES_REQUEST]: transporterIncomingSeriesRequest,
  [Types.TRANSPORTER_INCOMING_SERIES_REQUEST_SUCCESS]:
    transporterIncomingSeriesRequestSuccess,
  [Types.TRANSPORTER_INCOMING_SERIES_REQUEST_FAILURE]:
    transporterIncomingSeriesRequestFailure,

  [Types.TRANSPORTER_ACCEPT_PROPOSED_SERIE_REQUEST]:
    transporterAcceptProposedSerieRequest,
  [Types.TRANSPORTER_ACCEPT_PROPOSED_SERIE_REQUEST_SUCCESS]:
    transporterAcceptProposedSerieRequestSuccess,
  [Types.TRANSPORTER_PROPOSED_SERIE_REQUEST_NOT_AVAILABLE]:
    transporterProposedSerieRequestNotAvailable,
  [Types.TRANSPORTER_ACCEPT_PROPOSED_SERIE_REQUEST_FAILURE]:
    transporterAcceptProposedSerieRequestFailure,

  [Types.TRANSPORTER_DID_RECEIVE_SERIE]: transporterDidReceiveSerie,
  [Types.TRANSPORTER_DID_SERIE_LEFT]: transporterDidSerieLeft,
  [Types.TRANSPORTER_SERIE_GOT_BY_OTHER]: transporterSerieGotByOther,

  [Types.TRANSPORTER_REFUSE_INCOMING_SERIE_REQUEST]:
    transporterRefuseIncomingSerieRequest,
  [Types.TRANSPORTER_REFUSE_INCOMING_SERIE_SUCCESS]:
    transporterRefuseIncomingSerieSuccess,
  [Types.TRANSPORTER_REFUSE_INCOMING_SERIE_FAILURE]:
    transporterRefuseIncomingSerieFailure,

  [Types.TRANSPORTER_CLEAR_ERROR]: transporterClearError,
});
