import { Component } from "react";
import { CheckIcon, HStack, Modal, Select, VStack } from "native-base";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { FloatingInput, Button, Text, Title } from "components";
import { VEHICLE_CATEGORIES } from "global";

const INITIAL_STATE = {
  make: "",
  plate: "",
  model: "",
  category: VEHICLE_CATEGORIES[0],
  phone: "",
  color: "Blanche",
};

class VehicleFormModal extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.onPropertyChanged = this.onPropertyChanged.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.resetState = this.resetState.bind(this);
    this.cancelAction = this.cancelAction.bind(this);
  }

  onPropertyChanged = (properiety) => {
    this.setState(Object.assign({}, this.state, { ...properiety }));
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.onSubmitForm();
    }
  };

  resetState() {
    this.setState({
      ...INITIAL_STATE,
    });
  }

  cancelAction() {
    this.resetState();
    this.props.onClose();
  }

  onSubmitForm() {
    const { plate, make, model, category, color, phone } = this.state;
    if (
      plate !== "" &&
      make !== "" &&
      model !== "" &&
      category !== "" &&
      color !== "" &&
      phone !== ""
    ) {
      this.resetState();
      this.props.onConfirm(this.state);
    }
  }

  render() {
    const { t, i18n } = this.props;
    const { plate, model, make, color, category, phone } = this.state;
    let formNotCompleted = false;

    if (
      plate === "" ||
      make === "" ||
      model === "" ||
      category === "" ||
      color === "" ||
      phone === ""
    ) {
      formNotCompleted = true;
    }
    const style = { fontFamily: "Lato", fontSize: "14px" };

    return (
      <Modal {...this.props}>
        <Modal.Content maxWidth="517px" px="" py="41px">
          <Modal.Body alignItems="center">
            <VStack space="30px">
              <VStack space="2">
                <Title fontSize="36px" fontWeight="400">
                  {t("addVehiclePopUp.add_vehicle_popup_title_headline", {
                    lng: i18n.language,
                  })}
                </Title>

                <Text fontSize="14px" color="default" textAlign="center">
                  {t("addVehiclePopUp.add_vehicle_popup_subtitle_headline", {
                    lng: i18n.language,
                  })}
                </Text>
              </VStack>

              <VStack space="25px">
                {/* CATEGORY */}
                <Select
                  w="full"
                  borderWidth="2px"
                  borderRadius="4px"
                  borderColor="gray"
                  color="default"
                  fontFamily="Lato"
                  accessibilityLabel="Choose category"
                  placeholder={t(
                    "addVehiclePopUp.add_vehicle_popup_category_label",
                    { lng: i18n.language }
                  )}
                  _selectedItem={{
                    bg: "primary.600",
                    endIcon: <CheckIcon size={5} />,
                  }}
                  isRequired={true}
                  {...style}
                  selectedValue={category}
                  onValueChange={(itemValue) =>
                    this.onPropertyChanged({ category: itemValue })
                  }
                >
                  {VEHICLE_CATEGORIES.map((value) => (
                    <Select.Item
                      {...style}
                      label={value}
                      value={value}
                      key={value}
                    />
                  ))}
                </Select>

                <FloatingInput
                  inputType="text"
                  label={t("addVehiclePopUp.add_vehicle_popup_plate_label", {
                    lng: i18n.language,
                  })}
                  isRequired={true}
                  {...style}
                  onChange={(e) =>
                    this.onPropertyChanged({ plate: e.target.value })
                  }
                  onKeyPress={this.handleKeyPress}
                  value={plate}
                />
                <FloatingInput
                  inputType="text"
                  label={t("addVehiclePopUp.add_vehicle_popup_model_label", {
                    lng: i18n.language,
                  })}
                  isRequired={true}
                  {...style}
                  onChange={(e) =>
                    this.onPropertyChanged({ model: e.target.value })
                  }
                  onKeyPress={this.handleKeyPress}
                  value={model}
                />
                <FloatingInput
                  inputType="text"
                  label={t("addVehiclePopUp.add_vehicle_popup_make_label", {
                    lng: i18n.language,
                  })}
                  isRequired={true}
                  {...style}
                  onChange={(e) =>
                    this.onPropertyChanged({ make: e.target.value })
                  }
                  onKeyPress={this.handleKeyPress}
                  value={make}
                />
                <FloatingInput
                  inputType="text"
                  label={t("addVehiclePopUp.add_vehicle_popup_color_label", {
                    lng: i18n.language,
                  })}
                  isRequired={true}
                  {...style}
                  onChange={(e) =>
                    this.onPropertyChanged({ color: e.target.value })
                  }
                  onKeyPress={this.handleKeyPress}
                  value={color}
                />
                <FloatingInput
                  inputType="text"
                  label={t("addVehiclePopUp.add_vehicle_popup_phone_label", {
                    lng: i18n.language,
                  })}
                  isRequired={true}
                  {...style}
                  onChange={(e) =>
                    this.onPropertyChanged({ phone: e.target.value })
                  }
                  onKeyPress={this.handleKeyPress}
                  value={phone}
                />
              </VStack>

              <HStack justifyContent="end" space="11px" alignItems="center">
                <Button
                  minWidth="140px"
                  third
                  borderWidth="1px"
                  px="36px"
                  py="8px"
                  onPress={this.cancelAction}
                >
                  {t(`button.cancel`, { lng: i18n.language }).toUpperCase()}
                </Button>

                <Button
                  minWidth="140px"
                  isDisabled={formNotCompleted}
                  borderWidth="0px"
                  px="36px"
                  py="8px"
                  onPress={this.onSubmitForm}
                >
                  {t(`button.valid`, { lng: i18n.language }).toUpperCase()}
                </Button>
              </HStack>
            </VStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withTranslation(["Private", "Common"], { wait: true })(
  VehicleFormModal
);
