import { put, takeLatest } from "redux-saga/effects";
import AccountActions, { AccountTypes } from "reducers/account";
import ongoingRidesActions from "reducers/transporter/transporter-ongoing-rides";
import rideHistoryActions from "reducers/transporter/transporter-rides-history";
import incomingRideActions from "reducers/ride/transporter-incoming-rides";
import vehilcesActions from "reducers/transporter/transporter-vehicle";
import incomingSerieActions from "reducers/serie/transporter-incoming-series";
import rideDriverActions from "reducers/ride/driver-location";
import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* resetAllState() {
  yield put(ongoingRidesActions.onGoingRidesResetState());
  yield put(rideHistoryActions.transporterRideHistoryResetState());
  yield put(incomingRideActions.transporterIncomingRidesResetState());
  yield put(vehilcesActions.transporterVehiclesResetState());
  yield put(incomingSerieActions.transporterIncomingSeriesResetState());
  yield put(rideDriverActions.transporterRideDriverResetState());
  yield put(rideDriverActions.transporterRideDriverResetState());
}

function* signinAsyncRequest({ email, password }) {
  const query = `
    query { 
      transporterUserLogin( 
        email:"${email}",
        password:"${password}"
      )
    }
  `;
  try {
    const data = yield graphQLClient().request(query);
    const token = data.transporterUserLogin;
    yield resetAllState();
    yield put(AccountActions.signinRequestSuccess(token));
  } catch ({ response }) {
    const { errors = [{ message: "" }] } = response;
    yield put(AccountActions.signinRequestFailure(errors[0].message));
  }
}

function* signoutAsyncRequest({ callback }) {
  yield resetAllState();
  if (_.isFunction()) callback();
}

function* forgotPasswordAsyncRequest({ email, callback }) {
  const query = `
    mutation {
      TransporterRequestResetPassword(email:"${email}")
    }
  `;
  try {
    yield graphQLClient().request(query);
    yield put(AccountActions.forgotPasswordSuccess(callback));
    callback();
  } catch ({ response }) {
    const { errors = [{ message: "" }] } = response;
    yield put(AccountActions.forgotPasswordFailure(errors[0].message));
  }
}

function* resetPasswordAsyncRequest({ token, password, callback }) {
  const query = `
    mutation {
      TransporterResetPassword(token:"${token}", password:"${password}")
    }
  `;
  try {
    yield graphQLClient().request(query);
    yield put(AccountActions.resetPasswordSuccess(callback));
    callback();
  } catch ({ response }) {
    const { errors = [{ message: "" }] } = response;
    yield put(AccountActions.resetPasswordFailure(errors[0].message));
  }
}

function* registerAsyncRequest({ user, company, callback }) {
  const userQuery = `
    {
      lastname: "${user.lastname}",
      firstname: "${user.firstname}",
      email: "${user.email}",
      password: "${user.password}"
    }
  `;
  const companyQuery = `
    {
      siret: "${company.siret}",
      name: "${company.name}",
      email: "${company.email}",
      partnerId: "${company.partnerId}",
      phones: {
        office: "${company.phones}"
      },
      address: {
        address: "${company.address.address}",
        longitude: ${company.address.longitude},
        latitude: ${company.address.latitude}
      }
    }
  `;
  const query = `
    mutation {
      TransporterRegisterUserAndCompany(
        user: ${userQuery},
        company: ${companyQuery}
      )
    }
  `;
  try {
    yield graphQLClient().request(query);
    yield put(AccountActions.registerSuccess());
    if (_.isFunction(callback)) callback();
  } catch (error) {
    yield put(AccountActions.registerFailure(error));
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(AccountTypes.SIGNIN_REQUEST, signinAsyncRequest),
  takeLatest(AccountTypes.SIGNOUT_REQUEST, signoutAsyncRequest),
  takeLatest(AccountTypes.FORGOT_PASSWORD_REQUEST, forgotPasswordAsyncRequest),
  takeLatest(AccountTypes.RESET_PASSWORD_REQUEST, resetPasswordAsyncRequest),
  takeLatest(AccountTypes.REGISTER_REQUEST, registerAsyncRequest),
];

/* ------------- Connect Types To Sagas ------------- */
