import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import { Center } from "native-base";

import noDataIcon from "assets/img/no_data.svg";
import { InComingSerieCard, Text } from "components";

function Series({ t, i18n, fetchData, serie, downloadFiles }) {
  const { error, loading } = serie.action;

  return (
    <>
      {/* No Data */}
      {serie.series.series.length == 0 && (
        <Center flex={1}>
          <img src={noDataIcon} />
          <Text fontSize="18">
            {t("RidesHistoryPage.message_when_no_data")}
          </Text>
        </Center>
      )}

      <div
        id="scrollableDivHistory"
        style={{
          overflow: "auto",
          paddingRight: "10%",
        }}
      >
        <InfiniteScroll
          dataLength={serie.series.series.length} //This is important field to render the next data
          next={fetchData}
          hasMore={true}
          scrollableTarget="scrollableDivHistory"
          loader={
            <Loader
              type="ThreeDots"
              color="#00BFFF"
              height={100}
              width={100}
              visible={loading}
            />
          }
        >
          {serie.series.series.map((serie, key) => (
            <InComingSerieCard
              {...{ t, i18n }}
              serie={serie}
              key={key}
              mb="10px"
              downloadFiles={downloadFiles}
            />
          ))}
        </InfiniteScroll>
      </div>
    </>
  );
}

export default Series;
