import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import _ from "lodash";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  transporterRideDriverResetState: null,
  transporterGetRideDriverLocationRequest: ["driverId"],
  transporterGetRideDriverLocationRequestSuccess: ["coords"],
  transporterGetRideDriverLocationRequestFailure: ["error"],

  transporterGetRidesWaitingTimeRequest: ["ridesId"],
  transporterGetRidesWaitingTimeRequestSuccess: ["waitingTime"],
  transporterGetRidesWaitingTimeRequestFailure: ["error"],
});

export const DriverLocationTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  waitingTime: [],
  coords: {
    driverId: "",
    longitude: 0,
    latitude: 0,
  },
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

export const transporterRideDriverResetState = (state, action) => {
  return INITIAL_STATE;
};

export const transporterGetRidesWaitingTimeRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const transporterGetRidesWaitingTimeRequestSuccess = (state, action) => {
  const { waitingTime } = state.asMutable({ deep: true });
  const s = _.reduce(
    waitingTime,
    (acc, el) => {
      const oldExist = _.filter(
        action.waitingTime,
        (e) => el.rideId == e.rideId
      );
      return _.isEmpty(oldExist) ? [...acc, el] : acc;
    },
    []
  );

  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(
    nextSate,
    ["waitingTime"],
    [...s, ...action.waitingTime]
  );
};

export const transporterGetRidesWaitingTimeRequestFailure = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const transporterGetRideDriverLocationRequestSuccess = (
  state,
  action
) => {
  const { coords } = state.asMutable({ deep: true });
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  if (_.isNull(action.coords.longitude) || _.isNull(action.coords.latitude))
    return nextSate;

  return Immutable.setIn(nextSate, ["coords"], action.coords);
};

export const transporterGetRideDriverLocationRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const transporterGetRideDriverLocationRequestFailure = (
  state,
  action
) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.TRANSPORTER_RIDE_DRIVER_RESET_STATE]: transporterRideDriverResetState,
  [Types.TRANSPORTER_GET_RIDE_DRIVER_LOCATION_REQUEST]:
    transporterGetRideDriverLocationRequest,
  [Types.TRANSPORTER_GET_RIDE_DRIVER_LOCATION_REQUEST_SUCCESS]:
    transporterGetRideDriverLocationRequestSuccess,
  [Types.TRANSPORTER_GET_RIDE_DRIVER_LOCATION_REQUEST_FAILURE]:
    transporterGetRideDriverLocationRequestFailure,

  [Types.TRANSPORTER_GET_RIDES_WAITING_TIME_REQUEST]:
    transporterGetRidesWaitingTimeRequest,
  [Types.TRANSPORTER_GET_RIDES_WAITING_TIME_REQUEST_SUCCESS]:
    transporterGetRidesWaitingTimeRequestSuccess,
  [Types.TRANSPORTER_GET_RIDES_WAITING_TIME_REQUEST_FAILURE]:
    transporterGetRidesWaitingTimeRequestFailure,
});
