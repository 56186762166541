import { Component } from "react";
import { HStack, Link, VStack } from "native-base";
import LoadingOverlay from "react-loading-overlay";
import resaamIcon from "assets/img/resaam-logo.svg";
import { Card, Text, Title, FloatingInput, Button } from "components";

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: process.env.NODE_ENV === "development" ? "admin@resaam.com" : "",
      password: process.env.NODE_ENV === "development" ? "admin" : "",
    };

    this.onPressLoginButton = this.onPressLoginButton.bind(this);
    this.onPropertyChanged = this.onPropertyChanged.bind(this);
  }

  componentDidMount() {
    this.props.signoutRequest();
  }

  onPropertyChanged = (properiety) => {
    this.setState(Object.assign({}, this.state, { ...properiety }));
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.onPressLoginButton();
    }
  };

  onPressLoginButton() {
    const { signinRequest } = this.props;
    const { email, password } = this.state;

    signinRequest(email, password);
  }

  render() {
    const { t, i18n } = this.props;

    const { action } = this.props.account;
    const { error = "", loading = false } = action;

    const { email, password } = this.state;
    return (
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          spinner: (base) => ({
            ...base,
            width: "100px",
            "& svg circle": {
              stroke: "#0096AC",
            },
          }),
        }}
      >
        <Card
          h="md"
          w="md"
          zIndex="10"
          borderRadius="50px"
          borderWidth="0px"
          shadow="login"
          alignItems="center"
          px="4em"
          py="3em"
          justifyContent="space-between"
        >
          <img className="logo resaam" width="180px" src={resaamIcon} />

          <Title>{t("LoginPage.head_title", { lng: i18n.language })}</Title>

          <Text w="333px" textAlign="center">
            {t("LoginPage.description", { lng: i18n.language })}
          </Text>

          {error && (
            <Text color="danger">
              {t("LoginPage.fail_login", { lng: i18n.language })}
            </Text>
          )}

          <FloatingInput
            inputType="email"
            label={t("LoginPage.email_label", { lng: i18n.language })}
            onChange={(e) => this.onPropertyChanged({ email: e.target.value })}
            onKeyPress={this.handleKeyPress}
            value={email}
          />

          <FloatingInput
            inputType="password"
            label={t("LoginPage.password_label", {
              lng: i18n.language,
            })}
            onChange={(e) =>
              this.onPropertyChanged({ password: e.target.value })
            }
            onKeyPress={this.handleKeyPress}
            value={password}
          />

          <HStack justifyContent="space-between" w="full" mt="2">
            <VStack space={1}>
              <Link href="/#/forgot">
                {t("LoginPage.forgot_password_button", { lng: i18n.language })}
              </Link>

              <Link href="/#/register">
                {t("LoginPage.register_button", { lng: i18n.language })}
              </Link>
            </VStack>

            <Button
              onPress={this.onPressLoginButton}
              third
              borderColor="primary"
              _text={{
                color: "primary",
                fontFamily: "Lato",
                fontSize: "14px",
                fontWeight: "700",
              }}
              px="11px"
              h="32px"
              alignItems="center"
            >
              {t("LoginPage.login_button", { lng: i18n.language })}
            </Button>
          </HStack>
        </Card>
      </LoadingOverlay>
    );
  }
}

export default LoginPage;
