import { Component } from "react";
import transporterVehiclesAction from "reducers/transporter/transporter-vehicle";
import { withTranslation } from "react-i18next";
import { Alert, HStack, ScrollView, Stack, View, VStack } from "native-base";
import { connect } from "react-redux";
import {
  Title,
  FloatingInput,
  NotificationMenu,
  Button,
  VehicleFormModal,
  VehicleCard,
} from "components";
import addVehicleIcon from "assets/img/add_vehicle.svg";

class TransporterVehicles extends Component {
  constructor(props) {
    super(props);

    this.onSearchVehicleFilter = this.onSearchVehicleFilter.bind(this);
    this.onPressShowFormButton = this.onPressShowFormButton.bind(this);
    this.onCancelFormPopUp = this.onCancelFormPopUp.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);

    this.state = {
      searchInputValue: "",
      showFormPopUp: false,
      selectedVehicle: null,
    };
  }

  onSearchVehicleFilter(e) {
    const { value } = e.target;
    this.setState({ searchInputValue: value });
    this.props.transporterVehiclesRequest(1, 10, value);
  }

  onPressShowFormButton(ride) {
    this.setState(
      Object.assign({}, this.state, {
        showFormPopUp: true,
        selectedVehicle: ride,
      })
    );
  }

  onSubmitForm(vehicle) {
    this.setState({ selectedVehicle: null });
    this.props.transporterCreateVehicleRequest(vehicle);
  }

  onCancelFormPopUp() {
    this.setState(
      Object.assign({}, this.state, {
        showFormPopUp: false,
        selectedVehicle: null,
      })
    );
  }

  render() {
    const {
      t,
      i18n,
      actionError,
      vehicle: { vehicles = [] },
    } = this.props;
    // const { error, loading } = actionError;
    const { showFormPopUp, selectedVehicle, searchInputValue } = this.state;

    return (
      <View w="full">
        <VStack space="5" ml="24" mr="12" h="full">
          <VStack space="5" h="48" mt="5">
            {/* {error !== "" && <Alert bsStyle="danger">
            <span>{error}</span>
          </Alert>} */}

            <Title>
              {t("vehiclesPage.vehicles_page_title_headline", {
                lng: i18n.language,
              })}
            </Title>

            {/* Notification menu */}
            <Stack alignItems="end">
              <NotificationMenu
                items={this.props.notifications}
                onReadedNotifications={this.props.onReadedNotifications}
              />
            </Stack>
            <HStack justifyContent="space-between" mr="20">
              {/* Search */}
              <FloatingInput
                inputType="search"
                label={t("searchBar", { lng: i18n.language })}
                onChange={this.onSearchVehicleFilter}
                // onKeyPress={this.handleKeyPress}
                value={searchInputValue}
              />

              {/* Localiser */}

              {/* Add vehicle */}
              <Button
                borderRadius="10px"
                py="11px"
                px="18px"
                rightIcon={<img src={addVehicleIcon} />}
                _text={{
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "ligth",
                }}
                onPress={this.onPressShowFormButton}
              >
                {t("vehiclesPage.vehicles_page_add_vehicle_button", {
                  lng: i18n.language,
                })}
              </Button>
            </HStack>
          </VStack>

          <ScrollView>
            <VStack>
              {vehicles.map((vehicle, key) => (
                <VehicleCard
                  mb="10px"
                  vehicle={vehicle}
                  transporterDeleteVehicleRequest={
                    this.props.transporterDeleteVehicleRequest
                  }
                  {...{ t, i18n }}
                  key={key}
                />
              ))}
            </VStack>
          </ScrollView>
        </VStack>

        {/* MODAL ADD VEHICLE */}
        <VehicleFormModal
          {...{ t, i18n }}
          vehicle={selectedVehicle}
          isOpen={showFormPopUp}
          onClose={this.onCancelFormPopUp}
          onConfirm={this.onSubmitForm}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // actionError: state.inComingRides.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    transporterCreateVehicleRequest: (...args) =>
      dispatch(
        transporterVehiclesAction.transporterCreateVehicleRequest(...args)
      ),
    transporterDeleteVehicleRequest: (...args) =>
      dispatch(
        transporterVehiclesAction.transporterDeleteVehicleRequest(...args)
      ),
  };
};

const vehiclesRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(TransporterVehicles);
export default withTranslation(["Private", "Common"], { wait: true })(
  vehiclesRedux
);
