import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import _ from "lodash";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  onGoingRidesResetState: null,
  onGoingRidesRequest: ["page", "limit", "filter"],
  onGoingRidesRequestSuccess: ["rides"],
  onGoingRidesRequestFailure: ["error"],

  transporterAssignVehicleForRideRequest: ["rideId", "vehicle", "callback"],
  transporterAssignVehicleForRideSuccess: ["ride"],
  transporterAssignVehicleForRideFailure: ["error"],

  onGoingRideStatusChanged: ["ride"],
  onGoingRidePatientStatusChanged: ["ride"],

  onGoingRidesNewRideRequest: ["ride"],
  onGoingRidesRemoveRide: ["ride"],

  onGoingRidesAddRideNeedAssign: ["rideId"],
  onGoingRidesRemoveRideNeedAssign: ["rideId"],

  ongoingRideFilesUpdated: ["ride"],
});

export const OngoingRideTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  rides: {
    page: 1,
    limit: 10,
    totalPages: 0,
    rides: [],
  },
  ridesNeedAssign: [],
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

/// GENERAL
export const onGoingRidesResetState = (state, action) => {
  return INITIAL_STATE;
};

export const onGoingRideStatusChanged = (state, action) => {
  const { ride } = action;
  let rides = state.rides.rides.asMutable({ deep: true });
  let _rides = _.map(rides, (r) => {
    if (ride._id === r._id) {
      return { ...r, ...ride };
    }
    return r;
  });
  return Immutable.setIn(state, ["rides", "rides"], _rides);
};

export const onGoingRidesRemoveRide = (state, action) => {
  const { ride } = action;
  let rides = state.rides.rides.asMutable({ deep: true });
  let _rides = rides.filter((r) => r._id !== ride._id);
  return Immutable.setIn(state, ["rides", "rides"], _rides);
};

export const onGoingRidePatientStatusChanged = (state, action) => {
  const { ride } = action;
  let rides = state.rides.rides.asMutable({ deep: true });
  let _rides = _.map(rides, (r) => {
    if (ride._id === r._id) {
      r.patient.status = ride.patient.status;
    }
    return r;
  });
  return Immutable.setIn(state, ["rides", "rides"], _rides);
};

export const onGoingRidesRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const onGoingRidesRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const onGoingRidesRequestSuccess = (state, action) => {
  const nextSate = Immutable.setIn(state, ["action", "loading"], false);

  if (action.rides.page == 1) {
    //first page
    return Immutable.setIn(nextSate, ["rides"], action.rides);
  }

  const prevList = state.rides.rides.asMutable({ deep: true }); //
  const { rides, ...rest } = action.rides; //

  return Immutable.setIn(nextSate, ["rides"], {
    ...rest,
    rides: [...prevList, ...rides],
  });
};

export const onGoingRidesNewRideRequest = (state, action) => {
  let rideRequests = state.rides.asMutable({ deep: true });
  rideRequests = [action.ride, ...rideRequests.rides];
  return Immutable.setIn(state, ["rides", "rides"], rideRequests);
};

export const transporterAssignVehicleForRideRequest = (state, action) => {
  let nextState = Immutable.setIn(state, ["action", "error"], "");
  return Immutable.setIn(nextState, ["action", "loading"], true);
};

export const transporterAssignVehicleForRideFailure = (state, action) => {
  let nextState = Immutable.setIn(state, ["action", "error"], action.error);
  return Immutable.setIn(nextState, ["action", "loading"], false);
};

export const transporterAssignVehicleForRideSuccess = (state, action) => {
  const { ride: rideChanged } = action;
  let rideRequests = state.rides.asMutable({ deep: true });
  let { rides } = rideRequests;

  let _rides = _.map(rides, (r) => {
    if (rideChanged._id === r._id) {
      const transporter = rideChanged.transporter;
      return { ...r, transporter };
    }
    return r;
  });
  let nextState = Immutable.setIn(state, ["rides", "rides"], _rides);
  return Immutable.setIn(nextState, ["action", "loading"], false);
};

export const onGoingRidesAddRideNeedAssign = (state, action) => {
  let ridesNeedAssignRequests = state.ridesNeedAssign.asMutable({ deep: true });
  ridesNeedAssignRequests.push(action.rideId);
  return Immutable.setIn(state, ["ridesNeedAssign"], ridesNeedAssignRequests);
};

export const onGoingRidesRemoveRideNeedAssign = (state, action) => {
  const ridesNeedAssignRequests = state.ridesNeedAssign.asMutable({
    deep: true,
  });
  const rides = ridesNeedAssignRequests.filter((id) => id !== action.rideId);
  return Immutable.setIn(state, ["ridesNeedAssign"], rides);
};

export const ongoingRideFilesUpdated = (state, action) => {
  const { ride } = action;
  let rides = state.rides.rides.asMutable({ deep: true });
  let _rides = _.map(rides, (r) => {
    if (ride._id === r._id) {
      return { ...r, ...ride };
    }
    return r;
  });
  return Immutable.setIn(state, ["rides", "rides"], _rides);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.ON_GOING_RIDES_RESET_STATE]: onGoingRidesResetState,
  [Types.ON_GOING_RIDES_REQUEST]: onGoingRidesRequest,
  [Types.ON_GOING_RIDES_REQUEST_SUCCESS]: onGoingRidesRequestSuccess,
  [Types.ON_GOING_RIDES_REQUEST_FAILURE]: onGoingRidesRequestFailure,

  [Types.TRANSPORTER_ASSIGN_VEHICLE_FOR_RIDE_REQUEST]:
    transporterAssignVehicleForRideRequest,
  [Types.TRANSPORTER_ASSIGN_VEHICLE_FOR_RIDE_SUCCESS]:
    transporterAssignVehicleForRideSuccess,
  [Types.TRANSPORTER_ASSIGN_VEHICLE_FOR_RIDE_FAILURE]:
    transporterAssignVehicleForRideFailure,

  [Types.ON_GOING_RIDE_STATUS_CHANGED]: onGoingRideStatusChanged,
  [Types.ON_GOING_RIDE_PATIENT_STATUS_CHANGED]: onGoingRidePatientStatusChanged,
  [Types.ON_GOING_RIDES_NEW_RIDE_REQUEST]: onGoingRidesNewRideRequest,
  [Types.ON_GOING_RIDES_REMOVE_RIDE]: onGoingRidesRemoveRide,

  [Types.ON_GOING_RIDES_ADD_RIDE_NEED_ASSIGN]: onGoingRidesAddRideNeedAssign,
  [Types.ON_GOING_RIDES_REMOVE_RIDE_NEED_ASSIGN]:
    onGoingRidesRemoveRideNeedAssign,

  [Types.ONGOING_RIDE_FILES_UPDATED]: ongoingRideFilesUpdated,
});
