import { Text, Tooltip } from "components";
import { Center } from "native-base";

const BadgeCard = (props) => {
  const {
    children,
    bg = "primary",
    color = "ligth",
    tooltip,
    top = 0,
    left = 20,
  } = props;

  return (
    <Tooltip label={tooltip} placement="right">
      <Center
        py="1"
        px="2"
        position="absolute"
        zIndex="1"
        top={top}
        left={left}
        rounded="full"
        borderWidth="1px"
        borderColor={bg}
        bg={bg}
      >
        <Text color={color} fontWeight={800} fontSize={16}>
          {children}
        </Text>
      </Center>
    </Tooltip>
  );
};

export default BadgeCard;
