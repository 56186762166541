export const capitalize = (string) => {
  if (typeof string !== "string") return "";
  const stringLower = string.toLowerCase();
  return stringLower.charAt(0).toUpperCase() + stringLower.slice(1);
};

export function getQueryString(name) {
  var after = window.location.hash.split("?")[1];
  if (after) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = after.match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    } else {
      return null;
    }
  }
}

export function createInitialValueFromArray(arr) {
  let obj = {};
  arr.forEach((element) => {
    obj[element] = false;
  });
  return obj;
}

function getItemQueryFilter(name, item) {
  return item ? `${name}: ${item},` : "";
}

export function getFilterQuery(filter) {
  const patientStatus = !filter.advanced?.patientStatus
    ? null
    : `{
    ${getItemQueryFilter("waiting", filter.advanced.patientStatus.waiting)}
    ${getItemQueryFilter("admission", filter.advanced.patientStatus.admission)}
    ${getItemQueryFilter("ready", filter.advanced.patientStatus.ready)}
  }`;
  const rideStatus = `{
    ${getItemQueryFilter("onway", filter.advanced.rideStatus.onway)}
    ${getItemQueryFilter("arrived", filter.advanced.rideStatus.arrived)}
    ${getItemQueryFilter("pending", filter.advanced.rideStatus.pending)}
    ${getItemQueryFilter("unassigned", filter.advanced.rideStatus.unassigned)}
    ${getItemQueryFilter("waiting", filter.advanced.rideStatus.waiting)}
    ${getItemQueryFilter("onboard", filter.advanced.rideStatus.onboard)}
    ${getItemQueryFilter("canceled", filter.advanced.rideStatus.canceled)}
    ${getItemQueryFilter("completed", filter.advanced.rideStatus.completed)}
  }`;
  return `
    {
      dates: [${filter.dates}],
      text: "${filter.text}",
      advanced: {
        driver: "${filter.advanced.driver}",
        rideDate: "${filter.advanced.rideDate}",
        vehicle: "${filter.advanced.vehicle}",
        vehicleCategories: {
          AMBULANCE: ${filter.advanced.vehicleCategories.AMBULANCE},
          VSL: ${filter.advanced.vehicleCategories.VSL}
        },
        rideType: {
          SIMPLE: ${filter.advanced.rideType.SIMPLE},
          SERIE: ${filter.advanced.rideType.SERIE},
          SAMU: ${filter.advanced.rideType.SAMU},
          ROUND_TRIP: ${filter.advanced.rideType.ROUND_TRIP}
        },
        rideStatus: ${rideStatus},
        patientStatus: ${patientStatus}
      }
    }
  `;
}

export function getPhonesFromService(service) {
  const phones = [];
  if (service?.phone) phones.push(service.phone);
  // if(service?.company?.phone) phones.push(service.company.phone);
  return phones.join(", ");
}
