import { put, takeLatest } from "redux-saga/effects";
import TransporterUserActions, {
  TransporterUserTypes,
} from "reducers/transporter/transporter-user";
import _ from "lodash";
import errorHandler from "sagas/error-handler";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

const GRAPHQL_QUERY_USER = `
  _id
  company
  userType
  firstname
  lastname
  phone
  email
  avatar { path size extension name }
  active
  lastLoginAt
  createdAt
  updatedAt
  status
  vehicle { make plate model }
  inUse
  teamMate { firstname lastname }
  listCompaniesDesactivate
`;

function* transporterUsersAsyncRequest({ page, limit, filter = "" }) {
  const query = `
    {
      transporterDriverUsers(page:${page},limit:${limit},filter:"${filter}",withAuxiliaries: ${true}) { 
        page
        limit
        totalPages
        users {
          ${GRAPHQL_QUERY_USER}
        }
      }
    }
  `;

  try {
    const data = yield graphQLClient().request(query);
    const users = data.transporterDriverUsers;
    yield put(TransporterUserActions.transporterUsersRequestSuccess(users));
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      TransporterUserActions.transporterUsersRequestFailure
    );
  }
}

function* transporterAddUsersAsyncRequest({ user, callback }) {
  const {
    userType = "",
    phone = "",
    password = "",
    lastname = "",
    firstname = "",
  } = user;
  const query = `
    mutation {
      TransporterCreateDriverUser(
        user: {
          userType:"${userType}",
          lastname:"${lastname}",
          firstname:"${firstname}",
          phone:"${phone}",
          password:"${password}"
        }
      )
      {
        _id
        firstname
        lastname
        avatar { path size extension name }
        phone
        password
        userType
        vehicle { make plate model }
      }
    }
  `;

  try {
    yield graphQLClient().request(query);

    yield put(TransporterUserActions.transporterCreateUserRequestSuccess());
    yield put(TransporterUserActions.transporterUsersRequest(1, 100));
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      TransporterUserActions.transporterCreateUserRequestFailure
    );

    if (_.isFunction(callback)) callback(error);
  }
}

// function* transporterModifyUserAsyncRequest({ user, id,callback }) {
//   const { model = "", plate = "", make = "", category, options } = user
//   const query = `
//     mutation {

//       TransporterModifyUser(user:{
//         plate:"${plate}",
//           model:" ${model}",
//           make:"${make}",
//           category:${category},
//           options:[${options}]

//         },
//         userId:"${id}"
//         )

//         }
//   `
//   try {
//      yield graphQLClient().request(query)

//     yield put(TransporterUserActions.transporterModifyUserRequestSuccess())
//     yield put(TransporterUserActions.transporterUsersRequest(1, 10))

//     if (_.isFunction(callback)) callback()

//   } catch (error) {

//     const { message = "" } = error.response.errors[0]
//     yield errorHandler(message,TransporterUserActions.transporterModifyUserRequestFailure)

//     if (_.isFunction(callback)) callback(error)

//   }
// }

function* transporterDeleteUserAsyncRequest({ vehiculeId, callback }) {
  const query = `
    mutation {
      TransporterDeleteUser(userId:"${vehiculeId}")
    }
  `;
  try {
    yield graphQLClient().request(query);

    yield put(TransporterUserActions.transporterDeleteUserRequestSuccess());
    yield put(TransporterUserActions.transporterUsersRequest(1, 100));

    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      TransporterUserActions.transporterDeleteUserRequestFailure
    );

    if (_.isFunction(callback)) callback(error);
  }
}

function* transporterToggleActivateUserAsyncRequest({
  userId,
  userType,
  callback,
}) {
  const query = `
    mutation {
      TransporterToggleActivateUser(userId:"${userId}", userType:"${userType}") {
        ${GRAPHQL_QUERY_USER}
      }
    }
  `;
  try {
    const { TransporterToggleActivateUser } = yield graphQLClient().request(
      query
    );
    yield put(
      TransporterUserActions.transporterToggleActivateUserSuccess(
        TransporterToggleActivateUser
      )
    );
    if (_.isFunction(callback)) callback(TransporterToggleActivateUser.active);
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      TransporterUserActions.transporterToggleActivateUserFailure
    );
    // if (_.isFunction(callback)) callback(error);
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(
    TransporterUserTypes.TRANSPORTER_USERS_REQUEST,
    transporterUsersAsyncRequest
  ),
  takeLatest(
    TransporterUserTypes.TRANSPORTER_CREATE_USER_REQUEST,
    transporterAddUsersAsyncRequest
  ),
  // takeLatest(TransporterUserTypes.TRANSPORTER_MODIFY_USER_REQUEST, transporterModifyUserAsyncRequest),
  takeLatest(
    TransporterUserTypes.TRANSPORTER_DELETE_USER_REQUEST,
    transporterDeleteUserAsyncRequest
  ),
  takeLatest(
    TransporterUserTypes.TRANSPORTER_TOGGLE_ACTIVATE_USER_REQUEST,
    transporterToggleActivateUserAsyncRequest
  ),
];
