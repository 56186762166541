import { put, takeLatest } from "redux-saga/effects";
import TransporterVehicleActions, {
  TransporterVehicleTypes,
} from "reducers/transporter/transporter-vehicle";
import _ from "lodash";
import errorHandler from "sagas/error-handler";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* transporterVehiclesAsyncRequest({ page, limit, filter = "" }) {
  const query = `
    {
      transporterVehicles(page:${page},limit:${limit},filter:"${filter}") { 
        page
        limit
        totalPages
        vehicles {
          _id
          driver
          plate
          model
          make
          category
          inUse
          color
          phone
          inService
          createdAt
          updatedAt
        }
      }
    }
  `;

  try {
    const { transporterVehicles } = yield graphQLClient().request(query);
    yield put(
      TransporterVehicleActions.transporterVehiclesRequestSuccess(
        transporterVehicles
      )
    );
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      TransporterVehicleActions.transporterVehiclesRequestFailure
    );
  }
}

function* transporterAddVehiclesAsyncRequest({ vehicle, callback }) {
  const {
    model = "",
    plate = "",
    make = "",
    category,
    color = "",
    phone = "",
  } = vehicle;
  const query = `
    mutation {
      TransporterAddVehicle(
        vehicle: {
          plate:"${plate}",
          model:"${model}",
          make:"${make}",
          category:${category},
          color:"${color}",
          phone:"${phone}"
        }
      )
      {
        _id
        driver
        plate
        model
        make
        category
        inUse
        color
        phone
        inService
        createdAt
        updatedAt
      }
    }
  `;
  try {
    yield graphQLClient().request(query);

    yield put(
      TransporterVehicleActions.transporterCreateVehicleRequestSuccess()
    );
    yield put(TransporterVehicleActions.transporterVehiclesRequest(1, 100));
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      TransporterVehicleActions.transporterCreateVehicleRequestFailure
    );

    if (_.isFunction(callback)) callback(error);
  }
}

// function* transporterModifyVehicleAsyncRequest({ vehicle, id,callback }) {
//   const { model = "", plate = "", make = "", category, options } = vehicle
//   const query = `
//     mutation {

//       TransporterModifyVehicle(vehicle:{
//         plate:"${plate}",
//           model:" ${model}",
//           make:"${make}",
//           category:${category},
//           options:[${options}]

//         },
//         vehicleId:"${id}"
//         )

//         }
//   `
//   try {
//      yield graphQLClient().request(query)

//     yield put(TransporterVehicleActions.transporterModifyVehicleRequestSuccess())
//     yield put(TransporterVehicleActions.transporterVehiclesRequest(1, 10))

//     if (_.isFunction(callback)) callback()

//   } catch (error) {

//     const { message = "" } = error.response.errors[0]
//     yield errorHandler(message,TransporterVehicleActions.transporterModifyVehicleRequestFailure)

//     if (_.isFunction(callback)) callback(error)

//   }
// }

function* transporterDeleteVehicleAsyncRequest({ vehiculeId, callback }) {
  const query = `
    mutation {
      TransporterDeleteVehicle(vehicleId:"${vehiculeId}")
    }
  `;
  try {
    yield graphQLClient().request(query);

    yield put(
      TransporterVehicleActions.transporterDeleteVehicleRequestSuccess()
    );
    yield put(TransporterVehicleActions.transporterVehiclesRequest(1, 100));

    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      TransporterVehicleActions.transporterDeleteVehicleRequestFailure
    );

    if (_.isFunction(callback)) callback(error);
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(
    TransporterVehicleTypes.TRANSPORTER_VEHICLES_REQUEST,
    transporterVehiclesAsyncRequest
  ),
  takeLatest(
    TransporterVehicleTypes.TRANSPORTER_CREATE_VEHICLE_REQUEST,
    transporterAddVehiclesAsyncRequest
  ),
  // takeLatest(TransporterVehicleTypes.TRANSPORTER_MODIFY_VEHICLE_REQUEST, transporterModifyVehicleAsyncRequest),
  takeLatest(
    TransporterVehicleTypes.TRANSPORTER_DELETE_VEHICLE_REQUEST,
    transporterDeleteVehicleAsyncRequest
  ),
];
