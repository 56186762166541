import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import { Center } from "native-base";

import { Text, HistoryRidesCard } from "components";
import noDataIcon from "assets/img/no_data.svg";

function Rides({ ride, fetchData, t, i18n, downloadFiles }) {
  const { error, loading } = ride.action;

  return (
    <>
      {/* No Data */}
      {ride.rides.rides.length == 0 ? (
        <Center flex={1}>
          <img src={noDataIcon} />
          <Text fontSize="18">
            {t("RidesHistoryPage.message_when_no_data")}
          </Text>
        </Center>
      ) : (
        <Text italic color="gray">
          Pour plus de détails appuyez sur la fiche transport
        </Text>
      )}

      <div
        id="scrollableDivHistory"
        style={{
          overflow: "auto",
          paddingRight: "10%",
          marginTop: "20px",
        }}
      >
        <InfiniteScroll
          dataLength={ride.rides.rides.length} //This is important field to render the next data
          next={fetchData}
          hasMore={true}
          scrollableTarget="scrollableDivHistory"
          loader={
            <Loader
              type="ThreeDots"
              color="#00BFFF"
              height={100}
              width={100}
              visible={loading}
            />
          }
        >
          {ride.rides.rides.map((ride, key) => (
            <HistoryRidesCard
              {...{ t, i18n }}
              ride={ride}
              downloadFiles={downloadFiles}
              key={key}
              mb="10px"
            />
          ))}
        </InfiniteScroll>
      </div>
    </>
  );
}

export default Rides;
