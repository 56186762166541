import { Subscription } from "@apollo/client/react/components";
import gql from "graphql-tag";
import { Text } from "components";

const TRANSPORTER_INCOMING_SERIE_SUBSCRIPTION = gql`
  subscription {
    TransporterIncomingSerieSubscription {
      _id
      status
      vehicleCategory
      vehicleOptions
      dates
      start
      end
      returnDatetime
      createdAt
      pickup {
        address
      }
      dropoff {
        address
      }
      patient {
        firstname
        lastname
        phone
        email
        nss
        status
      }
      service {
        company {
          name
        }
      }
    }
  }
`;

const IncomingSerieNotification = ({
  transporterDidReceiveSerie,
  showNotification,
}) => {
  function onSubscriptionData(serie) {
    if (serie !== undefined) {
      transporterDidReceiveSerie(serie);
      showNotification({
        page: "/offer",
        message: "Vous avez une nouvelle demande de série",
        type: "info",
        readed: false,
      });
    }
  }

  return (
    <Subscription
      subscription={TRANSPORTER_INCOMING_SERIE_SUBSCRIPTION}
      onSubscriptionData={({ subscriptionData }) => {
        onSubscriptionData(
          subscriptionData.data.TransporterIncomingSerieSubscription
        );
      }}
    >
      {({ data = {}, loading, error }) => {
        // if (!error && !loading) {
        //   this.onSubscriptionData(data.TransporterIncomingSerieSubscription);
        // }
        return null;
      }}
    </Subscription>
  );
};

export default IncomingSerieNotification;
