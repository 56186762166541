import { Subscription } from "@apollo/client/react/components";
import gql from "graphql-tag";
import { Text } from "components";

const TRANSPORTER_INCOMING_RIDE_LEFT_SUBSCRIPTION = gql`
  subscription {
    TransporterIncomingRideLeftSubscription {
      _id
      status
      rideNumber
    }
  }
`;

const IncomingRideLeftNotification = ({
  transporterDidRideLeft,
  transporterRideGotByOther,
  showNotification,
}) => {
  function onSubscriptionData(ride) {
    if (ride !== undefined) {
      transporterRideGotByOther(ride);
      setTimeout(() => {
        transporterDidRideLeft(ride);
      }, 5_000);

      showNotification({
        page: "/offer",
        message: `la réservation ${ride.rideNumber} n'est plus disponible`,
        type: "danger",
        readed: false,
      });
    }
  }

  return (
    <Subscription
      subscription={TRANSPORTER_INCOMING_RIDE_LEFT_SUBSCRIPTION}
      onSubscriptionData={({ subscriptionData }) => {
        onSubscriptionData(
          subscriptionData.data.TransporterIncomingRideLeftSubscription
        );
      }}
    >
      {({ data = {}, loading, error }) => null}
    </Subscription>
  );
};

export default IncomingRideLeftNotification;
