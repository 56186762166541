import { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import _ from "lodash";
import { Checkbox, HStack, ScrollView, Stack, View, VStack } from "native-base";
import { Title, NotificationMenu, Text } from "components";

function RenderItem({ name, value, capitalize }) {
  if (capitalize) {
    value = _.capitalize(value);
  }
  return (
    <HStack space={4}>
      <Text fontWeight="700">{name}</Text>
      <Text>{value}</Text>
    </HStack>
  );
}

function RenderCheckbox({ name, value, onChange }) {
  return (
    <Checkbox
      isChecked={value}
      // onChange={onChange}
      colorScheme={"green"}
      _text={{ color: "black" }}
      _checked={{ _text: { color: "green.600" } }}
      children={name}
    />
  );
}

class SettingsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sectors: {},
    };

    // this.toggleSector = this.toggleSector.bind(this);
  }

  componentDidMount() {
    this.props.profileRequest();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.profile?.company?.name !== this.props.profile?.company?.name
    ) {
      let sectors = {};
      this.props.profile?.company?.sectors.forEach((sector) => {
        if (!sectors[sector.samuName]) {
          sectors[sector.samuName] = [];
        }
        sectors[sector.samuName].push(sector);
      });
      this.setState({ sectors });
    }
  }

  // toggleSector = (sector) => {
  //   this.setState((prevState) => ({
  //     samu93: {
  //       ...prevState.samu93,
  //       [sector]: !prevState.samu93[sector],
  //     },
  //   }));
  // };

  render() {
    const { t, i18n, actionError, profile } = this.props;
    const {
      lastname = "",
      firstname = "",
      email = "",
      company = { name: "", siret: "", email: "", web: "" },
    } = profile;

    return (
      <View w="full">
        <VStack space="5" ml="24" mr="12" h="full">
          <VStack space="5" h="48" mt="5">
            <Title>Paramètres</Title>

            {/* Notification menu */}
            <Stack alignItems="end">
              <NotificationMenu
                items={this.props.notifications}
                onReadedNotifications={this.props.onReadedNotifications}
              />
            </Stack>
          </VStack>

          <ScrollView>
            <VStack space="5" h="full">
              <VStack>
                <Text mb={1} fontSize="18" fontWeight="900">
                  Profil
                </Text>
                <RenderItem name="Nom" value={lastname} capitalize />
                <RenderItem name="Prénom" value={firstname} capitalize />
                <RenderItem name="Email" value={email} />
              </VStack>
              <VStack>
                <Text mb={1} fontSize="18" fontWeight="900">
                  Société
                </Text>
                <RenderItem name="Nom" value={company?.name} capitalize />
                <RenderItem name="Email" value={company?.email} />
                <RenderItem name="Site web" value={company?.web} />
              </VStack>
              <VStack>
                <Text mb={1} fontSize="18" fontWeight="900">
                  Code d'inscription
                </Text>
                <RenderItem name="Code" value={company?.driverRegister?.code} />
              </VStack>
              <Text color="gray" italic>
                Vous pouvez donner le code à vos dea's pour une inscription
                autonome via l'application mobile (Resaam Ambulancier).
              </Text>
              {/* cocher les zones de travail pour le mode samu */}
              <VStack space={1}>
                <Text fontSize="18" fontWeight="900">
                  Zones de travail (SAMU)
                </Text>
                {Object.keys(this.state.sectors).map((samuName) => (
                  <VStack space={1} key={samuName}>
                    <Text fontSize="14" fontWeight="700">
                      {samuName} - secteurs
                    </Text>
                    <HStack space={4}>
                      {this.state.sectors[samuName].map((sector) => (
                        <RenderCheckbox
                          key={sector.sectorName}
                          name={sector.sectorName}
                          value={true}
                        />
                      ))}
                    </HStack>
                  </VStack>
                ))}
              </VStack>
            </VStack>
          </ScrollView>
        </VStack>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const settingRedux = connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
export default withTranslation(["Private", "Common"], { wait: true })(
  settingRedux
);
