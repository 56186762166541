import { Component } from "react";
import transporterUsersAction from "reducers/transporter/transporter-user";
import { withTranslation } from "react-i18next";
import { Alert, HStack, ScrollView, Stack, View, VStack } from "native-base";
import { connect } from "react-redux";
import {
  Title,
  FloatingInput,
  NotificationMenu,
  Button,
  UserFormModal,
  UserCard,
} from "components";
import addUserIcon from "assets/img/add_vehicle.svg";

// dispatch
// transporterUsersRequest
// transporterCreateUserRequest
// transporterDeleteUserRequest

class StaffPage extends Component {
  constructor(props) {
    super(props);

    this.onSearchUserFilter = this.onSearchUserFilter.bind(this);
    this.onPressShowFormButton = this.onPressShowFormButton.bind(this);
    this.onCancelFormPopUp = this.onCancelFormPopUp.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);

    this.state = {
      searchInputValue: "",
      showFormPopUp: false,
      selectedUser: null,
      userType: "DEA",
    };
  }

  componentDidMount() {
    this.props.transporterUsersRequest(1, 100);
  }

  onSearchUserFilter(e) {
    const { value } = e.target;
    this.setState({ searchInputValue: value });
    this.props.transporterUsersRequest(1, 100, value);
  }

  onPressShowFormButton(userType, user = null) {
    this.setState(
      Object.assign({}, this.state, {
        showFormPopUp: userType,
        selectedUser: user,
        userType,
      })
    );
  }

  onSubmitForm(user, callback) {
    this.setState({ selectedUser: null });
    this.props.transporterCreateUserRequest(user, callback);
  }

  onCancelFormPopUp() {
    this.setState(
      Object.assign({}, this.state, {
        showFormPopUp: false,
        selectedUser: null,
      })
    );
  }

  render() {
    const {
      t,
      i18n,
      actionError,
      transporterUsers: { users = [] },
    } = this.props;
    // const { error, loading } = actionError;
    const { showFormPopUp, selectedUser, searchInputValue } = this.state;

    return (
      <View w="full">
        <VStack space="5" ml="24" mr="12" h="full">
          <VStack space="5" h="48" mt="5">
            {/* {error !== "" && <Alert bsStyle="danger">
            <span>{error}</span>
          </Alert>} */}

            <Title>
              {t("StaffPage.title_headline", { lng: i18n.language })}
            </Title>

            {/* Notification menu */}
            <Stack alignItems="end">
              <NotificationMenu
                items={this.props.notifications}
                onReadedNotifications={this.props.onReadedNotifications}
              />
            </Stack>
            <HStack justifyContent="space-between" mr="20">
              {/* Search */}
              <FloatingInput
                inputType="search"
                label={t("searchBar", { lng: i18n.language })}
                onChange={this.onSearchUserFilter}
                // onKeyPress={this.handleKeyPress}
                value={searchInputValue}
              />

              {/* Localiser */}

              {/* Add user */}
              <HStack space={4}>
                <Button
                  borderRadius="10px"
                  py="11px"
                  px="18px"
                  rightIcon={<img src={addUserIcon} />}
                  _text={{
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "ligth",
                  }}
                  onPress={() => this.onPressShowFormButton("DEA")}
                >
                  {t("StaffPage.button_add_dea", { lng: i18n.language })}
                </Button>
                <Button
                  borderRadius="10px"
                  py="11px"
                  px="18px"
                  rightIcon={<img src={addUserIcon} />}
                  _text={{
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "ligth",
                  }}
                  onPress={() => this.onPressShowFormButton("AUXILIARY")}
                >
                  {t("StaffPage.button_add_auxiliary", { lng: i18n.language })}
                </Button>
              </HStack>
            </HStack>
          </VStack>

          <ScrollView>
            <VStack>
              {users.users.map((user, key) => (
                <UserCard
                  mb="10px"
                  user={user}
                  transporterDeleteUserRequest={
                    this.props.transporterDeleteUserRequest
                  }
                  {...{ t, i18n }}
                  key={key}
                  currentCompany={this.props.profile?.company?.id}
                  toggleActivateUser={
                    this.props.transporterToggleActivateUserRequest
                  }
                />
              ))}
            </VStack>
          </ScrollView>
        </VStack>

        {/* MODAL ADD USER */}
        <UserFormModal
          {...{ t, i18n }}
          user={selectedUser}
          userType={this.state.userType}
          isOpen={showFormPopUp}
          onClose={this.onCancelFormPopUp}
          onConfirm={this.onSubmitForm}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // actionError: state.inComingRides.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    transporterCreateUserRequest: (...args) =>
      dispatch(transporterUsersAction.transporterCreateUserRequest(...args)),
    transporterDeleteUserRequest: (...args) =>
      dispatch(transporterUsersAction.transporterDeleteUserRequest(...args)),
    transporterToggleActivateUserRequest: (...args) =>
      dispatch(
        transporterUsersAction.transporterToggleActivateUserRequest(...args)
      ),
  };
};

const staffRedux = connect(mapStateToProps, mapDispatchToProps)(StaffPage);
export default withTranslation(["Private", "Common"], { wait: true })(
  staffRedux
);
