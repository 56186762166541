import { Component } from "react";
import {
  HStack,
  Modal,
  Pressable,
  ScrollView,
  Toast,
  VStack,
} from "native-base";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { NotificationCustom, Button, Text } from "components";
import { VEHICLE_ICONS } from "global";
import VehicleCard from "components/VehicleModalCard";

class AcceptSerieModal extends Component {
  constructor(props) {
    super(props);

    this.onConfirmAction = this.onConfirmAction.bind(this);
    this.onCancelAction = this.onCancelAction.bind(this);

    this.setSelectedVehicle = this.setSelectedVehicle.bind(this);
    this.vehicleCategoryCompatible = this.vehicleCategoryCompatible.bind(this);

    this.state = {
      selectedVehicle: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedSerie !== prevProps.selectedSerie) {
      this.setState(
        Object.assign({}, this.state, {
          selectedVehicle: null,
        })
      );
    }
  }

  componentWillUnmount() {
    this.setSelectedVehicle(false);
  }

  onCancelAction() {
    this.setSelectedVehicle(null);
    this.props.onClose();
  }

  onConfirmAction() {
    const {
      t,
      i18n,
      selectedSerie,
      transporterAcceptProposedSerieRequest,
      // onShowAcceptRideSuccess,
    } = this.props;
    const { selectedVehicle } = this.state;

    transporterAcceptProposedSerieRequest(
      selectedSerie._id,
      selectedSerie.returnDatetime ? selectedVehicle : false
    );

    // Toast.show(
    //   NotificationCustom({
    //     message: "Série ajouté dans l'historique",
    //   })
    // );
    this.onCancelAction();
  }

  setSelectedVehicle(vehicle) {
    this.setState(Object.assign({}, this.state, { selectedVehicle: vehicle }));
  }

  vehicleCategoryCompatible(vehicle) {
    const { selectedSerie = false } = this.props;

    if (selectedSerie && vehicle.category === selectedSerie.vehicleCategory)
      return true;
    return false;
  }

  getVehicles() {
    const { selectedVehicle } = this.state;

    const vehicles = this.props.companyVehicles.sort((a, b) =>
      a.driver > b.driver ? 1 : -1
    );

    return vehicles.map((vehicle, key) => {
      const vehicleIsSelected = _.isEqual(selectedVehicle, vehicle);
      const vehicleIcon = !vehicleIsSelected
        ? VEHICLE_ICONS.hover[vehicle.category]
        : VEHICLE_ICONS.default[vehicle.category];

      if (!this.vehicleCategoryCompatible(vehicle)) {
        return null;
      }

      if (!vehicle.driver) {
        return (
          <VehicleCard
            vehicle={vehicle}
            vehicleIcon={vehicleIcon}
            isDisabled={true}
            key={key}
          />
        );
      }

      return (
        <Pressable
          onPress={() => this.setSelectedVehicle(vehicle)}
          p="0"
          key={key}
        >
          <VehicleCard
            vehicleIsSelected={vehicleIsSelected}
            vehicle={vehicle}
            vehicleIcon={vehicleIcon}
          />
        </Pressable>
      );
    });
  }

  render() {
    const { t, i18n, selectedSerie } = this.props;
    const { selectedVehicle } = this.state;
    const serieStartNow = selectedSerie && selectedSerie.returnDatetime;

    return (
      <Modal {...this.props}>
        <Modal.Content maxWidth="588px" maxHeight="545px">
          {/* <Modal.CloseButton /> */}
          <Modal.Header alignItems="center" borderBottomWidth="0px">
            <Text fontSize="24px" fontWeight="900" lineHeight="29px">
              {t(`modalSerie.title${serieStartNow ? "_start_now" : ""}`, {
                lng: i18n.language,
              })}
            </Text>
          </Modal.Header>
          <Modal.Body alignItems="center">
            <ScrollView w="full" px="36px" mt="4">
              <VStack height="full" space="10px">
                {/* VEHICLES */}
                {serieStartNow && this.getVehicles()}
              </VStack>
            </ScrollView>
          </Modal.Body>
          <Modal.Footer bg="light">
            <HStack space="2" mr="36px" my="4">
              <Button
                minWidth="140px"
                third
                borderWidth="1px"
                px="36px"
                py="8px"
                onPress={this.onCancelAction}
              >
                {t(`button.cancel`, { lng: i18n.language }).toUpperCase()}
              </Button>
              <Button
                minWidth="140px"
                isDisabled={serieStartNow ? selectedVehicle === null : false}
                borderWidth="0px"
                px="36px"
                py="8px"
                onPress={this.onConfirmAction}
              >
                {t(`button.accept`, { lng: i18n.language }).toUpperCase()}
              </Button>
            </HStack>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withTranslation(["Private", "Common"], { wait: true })(
  AcceptSerieModal
);
