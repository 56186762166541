import { Component } from "react";
import { HStack, VStack, Center, Pressable, Box } from "native-base";
import moment from "moment";
import _ from "lodash";
import { capitalize } from "functions";
import VehicleOptions from "../VehicleOptions";
import {
  DelayTransport,
  Tag,
  BadgeCard,
  Card,
  Text,
  Button,
  RideDestination,
  PatientStatus,
} from "components";

import birthdayIcon from "assets/img/patient/birthday.svg";
import emailIcon from "assets/img/patient/email.svg";
import nssIcon from "assets/img/patient/nss.svg";
import phoneNumberIcon from "assets/img/patient/phone_number.svg";
import Tooltip from "components/Tooltip";
import InterventionInfo from "./InterventionInfo";

function formatAddressIntervention(intervention) {
  // intervention numeroRue rue codePostal commune
  return `${intervention.numeroRue} ${intervention.rue}, ${intervention.codePostal} ${intervention.commune}`;
}

function formatWaitingTime(waitingTime, status) {
  return ["onway", "onboard"].includes(status) &&
    !_.isEmpty(waitingTime) &&
    _.isInteger(waitingTime.duration_in_traffic)
    ? moment.utc(waitingTime.duration_in_traffic * 1000).format("HH:mm")
    : "--:--";
}

class OnGoingRidesCardOld extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowMoreInfo: false,
    };

    this.onPressAssignVehicle = this.onPressAssignVehicle.bind(this);
    this.onRideShowMoreInfoToggle = this.onRideShowMoreInfoToggle.bind(this);
    this.onPressShowMap = this.onPressShowMap.bind(this);
  }

  onPressAssignVehicle() {
    this.props.onPressAcceptRideButton(this.props.ride);
  }

  onPressShowMap() {
    this.props.onShowMap(this.props.ride);
  }

  onRideShowMoreInfoToggle() {
    this.setState(
      Object.assign({}, this.state, {
        isShowMoreInfo: !this.state.isShowMoreInfo,
      })
    );
  }

  render() {
    const { t, i18n, ridesNeedAssign, downloadFiles } = this.props;
    const {
      _id,
      rideNumber,
      patient,
      status,
      dropoff,
      pickup,
      transporter,
      datetime,
      vehicleOptions,
      serie,
      samu,
      intervention,
      observation,
      subject,
      waitingTime = {},
      note,
      isRoundTrip,
      files,
    } = this.props.ride;
    const rideAssigned = transporter.vehicle !== null;
    const statusColor = `status.${rideAssigned ? status : "unassigned"}`;
    const statusText = t(`rideStatus.${rideAssigned ? status : "unassigned"}`, {
      lng: i18n.language,
    });
    const { isShowMoreInfo } = this.state;
    const isShowPatientStatus = samu ? false : true;
    const isShowRideDestination = pickup.address ? true : false;
    const notSpecifiedText = t("TransporterReservationsPage.not_specified", {
      lng: i18n.language,
    });

    return (
      <Pressable
        onPress={this.onRideShowMoreInfoToggle}
        mb={this.props.mb || 0}
        pt={3}
        position="relative"
      >
        {serie && (
          <BadgeCard
            tooltip={t("TransporterReservationsPage.ride_from_serie_tooltip", {
              lng: i18n.language,
            })}
          >
            {t("TransporterReservationsPage.ride_from_serie_label", {
              lng: i18n.language,
            })}
          </BadgeCard>
        )}

        {isRoundTrip && (
          <BadgeCard tooltip={"Transport avec un retour compris"}>
            <Text color="ligth">{"Aller/Retour"}</Text>
          </BadgeCard>
        )}

        {samu && (
          <BadgeCard
            // bg={statusColor}
            tooltip={
              t("TransporterReservationsPage.ride_from_samu_tooltip", {
                lng: i18n.language,
              }) + ` ${samu.name}`
            }
          >
            {t("TransporterReservationsPage.ride_from_samu_label", {
              lng: i18n.language,
            })}
          </BadgeCard>
        )}

        <Card
          display="flex"
          flexDirection="row"
          alignItems="center"
          borderColor={
            rideAssigned || !ridesNeedAssign.includes(_id)
              ? "ligthGray"
              : "status.unassigned"
          }
          minWidth="1094px"
          minHeight="191px"
          py="4"
        >
          <VStack space="2" w="full">
            <HStack
              space="2"
              justifyContent="space-between"
              w="full"
              alignItems="center"
            >
              <VStack space="1px" flex={1}>
                <Text fontSize="24px" fontWeight="900" color={statusColor}>
                  {rideNumber}
                </Text>
                {!isShowMoreInfo && (
                  <Text
                    fontSize="14px"
                    fontWeight="900"
                    color={statusColor}
                  >{`${patient?.firstname || ""} ${patient.lastname}`}</Text>
                )}
                <Text fontSize="14px" color="gray" fontStyle="italic">
                  {patient.nss}
                </Text>
              </VStack>

              <Box flex={2}>
                <RideDestination
                  pickup={
                    isShowRideDestination
                      ? pickup.address
                      : formatAddressIntervention(intervention)
                  }
                  dropoff={dropoff.address ? dropoff.address : "..."}
                />
              </Box>

              <HStack space="2" flex={1}>
                <VStack justifyContent="center">
                  <Text fontSize="14px" fontWeight="700" color="default">
                    {moment(datetime).format("DD MMM YYYY")}
                  </Text>
                  <Text fontSize="14px" fontWeight="700" color="default">
                    {moment(datetime).format("HH:mm")}
                  </Text>
                </VStack>
                {/* {status === "incoming" ||
                  (status === "pending" && (
                    <Center>
                      <DelayTransport
                        onPress={
                          (time) => null
                          // console.log(`retard ${rideNumber} by ${time} min`)
                        }
                      />
                    </Center>
                ))} */}

                <VStack space="2" flex={2}>
                  {status !== "pending" && (
                    <VStack>
                      <Text
                        fontSize="14px"
                        fontStyle="italic"
                        underline
                        color={status === "onbord" ? "gray" : "default"}
                      >
                        {t("TransporterReservationsPage.transporter_delay", {
                          lng: i18n.language,
                        }) + " :"}
                      </Text>
                      <Text fontSize="14px">
                        {formatWaitingTime(waitingTime, status)}{" "}
                      </Text>
                    </VStack>
                  )}
                  {status !== "onboard" && status !== "pending" && (
                    // Need subscription to get driver localisation
                    <Button
                      third
                      color="default"
                      borderColor="default"
                      py="1px"
                      px="10px"
                      borderWidth="1px"
                      onPress={this.onPressShowMap}
                    >
                      {t("TransporterReservationsPage.button_localisation", {
                        lng: i18n.language,
                      })}
                    </Button>
                  )}
                </VStack>
              </HStack>

              {isShowPatientStatus && (
                <VStack space="2" flex={1}>
                  <Text fontSize="14px" fontStyle="italic" underline>
                    {t("TransporterReservationsPage.patient_status", {
                      lng: i18n.language,
                    })}
                  </Text>
                  <PatientStatus status={patient.status} {...{ t, i18n }} />
                </VStack>
              )}

              <Box flex={1}>
                <VStack space="4" flexWrap="wrap">
                  <Tag text={statusText} color="ligth" bg={statusColor} />

                  {rideAssigned ? (
                    <Text fontSize="14x" fontWeight="900">
                      {capitalize(transporter.vehicle.category)}{" "}
                      {transporter.vehicle?.plate.toUpperCase()}
                    </Text>
                  ) : (
                    <Button
                      third
                      borderColor="danger"
                      color="danger"
                      onPress={this.onPressAssignVehicle}
                    >
                      {t("TransporterReservationsPage.button_assign_vehicle", {
                        lng: i18n.language,
                      }).toUpperCase()}
                    </Button>
                  )}
                </VStack>
              </Box>
            </HStack>

            {isShowMoreInfo && (
              <VStack space={2}>
                <HStack
                  space="2"
                  justifyContent="space-between"
                  alignItems="center"
                  w="full"
                  minHeight="132px"
                  mb="4"
                >
                  <HStack
                    flex={1}
                    justifyContent="space-evenly"
                    alignItems="center"
                    bg="ligthGray"
                    px="28px"
                    py="36px"
                    rounded="10px"
                  >
                    <VStack space={1}>
                      <Text
                        fontSize="24px"
                        fontWeight="900"
                        color={statusColor}
                      >
                        {`${patient.firstname || ""} ${patient.lastname}`}
                      </Text>
                      <Text
                        textAlign="center"
                        fontSize="16px"
                        fontWeight="900"
                        color={statusColor}
                      >
                        {patient.gender &&
                          t(
                            "TransporterReservationsPage.patient_gender." +
                              patient.gender.toLowerCase(),
                            {
                              lng: i18n.language,
                            }
                          )}
                      </Text>
                    </VStack>

                    <VStack space="4">
                      <HStack space="2" alignItems="center">
                        <img src={birthdayIcon} />
                        <Text>
                          {patient.age
                            ? patient.age + " ans"
                            : notSpecifiedText}
                        </Text>
                      </HStack>
                      <HStack space="2" alignItems="center">
                        <img src={nssIcon} />
                        <Text>{patient.nss || notSpecifiedText}</Text>
                      </HStack>
                    </VStack>
                    <VStack space="4">
                      <HStack space="2" alignItems="center">
                        <img src={emailIcon} />
                        <Text>{patient.email || notSpecifiedText}</Text>
                      </HStack>
                      <HStack space="2" alignItems="center">
                        <img src={phoneNumberIcon} />
                        <Text>{patient.phone || notSpecifiedText}</Text>
                      </HStack>
                    </VStack>

                    {samu && (
                      <>
                        <InterventionInfo
                          intervention={intervention}
                          t={t}
                          i18n={i18n}
                        />
                        <VStack mr="20">
                          <VStack>
                            <Text fontWeight="900">
                              {t("interventionInfo.subject", {
                                lng: i18n.language,
                              })}
                            </Text>
                            <Text>{subject || notSpecifiedText}</Text>
                          </VStack>

                          <VStack>
                            <Text fontWeight="900">
                              {t("interventionInfo.observation", {
                                lng: i18n.language,
                              })}
                            </Text>
                            <Text>{observation || notSpecifiedText}</Text>
                          </VStack>
                        </VStack>
                      </>
                    )}
                  </HStack>

                  {!samu && (
                    <VStack mr="20" w="20%">
                      <VehicleOptions options={vehicleOptions} />
                      <Text>{note}</Text>
                      {files.length > 0 && (
                        <Pressable
                          onPress={() => downloadFiles(files, patient)}
                        >
                          <Text>
                            Télécharger{" "}
                            {files.length == 1
                              ? "le fichier"
                              : `les ${files.length} fichiers`}
                          </Text>
                        </Pressable>
                      )}
                    </VStack>
                  )}
                </HStack>
              </VStack>
            )}
          </VStack>
        </Card>
      </Pressable>
    );
  }
}

export default OnGoingRidesCardOld;
